import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";


const locationPath = "/contact";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "お問い合わせ";

const useStyles = makeStyles((theme) => ({
    googleformWrapper: {
        position: 'relative',
        width: '100%',
        height: '586px',
        overflowY: 'scroll',
      },
  }));

function ContactPage() {

    useEffect(() => {

        // Google Analytics measurement ID setting
        ReactGA.initialize("G-KT8FSRD8RJ");
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

    }, []);

    const classes = useStyles();

    return (

        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content="お問い合わせ" />
                <meta property="og:url" content={fullPath} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content="お問い合わせ" />
                <meta property="og:site_name" content="Life-Util" />
            </Helmet>

            <iframe className={classes.googleformWrapper} title="contact-form" src="https://docs.google.com/forms/d/e/1FAIpQLSfJI1oAdGJ94ffs_sbuoWGhaBFnSu0W3dcMqdtinVn3ohtN_g/viewform?embedded=true" width="640" height="610" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>

        </div>

    );

}

export default ContactPage;
