import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import AccordionInstruction from '../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ReactGA from "react-ga4";


const locationPath = "/tools/password-generator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "パスワード生成ツール (CSV/PDFダウンロード可)";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    backgroundColor: '#ffffff',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '240px',
    height: 'auto',
  },
  margin: {
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

// Define text
const lowercase = "abcdefghijklmnopqrstuvwxyz"        // 英小文字
const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"        // 英大文字
const numbers = "0123456789"                          // 数値
//const symboles = "`˜!@#$%^&*()_+-={}[]|:;\"'<>,.?/"   // Symbols
const symboles = "/*-+$%&()~|_.,!#"   // Symbols

// ブラウザで実行するときはコメントアウト
//const crypto = require('crypto')

// Math.random()よりもセキュアな乱数生成関数
const secureMathRandom = () => {
    // node.jsで動かす場合
    //return crypto.randomFillSync(new Uint32Array(1))[0] / 4294967295
    // ブラウザで実行する場合は下記に変更
    return window.crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295;
}

const lengthText = "lengthText";
const quantityText = "quantityText";

let resultElement;
var resultData = [];
let lengthTextValue, quantityTextValue;

// Conditions
let length = "8";                           // length
let createQuantity = "10";                  // 作成するパスワードの数
let useLowercase = true;                    // 英小文字を含めるか
let useUppercase = true;                    // 英大文字を含めるか
let useNumber = true;                       // 数値を含めるか
let useSymbol = false;                      // 記号を含めるか
let compelDuplicateString = true;           // 同じ文字が現れるのを許容するか
let compelResembleString = true;            // 似ている文字を含めるか

function getPasswordArray() {

  // Set conditions
  let strList = `${useLowercase ? lowercase : ""}${useUppercase ? uppercase : ""}${useNumber ? numbers : ""}${useSymbol ? symboles : ""}`

  // Delete similar char
  if (compelResembleString) {
    strList = strList.replace(/I|l|1|\||O|o|0/g, "")
  }

  // Array
  let result = []

  // Loop
  for (let i = 0; i < createQuantity; i++) {
    let password = ""

    // Allow duplication
    if (!compelDuplicateString) {
        for (let j = 0; j < length; j++) {
            password += strList[Math.floor(secureMathRandom() * strList.length)]
        }

        // Remove duplication
    } else {
        let tmpStr = strList
        for (let j = 0; j < length; j++) {
            const index = Math.floor(secureMathRandom() * tmpStr.length)
            password += tmpStr[index]
            tmpStr = tmpStr.replace(tmpStr[index], "")
        }
    }
    result.push({ seq: i+1, name: password });
  }

  return result;

}

function setResultData(formValues) {

  if (formValues.length === lengthText) {
    length = Number(formValues.lengthTextValue);
  } else {
    length = formValues.length;
  }
  
  if (formValues.quantity === quantityText) {
    createQuantity = Number(formValues.quantityTextValue);
  } else {
    createQuantity = formValues.quantity;
  }
  
  useUppercase = formValues.useUppercase;
  useLowercase = formValues.useLowercase;
  useNumber = formValues.useNumber;
  useSymbol = formValues.useSymbol;

  compelDuplicateString = formValues.compelDuplicateString;
  compelResembleString = formValues.compelResembleString;

  //Important!
  resultData = getPasswordArray();

}


function PasswordGenerator() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setResultData(data)
  const [value, setValue] = React.useState(length);
  const [quantityValue, setQuantityValue] = React.useState(createQuantity);
  const [state, setState] = React.useState({
    useUppercase: true,
    useLowercase: true,
    useNumber: true,
    useSymbol: false,
    compelDuplicateString: true,
    compelResembleString: true,
  });

  const handleChangeForLength = (event) => {
    setValue(event.target.value);
  };

  const handleChangeForQuantity = (event) => {
    setQuantityValue(event.target.value);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  
  if(resultData.length === 0) {

    //Show result on load.
    resultData = getPasswordArray();

  } // else {

    resultElement = 
    <div>

      <MaterialTable
      icons={tableIcons}
      title={"Password-generator | " + fullPath}
      columns={[
        { title: '#', field: 'seq', width: 'auto', export: true },
        { title: 'Password', field: 'name', width: 'auto', export: true },
      ]}
      data={resultData}        
      options={{
        exportButton: true,
        exportAllData: true,
        checkedNumber: false,
        search: false,
        pageSize: 10,
      }}
      />
      <br /><br />
    </div>;
//  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="指定した条件(強度)でパスワードを自動生成することができます。パスワードはCSVまたはPDFで一括ダウンロード<SaveAlt />できます。文字数は最大99文字まで、同時に最大999個までPasswordを自動 作成できます。パスワードの作り方がわからなくても、zipファイルや各種アカウント認証のためのパスワードをワンクリックでランダムに一括作成します。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="指定した条件(強度)でパスワードを自動生成することができます。パスワードはCSVまたはPDFで一括ダウンロード<SaveAlt />できます。文字数は最大99文字まで、同時に最大999個までPasswordを自動 作成できます。パスワードの作り方がわからなくても、zipファイルや各種アカウント認証のためのパスワードをワンクリックでランダムに一括作成します。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>
      
      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        
        <FormControl component="fieldset">
          
          <div>
            <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
              パスワードを生成する
            </Button>
          </div>

          <Grid justify="center" container spacing={1}>
            <Grid item>
              <Paper className={classes.paper} elevation={1}>

                <FormLabel component="legend" style={{fontWeight: '700'}}>文字種類</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={state.useUppercase} onChange={handleChange} name="useUppercase" color="primary"
                      inputRef={register({ required: false})}
                      />}
                      label="英字(大文字)"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={state.useLowercase} onChange={handleChange} name="useLowercase" color="primary" 
                      inputRef={register({ required: false})}
                      />}
                      label="英字(小文字)"
                    />
                    </FormGroup>
                    <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={state.useNumber} onChange={handleChange} name="useNumber" color="primary" 
                      inputRef={register({ required: false})}
                      />}
                      label="数字"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={state.useSymbol} onChange={handleChange} name="useSymbol" color="primary" 
                      inputRef={register({ required: false})}
                      />}
                      label="記号"
                    />
                  </FormGroup>
              </Paper>
            </Grid>

            <Grid item>
              <Paper className={classes.paper} elevation={1}>
               <FormLabel component="legend" style={{fontWeight: '700'}}>文字数</FormLabel>
                <RadioGroup aria-label="length" name="length" value={value} onChange={handleChangeForLength}>
                  <FormControlLabel value="8" control={<Radio />} label="8文字" inputRef={register({ required: true })}/>
                  <FormControlLabel value="12" control={<Radio />} label="12文字" inputRef={register({ required: true })}/>
                  <FormControlLabel value={lengthText} control={<Radio />} label="文字数を指定する" inputRef={register({ required: true })}/>
                </RadioGroup>

                <TextField
                  id="rate"
                  label="指定文字数(最大99)"
                  type="number"
                  name="lengthTextValue"
                  defaultValue={lengthTextValue}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  inputRef={register({ required: false, maxLength: 2 })}
                />
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.paper} elevation={1}>
                <FormLabel component="legend" style={{fontWeight: '700'}}>個数</FormLabel>
                <RadioGroup aria-label="quantity" name="quantity" value={quantityValue} onChange={handleChangeForQuantity}>
                  <FormControlLabel value="10" control={<Radio />} label="10" inputRef={register({ required: true })}/>
                  <FormControlLabel value="30" control={<Radio />} label="30" inputRef={register({ required: true })}/>
                  <FormControlLabel value={quantityText} control={<Radio />} label="個数を指定する" inputRef={register({ required: true })}/>
                </RadioGroup>
            
                <TextField
                  id="quantity"
                  label="指定個数(最大999)"
                  type="number"
                  name="quantityTextValue"
                  defaultValue={quantityTextValue}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  inputRef={register({ required: false, maxLength: 3 })}
                />
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.paper} elevation={1}>

                <FormLabel component="legend" style={{fontWeight: '700'}}>その他</FormLabel>
                <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.compelDuplicateString} onChange={handleChange} name="compelDuplicateString" color="primary"
                      inputRef={register({ required: false})}
                      />}
                      label="同じ文字を2回以上使わない"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={state.compelResembleString} onChange={handleChange} name="compelResembleString" color="primary" 
                      inputRef={register({ required: false})}
                      />}
                      label="似ている文字を使わない"
                    />
                </FormGroup>
              </Paper>
            </Grid>
          </Grid>

          <div>
            <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
              パスワードを生成する
            </Button>
          </div>
      
        </FormControl>

    </form>

    <div className={classes.table}>
      {resultElement}
    </div>

    <AccordionInstruction title="使い方">
      <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
      <Typography variant="body2" gutterBottom>
        指定した条件(強度)でパスワードを自動生成することができます。<br />
        パスワードはCSVまたはPDFで一括ダウンロード<SaveAlt />できます。<br />
        文字数は最大99文字まで、同時に最大999個までPasswordを自動 作成できます。<br />
        パスワードの作り方がわからなくても、zipファイルや各種アカウント認証のためのパスワードをワンクリックでランダムに一括作成します。<br />
        <br />
        ■記号文字について<br />
          以下の記号文字を使用しています。<br />
          {symboles}<br />
        <br />
        ■「似ている文字を使わない」について<br />
        「似ている文字を使わない」にチェックを入れると以下の文字が含まれません。<br />
        Il1Oo0<br />
        <br />
        ※「その他」項目を指定した場合は文字数は56文字までになります。<br />
        <br />
        関連リンク<br />
        ⇒<a href="/tools/qr-code-generator">QRコード作成</a><br />
        ⇒<a href="/tools/barcode-generator">バーコード作成</a>
      </Typography>
      </Box>
    </ AccordionInstruction>
    
    <AdSense.Google
      client='ca-pub-6460385026549088'
      slot='3710473082'
      style={{ display: 'block' }}
      layout='in-article'
      format='fluid'
    />
  
  </div>
  );
}

export default PasswordGenerator;
