import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  BarChart, Bar, Legend, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, ReferenceArea,
} from 'recharts';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import AdSense from 'react-adsense';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SnsShareButtons from '../../../components/sns/SnsShareButtons';
import ReactGA from "react-ga4";

const locationPath = "/calculator/finance/barista-fire-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "サイドFIREシミュレーター";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    backgroundColor: '#ffffff',
    width: '100%',
  },
  table: {
  },
  margin: {
    marginTop: '5px',
    marginBottom: '0px',
  },
  slider_margin: {
    marginBottom: '30px',
  },
}));

let chartElement, principal, monthly_deposit;
let finalAmount;
var chartData = [];
let rate, currentAge, retirementAge, yearlyExpenseGlobal, pensionStartAge, pensionStartAgeControled, 
    baristaFireStartAge, baristaFireEndAge, monthlySupplimentalImcome,
    yearlyExpenseDuringBaristaFire, yearlySupplimentalImcome, baristaFireStartAgeLabel, baristaFireEndAgeLabel;
    //yearlyExpenseAfterPensionStartGlobal;
var baristaFireAgeRange = [40, 65];

function setChartData(formValues) {

  var newChartData = [];
  let yearly_deposit, interest, total;
  principal = Number(formValues.principal);
  monthly_deposit = Number(formValues.monthly_deposit);
  yearly_deposit = 0;
  rate = Number(formValues.rate);
  interest = 0;
  pensionStartAgeControled = Number(pensionStartAge);
  currentAge = Number(formValues.currentAge);
  retirementAge = Number(retirementAge);
  let yearlyExpense, yearlyExpenseAfterPensionStart;
  yearlyExpense = Number(formValues.yearlyExpense * 10000);
  yearlyExpenseGlobal = formValues.yearlyExpense;
  yearlyExpenseAfterPensionStart = Number(formValues.yearlyExpenseAfterPensionStart) * 10000;
  //yearlyExpenseAfterPensionStartGlobal = formValues.yearlyExpenseAfterPensionStart;

  // Barista FIRE variables starts here
  let considerBaristaFire = formValues.considerBaristaFire;
  
  if (considerBaristaFire) {
    baristaFireStartAge = baristaFireAgeRange[0];
    if (baristaFireStartAge < currentAge) {
      baristaFireStartAge = currentAge;
    }
    baristaFireEndAge = baristaFireAgeRange[1];
    
    // Override retirementAge
    retirementAge = baristaFireStartAge;
    // Calc yearly supplimentalIncome
    yearlySupplimentalImcome = Number(formValues.monthlySupplimentalImcome) * 10000 * 12;

    
    if (yearlySupplimentalImcome > 0) {
      baristaFireStartAgeLabel = retirementAge + "歳";
      baristaFireEndAgeLabel = baristaFireEndAge + "歳";
    }
    // Set yearly expense during Barista fire
    yearlyExpenseDuringBaristaFire = yearlyExpense - yearlySupplimentalImcome;
    // simple implementation //TODO
    if (yearlyExpenseDuringBaristaFire <= 0) {
      // Set zero if it was negative.
      yearlyExpenseDuringBaristaFire = 0;
    }
    /**
    console.log("baristaFireStartAge:" + baristaFireStartAge);
    console.log("baristaFireEndAge:" + baristaFireEndAge);
    console.log("yearlySupplimentalImcome:" + yearlySupplimentalImcome);
    console.log("yearlyExpense:" + yearlyExpense);
    console.log("yearlyExpenseDuringBaristaFire:" + yearlyExpenseDuringBaristaFire);
    */
  } else {
    baristaFireStartAge = "";
    baristaFireEndAge = "";
  }
  // Barista FIRE variables ends here

  if (retirementAge < currentAge) {
    retirementAge = currentAge;
  }

  let principalAfterRetirement, yearlyDepositeAfterRetirement;
  let interestAfterRetirement = 0;

  let considerPension = formValues.considerPension;

  if (! considerPension) {
    pensionStartAgeControled = "";
  }

  for (let i = 0; i + currentAge <= 110; i++) {

    //Consider Barista Fire
    if (i + currentAge >= baristaFireStartAge && i + currentAge <= baristaFireEndAge) {
      // During Barista fire
      // Set yearly expense
      //yearlyExpense = yearlyExpenseDuringBaristaFire;
    }

    if (i + currentAge <= retirementAge) {
      
      yearly_deposit = yearly_deposit + (Number(formValues.monthly_deposit * 10000) * 12);
      interest = ((principal * 10000 + yearly_deposit + interest) * (rate / 100)) + interest; 
      total = (principal * 10000) + yearly_deposit + interest;
      
      principalAfterRetirement = principal * 10000;
      yearlyDepositeAfterRetirement = yearly_deposit;
      interestAfterRetirement = Math.round(interest);
      
      newChartData[i] = {name: currentAge + i + "歳", 元金: principal * 10000, 毎月積立額: yearly_deposit, リターン: Math.round(interest), 合計: Math.round(total)};
      
      finalAmount = principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement;

    } else {

      //Consider pension age
      if (considerPension && i + currentAge >= pensionStartAgeControled) {
        //Replace yearly expense amount
        yearlyExpense = yearlyExpenseAfterPensionStart;
      }
      
      let yearlyExpenseConsideringBaristaFire = yearlyExpense;
      let yearlyDepositeDuringBaristaFire = 0;

      if (i + currentAge >= baristaFireStartAge && i + currentAge <= baristaFireEndAge) {
        // During Barista fire

        yearlyExpenseConsideringBaristaFire = yearlyExpense - yearlySupplimentalImcome;
        if (yearlyExpenseConsideringBaristaFire < 0) {
          yearlyDepositeDuringBaristaFire = Math.abs(yearlyExpenseConsideringBaristaFire);
          /**
          console.log("yearlyExpenseConsideringBaristaFire:" + yearlyExpenseConsideringBaristaFire);
          console.log("yearlyDepositeDuringBaristaFire:" + yearlyDepositeDuringBaristaFire);
          */

          yearlyDepositeAfterRetirement = yearlyDepositeAfterRetirement + yearlyDepositeDuringBaristaFire;
          
          yearlyExpenseConsideringBaristaFire = 0;
        }
      }

      let totalAmount = principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement;

      if ((yearlyDepositeAfterRetirement + interestAfterRetirement) < yearlyExpenseConsideringBaristaFire) {
        principalAfterRetirement = totalAmount - yearlyExpenseConsideringBaristaFire;
        yearlyDepositeAfterRetirement = 0;

        if (principalAfterRetirement < 0) {
          interestAfterRetirement = 0;

          principalAfterRetirement = principalAfterRetirement + yearlyDepositeAfterRetirement;
          yearlyDepositeAfterRetirement = 0;
        } else {
          interestAfterRetirement = (Math.round((principalAfterRetirement) * (rate / 100))); 
        }
      } else if (interestAfterRetirement < yearlyExpenseConsideringBaristaFire) {
        //TODO
        yearlyDepositeAfterRetirement = yearlyDepositeAfterRetirement + interestAfterRetirement - yearlyExpenseConsideringBaristaFire;
        interestAfterRetirement = 0;
        interestAfterRetirement = interestAfterRetirement + (Math.round((principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement) * (rate / 100)));
      } else {
        //TODO
        interestAfterRetirement = interestAfterRetirement - yearlyExpenseConsideringBaristaFire;
        interestAfterRetirement = interestAfterRetirement + (Math.round((principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement) * (rate / 100)));
      }
      
      newChartData[i] = {name: currentAge + i + "歳", 元金: principalAfterRetirement, 毎月積立額: yearlyDepositeAfterRetirement, リターン: interestAfterRetirement, 合計: principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement};
    }
  }

  //Important!
  chartData = newChartData;

}

/**
function valuetext(value) {
  retirementAge = value;
  return `${value}`;
} */

function valuetextBaristaFire(value) {
  return `${value}`;
}
/**
function valuetextPensionStartAge(value) {
  pensionStartAge = value;
  return `${value}`;
}
*/
function BaristaFireCalculator() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);

  const [state, setState] = React.useState({
    considerPension: false,
    considerBaristaFire: true,
  });

  const [valueBaristaFire, setValue] = React.useState(baristaFireAgeRange);

  const handleChangeBaristaFireAge = (event, newValue) => {
    setValue(newValue);
    baristaFireAgeRange = valueBaristaFire;
  };
/**
  const handleSyncBaristaFireAge = (event, newValue) => {
    setValue(newValue);
    baristaFireAgeRange[0] = pensionStartAge;
    baristaFireAgeRange[1] = pensionStartAge + 5;
  };
*/

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setChartData(data)
  
  if(chartData.length === 0) {

    rate = 4;
    yearlyExpenseGlobal = 300;
    retirementAge = 35;
    //yearlyExpenseAfterPensionStartGlobal = 150;
    pensionStartAge = 65;
    monthlySupplimentalImcome = 10;
    //baristaFireAgeRange = [35, 45];

  } else {

    chartElement = 
    <div>
      <br />
      <div style={{ width: '100%'}}>
        <TextField
          id="outlined-read-only-input"
          label="サイドFIRE開始時の積立金額"
          defaultValue={finalAmount.toLocaleString()}
          value={finalAmount.toLocaleString()}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      </div>

      <div style={{ width: '800px', height: '450px', backgroundColor: '#FFFFFF', paddingLeft: '220px' }}>
        <ResponsiveContainer>
          <BarChart
            width="100%"
            height={300}
            data={chartData}
            margin={{
              top: 40, right: 30, left: 30, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={tick => {
                return (tick / 10000).toLocaleString() + "万円";
            }}/>
            <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)}/>
            <Legend />
            <Bar dataKey="元金" stackId="a" fill="#8884d8" />
            <Bar dataKey="毎月積立額" stackId="a" fill="#82ca9d" />
            <Bar dataKey="リターン" stackId="a" fill="#ff7f50" />
            <ReferenceLine y={0} label="" stroke="red" />
            <ReferenceLine x={pensionStartAgeControled + '歳'} stroke="blue" label={{value:"年金受給開始年齢", position:"top", fill:"blue"}} />
            <ReferenceArea x1={baristaFireStartAgeLabel} x2={baristaFireEndAgeLabel} stroke="green" strokeOpacity={0.3} label={{value:"サイドFIRE期間", position:"insideTop", fill:"green"}} isFront="true" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div style={{ maxWidth:'720px', paddingLeft: '220px' }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>年齢</TableCell>
                <TableCell align="right">元金</TableCell>
                <TableCell align="right">積立金額</TableCell>
                <TableCell align="right">リターン</TableCell>
                <TableCell align="right">合計</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chartData.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row" align="right">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{Number(row.元金).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.毎月積立額).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.リターン).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.合計).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  }

  return (
    <div className="App-header">
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="サイドFIREとは、早期退職後の生活費の不安を軽減するためにパートタイムの補助収入を得ながらセミリタイア生活することを言います。FIREとは経済的独立と早期リタイアのことでFinancial Independence, Retire Earlyの頭文字を取った言葉です。FIREはリーンFIREとリッチFIREに分類されています。リーンFIREとは最小限の資産形成と退職金によって早期退職し、退職後は質素な生活が必要になることを言います。リッチFIREとは多くの資産と不労所得によって早期退職し、退職後の生活費の不安が無いことを言います。この2つのハイブリッドと言われているのが米国でバリスタFIREとして知られ、日本ではサイドFIREと言われているようです。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="サイドFIREとは、早期退職後の生活費の不安を軽減するためにパートタイムの補助収入を得ながらセミリタイア生活することを言います。FIREとは経済的独立と早期リタイアのことでFinancial Independence, Retire Earlyの頭文字を取った言葉です。FIREはリーンFIREとリッチFIREに分類されています。リーンFIREとは最小限の資産形成と退職金によって早期退職し、退職後は質素な生活が必要になることを言います。リッチFIREとは多くの資産と不労所得によって早期退職し、退職後の生活費の不安が無いことを言います。この2つのハイブリッドと言われているのが米国でバリスタFIREとして知られ、日本ではサイドFIREと言われているようです。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div className={classes.snsButtonMargin}>
        <SnsShareButtons fullPath={fullPath} title={title} hashtag={["サイドFIREシミュレーター", "セミリタイヤ"]} />
      </div>
      
      <form name="form1" className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

        <AdSense.Google
          client='ca-pub-6460385026549088'
          slot='1650481640'
          style={{ display: 'block' }}
          format='auto'
          responsive='true'
          layoutKey='-gw-1+2a-9x+5c'
        />

        <div>
          <TextField
            id="years"
            label="現在年齢(歳)"
            type="number"
            name="currentAge"
            defaultValue={currentAge}
            inputRef={register({ required: true, maxLength: 3 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>
        <div>
          <TextField
            id="principal"
            label="元金(万円)"
            type="number"
            name="principal"
            defaultValue={principal}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            inputRef={register({ required: true, maxLength: 20 })}
          />
        </div>
        <div>
          <TextField
            id="monthly-deposit"
            label="毎月積立額(万円) 例:3.33"
            type="number"
            name="monthly_deposit"
            defaultValue={monthly_deposit}
            inputRef={register({ required: true, maxLength: 20 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>
        <div>
          <TextField
            id="rate"
            label="実質リターン(%)"
            type="number"
            name="rate"
            defaultValue={rate}
            inputRef={register({ required: true, maxLength: 20 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>

        {/**
        <div style={{width: '200px', marginLeft: 'auto', marginRight: 'auto'}}>
          <Typography id="discrete-slider-small-steps" gutterBottom className="MuiFormLabel-root MuiInputLabel-shrink ">
            リタイア年齢
          </Typography>
          <Slider
            name="retirementAge"
            defaultValue={retirementAge}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider-small-steps"
            step={1}
            marks
            min={20}
            max={90}
            valueLabelDisplay="on"
            //onChange={handleSyncBaristaFireAge}
          />
        </div>
        */}

        <div>
          <TextField
            id="yearlyExpense"
            label="サイドFIRE開始後の年間支出(万円)"
            type="number"
            name="yearlyExpense"
            defaultValue={yearlyExpenseGlobal}
            inputRef={register({ required: false, maxLength: 20 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>

        <div className={classes.margin}>
        <Accordion expanded={state.considerBaristaFire} style={{maxWidth:"300px", marginLeft: 'auto', marginRight: 'auto'}}>
          <AccordionSummary
            /*expandIcon={<ExpandMoreIcon />}*/
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >

            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={<Checkbox  checked={state.considerBaristaFire} onChange={handleChange} name="considerBaristaFire" color="primary" disabled="true" 
              inputRef={register({ required: false})}/>}
              label="サイドFIREする"
            />

          </AccordionSummary>

          <AccordionDetails>
            
            <div style={{width: '200px', marginLeft: 'auto', marginRight: 'auto'}}>
              <div  className={classes.slider_margin}>
                <Typography id="discrete-slider-small-steps" gutterBottom className="MuiFormLabel-root MuiInputLabel-shrink">
                  サイドFIRE開始/終了年齢
                </Typography>
              </div>

              <Slider
                onChange={handleChangeBaristaFireAge}
                aria-labelledby="range-slider"
                getAriaValueText={valuetextBaristaFire}
                valueLabelDisplay="on"
                step={1}
                max={90}
                min={20}
                value={valueBaristaFire}
              />

              <TextField
                id="supplimentalIncome"
                label="毎月補助収入(万円)"
                type="number"
                name="monthlySupplimentalImcome"
                defaultValue={monthlySupplimentalImcome}
                inputRef={register({ required: false, maxLength: 20 })}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </div>

          </AccordionDetails>
        </Accordion>

        </div>
        
        {/**
        <div className={classes.margin}>
        <Accordion expanded={state.considerPension} style={{maxWidth:"300px", marginLeft: 'auto', marginRight: 'auto'}}>
          <AccordionSummary
            //expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >

            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={<Checkbox  checked={state.considerPension} onChange={handleChange} name="considerPension" color="primary" 
              inputRef={register({ required: false})}/>}
              label="年金情報を入力する"
            />

          </AccordionSummary>

          <AccordionDetails>
            
            <div style={{width: '200px', marginLeft: 'auto', marginRight: 'auto'}}>
              <Typography id="discrete-slider-small-steps" gutterBottom className="MuiFormLabel-root MuiInputLabel-shrink">
                年金受給開始年齢
              </Typography>
              <Slider
                name="pensionStartAge"
                defaultValue={pensionStartAge}
                getAriaValueText={valuetextPensionStartAge}
                aria-labelledby="discrete-slider-small-steps"
                step={1}
                marks
                min={60}
                max={75}
                valueLabelDisplay="on"
              />

              <TextField
                id="yearlyExpense"
                label="年金受給開始後の年間支出(万円)"
                type="number"
                name="yearlyExpenseAfterPensionStart"
                defaultValue={yearlyExpenseAfterPensionStartGlobal}
                inputRef={register({ required: false, maxLength: 20 })}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </div>

          </AccordionDetails>
        </Accordion>
        </div>
        */}

        <div>
          <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
            計算する
          </Button>
          <AdSense.Google
            client='ca-pub-6460385026549088'
            slot='1365141965'
            style={{ display: 'block' }}
            layout='in-article'
            format='fluid'
          />
        </div>
      </form>

      <div id="chart">
        {chartElement}
      </div>

      <div>
        <AccordionInstruction title="説明">
          <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
            <Typography variant="body2" gutterBottom>
              このサイドFIREシミュレーターは<Link href="/calculator/finance/fire-simulator">FIREシミュレーター</Link>の拡張機能です。グラフで可視化することでサイドFIREの基本イメージを掴むことができます。<br />
              サイドFIREとは、早期退職後の生活費の不安を軽減するために補助収入を得ながらセミリタイア生活することを言います。<br />
              FIREとは経済的独立と早期リタイアのことでFinancial Independence, Retire Earlyの頭文字を取った言葉です。<br />
              <br />
              FIREにはリーンFIREとファットFIREと分類されているものがあります。<br />
              ・リーンFIREとは最小限の資産形成と退職金によって早期退職し、退職後は質素な生活が必要になることを言います。<br />
              ・ファットFIREとは多くの資産と不労所得によって早期退職し、退職後の生活費の不安が無いことを言います。<br />
              この2つのハイブリッドと言われているのが米国で"バリスタFIRE"として知られ、日本では類似のことがサイドFIREと言われているようです。<br />
              米国では国民皆保険制度がないため、時間的自由を得ながら健康保険制度に加入することを目的としてパートタイマーになるという背景があるようです。<br />
              
              <br />
              サイドFIREをする期間、毎月の補助収入を入力して「計算する」ボタンを押せばサイドFIRE計算のシミュレーションが出来ます。<br />
              サイドFIRE開始年齢までは複利の力で資産形成をします。サイドFIRE期間中はパートタイムの収入を得ながらセミリタイヤ生活します。<br />
              「毎月補助収入」が支出を上回る場合は、余剰分を毎月積立額に累計することとします。<br />
              試算結果をグラフと表で表示します。<br />
              <br />
              将来をシミュレーションをすることは単純ではありませんが、ツールの簡易性を優先して出来るだけシンプルに使えるようにしています。<br />
              FIRE戦略を考えながら色々なパターンを入力して計算してみましょう。<br />
              <br />

              参考になるサイドFIRE関連の書籍も多く出版されています。一冊読んでみると更にイメージできるようになりますので是非読んでみてください。<br />
              <br />
              外部リンク：<Link target="_blank" href="https://amzn.to/3lTJbHn" rel="nofollow noopener noreferrer">Amazon.co.jp - サイドFIRE関連書籍一覧</Link><br />
              
              <br />
              <br />
              サイト内リンク<br />
              ⇒<Link href="/calculator/finance/fire-simulator">FIREシミュレーター</Link><br />
              ⇒<Link href="/calculator/finance/compound-interest-calculator">複利計算</Link><br />
              ⇒<Link href="/calculator/finance/savings-rate-calculator">貯蓄率計算</Link><br />
              ⇒<Link href="/calculator/finance/expected-asset-amount-calculator">期待資産額計算</Link><br />
              ⇒<Link href="/calculator/healthcare/expected-life-at-age-calculator">平均余命計算</Link><br />
              ⇒<Link href="/calculator/finance/human-life-value-calculator">生命価値計算</Link>
            </Typography>
          </Box>
        </ AccordionInstruction>
        
        <AdSense.Google
          client='ca-pub-6460385026549088'
          slot='3710473082'
          style={{ display: 'block' }}
          layout='in-article'
          format='fluid'
        />

      </div>

    </div>
  );
}

export default BaristaFireCalculator;
