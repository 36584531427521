import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import ReactGA from "react-ga4";


const locationPath = "/tools/color-picker";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "カラーピッカー";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    marginTop: '50px',
  },
  colorPickerStyle: {
    padding: '10px',
  },
}));

function ColorPickerTool() {
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const [color, setColor] = useColor("hex", "#121212");

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="カラーピッカーです。カラーパレットを見ながら色を選ぶことができます。選んだ色のHEX、RGB、HSVの3種類のカラーコードが表示されます。HEX、RGB、HSVを指定して選ぶこともできます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="カラーピッカーです。カラーパレットを見ながら色を選ぶことができます。選んだ色のHEX、RGB、HSVの3種類のカラーコードが表示されます。HEX、RGB、HSVを指定して選ぶこともできます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div className={classes.colorPickerStyle}>

        <ColorPicker width={330} height={228} color={color} onChange={setColor}  />

      </div>

      <AccordionInstruction title="使い方">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            カラーピッカーです。カラーパレットを見ながら色を選ぶことができます。選んだ色のHEX、RGB、HSVの3種類のカラーコードが表示されます。<br />
            HEX、RGB、HSVを指定して選ぶこともできます。
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default ColorPickerTool;
