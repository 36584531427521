import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import ReactGA from "react-ga4";


const locationPath = "/calculator/converter/byte-converter";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "バイト換算";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    backgroundColor: '#ffffff',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
  },
  paper: {
    width: "fit-content",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

let resultElement, inputValue;
var resultData = [];
let unit;

function createData(unit, value) {
  return { unit, value };
}

function setResultData(formValues) {

  //console.log("setCharData!");
  
  var newResultData = [];
  inputValue = Number(formValues.inputValue);
  unit = formValues.unit;

  let bytes, kbytes, mbytes, gbytes, tbytes;
  const unitValue = 1024;

  switch (unit) {
    case 'B':
      bytes = inputValue;
      kbytes = inputValue / unitValue;
      mbytes = kbytes / unitValue;
      gbytes = mbytes / unitValue;
      tbytes = gbytes / unitValue;
      break;
    case 'KB':
      kbytes = inputValue;
      bytes = kbytes * unitValue;
      mbytes = kbytes / unitValue;
      gbytes = mbytes / unitValue;
      tbytes = gbytes / unitValue;
      break;
    case 'MB':
      mbytes = inputValue;
      kbytes = mbytes * unitValue;
      bytes = kbytes * unitValue;
      gbytes = mbytes / unitValue;
      tbytes = gbytes / unitValue;
      break;
    case 'GB':
      gbytes = inputValue;
      mbytes = gbytes * unitValue;
      kbytes = mbytes * unitValue;
      bytes = kbytes * unitValue;
      tbytes = gbytes / unitValue;
    break;
    case 'TB':
      tbytes = inputValue;
      gbytes = tbytes * unitValue;
      mbytes = gbytes * unitValue;
      kbytes = mbytes * unitValue;
      bytes = kbytes * unitValue;
      break;
    default:
  }

  newResultData = [
    createData("B(バイト)", bytes),
    createData("KB(キロバイト)", kbytes),
    createData("MB(メガバイト)", mbytes),
    createData("GB(ギガバイト)", gbytes),
    createData("TB(テラバイト)", tbytes),
  ];

  //Important!
  resultData = newResultData;
}

function ByteConverter() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setResultData(data)
  const [state, setState] = React.useState({
    unit: '',
    name: '',
  });

  const handleChangeForUnit = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  
  if(resultData.length === 0) {

  } else {

    resultElement = 
    <div>
      <TableContainer component={Paper} className={classes.paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>単位</TableCell>
              <TableCell align="right">バイト数</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resultData.map((row) => (
              <TableRow key={row.rate}>
                <TableCell component="th" scope="row" align="left">
                  {row.unit}
                </TableCell>
                <TableCell align="right">{Number(row.value).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="バイト換算。バイト(B)、キロバイト(KB)、メガバイト(MB)、ギガバイト(GB)、テラバイト(TB)を選択して換算することができます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="バイト換算。バイト(B)、キロバイト(KB)、メガバイト(MB)、ギガバイト(GB)、テラバイト(TB)を選択して換算することができます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

       <div>
        <TextField
          id="inputValute"
          label="入力"
          type="number"
          name="inputValue"
          defaultValue={inputValue}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 20 })}
        />
        </div>

        <div>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple" >単位</InputLabel>
            <Select
              native
              defaultValue={unit}
              onChange={handleChangeForUnit}
              inputProps={{
                name: 'unit',
                id: 'age-native-simple',
              }}
              inputRef={register({ required: true })}
            >
              <option value='B'>B(バイト)</option>
              <option value='KB'>KB(キロバイト)</option>
              <option value='MB'>MB(メガバイト)</option>
              <option value='GB'>GB(ギガバイト)</option>
              <option value='TB'>TB(テラバイト)</option>
            </Select>
          </FormControl>
        </div>

        <div>
        <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
            換算する
          </Button>
        </div>
        <br />
        
      </form>

      <div id="result">
        {resultElement}
      </div>

      <AccordionInstruction title="使い方">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
        <Typography variant="body2" gutterBottom>
          バイト(情報量)の換算をします。<br />
          バイト数を入力して、単位を選択します。<br />
          単位は以下の中から選択することができます。<br />
          ・バイト(B)<br />
          ・キロバイト(KB)<br />
          ・メガバイト(MB)<br />
          ・ギガバイト(GB)<br />
          ・テラバイト(TB)<br />
          <br />
          機能改善のご意見・ご要望などございましたらお問い合わせからご連絡ください。<br />
        </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );
}

export default ByteConverter;
