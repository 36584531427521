import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
  } from 'recharts';

export default function SimpleBarChart(props) {

    let data = [
        {
          name: props.nameValue,
          値: props.totalNumber,
        },
        {
          name: props.percentageMain,
          値: props.valueMain,
        },
      ];

    return (
        <ResponsiveContainer width="100%" height={360}>
            <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
                top: 25,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <ReferenceLine y={0} stroke="#000" />
            <Bar dataKey="値" fill="#8884d8" label={{ fill: 'black', fontSize: 20 }} />
            </BarChart>
        </ResponsiveContainer>
    );
}
