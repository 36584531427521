import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import DialpadIcon from '@material-ui/icons/Dialpad';
import {useLocation} from 'react-router-dom';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ComputerIcon from '@material-ui/icons/Computer';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';

const drawerWidth = 280;
let barTitle;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  rootNested: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));


export default function PersistentDrawerLeftNested() {
  
  const pathname = useLocation().pathname;
  
  switch (pathname) {
    case '/about':
      barTitle = "サイトについて";
      break;
    case '/privacy-policy':
        barTitle = "プライバシーポリシー・免責事項";
        break;
    case '/inquiry':
      barTitle = "お問い合わせ";
      break;
    case '/calculator/finance/leave-allowance-monthly-salary-calculator':
      barTitle = "休業手当計算 (月給の場合)";
      break;
    case '/calculator/finance/compound-interest-calculator':
      barTitle = "複利計算";
      break;
    case '/calculator/finance/fire-calculator':
      barTitle = "FIREシミュレーター";
      break;
    case '/calculator/finance/fire-simulator':
        barTitle = "FIREシミュレーター";
        break;
    case '/calculator/finance/barista-fire-calculator':
        barTitle = "サイドFIREシミュレーター";
        break;
    case '/calculator/finance/savings-rate-calculator':
      barTitle = "貯蓄率計算";
      break;
    case '/calculator/finance/expected-asset-amount-calculator':
      barTitle = "期待資産額計算";
      break;
    case '/calculator/finance/human-life-value-calculator':
      barTitle = "生命価値計算";
    break;
    case '/calculator/finance/consumption-tax-calculator':
      barTitle = "消費税計算";
      break;
    case '/calculator/converter/gram-to-ounce-converter':
      barTitle = "グラム⇔オンス換算";
      break;
    case '/calculator/converter/inch-to-centimeter-converter':
      barTitle = "インチ⇔センチ換算";
      break;
    case '/calculator/converter/mile-to-kilometer-converter':
      barTitle = "マイル⇔キロメートル換算";
      break;
    case '/calculator/converter/byte-converter':
      barTitle = "バイト換算";
      break;
    case '/calculator/converter/torque-converter':
      barTitle = "トルク換算";
      break;
    case '/calculator/converter/pressure-converter':
      barTitle = "圧力換算";
      break;
    case '/calculator/converter/celsius-to-fahrenheit-converter':
      barTitle = "摂氏⇔華氏換算";
      break;
    case '/calculator/converter/quart-to-litre-converter':
      barTitle = "クォート⇔リットル換算";
      break;
    case '/calculator/converter/square-metre-tsubo-tatami-converter':
      barTitle = "平米計算ツール";
      break;
    case '/calculator/converter/meter-to-yard-converter':
      barTitle = "メートル⇔ヤード換算";
      break;
    case '/calculator/healthcare/bmi-calculator':
      barTitle = "BMI計算";
      break;
    case '/calculator/healthcare/basal-metabolic-rate-calculator':
        barTitle = "BMR 基礎代謝量計算ツール(グラフ表示)";
      break;
    case '/calculator/healthcare/expected-life-at-age-calculator':
      barTitle = "平均余命計算";
      break;
    case '/calculator/calendar/date-calculator':
      barTitle = "日数計算";
      break;
    case '/calculator/calendar/time-calculator':
      barTitle = "時間計算 (終了時間 - 開始時間)";
      break;
    case '/calculator/calendar/time-difference-calculator':
      barTitle = "時差計算 (タイムゾーン)";
      break;
    case '/tools/password-generator':
      barTitle = "パスワード生成";
      break;
    case '/tools/qr-code-generator':
      barTitle = "QRコード作成";
      break;
    case '/tools/barcode-generator':
      barTitle = "バーコード作成";
      break;
    case '/calculator/math/percentage-calculator':
      barTitle = "パーセント計算";
      break;
    case '/tools/color-picker':
      barTitle = "カラーピッカー";
      break;
    case '/tools/sketch-canvas':
      barTitle = "お絵描きサイト";
      break;
    case '/tools/character-counter':
      barTitle = "文字数カウント";
      break;
    case '/tools/xml-formatter':
      barTitle = "XMLフォーマッター";
    break;
    case '/tools/json-formatter':
      barTitle = "JSONフォーマッター";
    break;
    case '/tools/aspect-ratio-calculator':
      barTitle = "アスペクト比計算ツール";
    break;
    case '/game/chessboard':
      barTitle = "チェスボード";
      break;
    case '/en/calculator/finance/simple-fire-calculator':
        barTitle = "Simple FIRE Calculator";
        break;
    case '/en/calculator/math/percentage-calculator':
        barTitle = "Percentage Calculator";
        break;
    case '/en':
        barTitle = "Useful calculator site";
        break;
    case '/en/':
        barTitle = "Useful calculator site";
        break;
    default:
      barTitle = "Life-Util";
      //document.title = barTitle;
  }

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  // Add for menu starts
  const [nestOpenHealthcare, setNestOpenHealthcare] = React.useState(false);
  const [nestOpenCalendar, setNestOpenCalendar] = React.useState(false);
  const [nestOpenFinance, setNestOpenFinance] = React.useState(false);
  const [nestOpenUnitConversion, setNestOpenUnitConversion] = React.useState(false);
  const [nestOpenItTool, setNestOpenItTool] = React.useState(false);
  const [nestOpenMath, setNestOpenMath] = React.useState(false);
  const [nestOpenGame, setNestOpenGame] = React.useState(false);
  // Add for menu ends

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerCloseWithChangingTitle = (titleStr) => {
    setOpen(false);
    document.title = titleStr;

    //setNestOpenHealthcare(false);
    //setNestOpenCalendar(false);
  };

  //Add for parent menu starts
  const handleClickHealthcare = () => {
    //console.log("click!");
    setNestOpenHealthcare(!nestOpenHealthcare);
  };

  const handleClickCalendar = () => {
    //console.log("click!");
    setNestOpenCalendar(!nestOpenCalendar);
  };

  const handleClickFinance = () => {
    //console.log("click!");
    setNestOpenFinance(!nestOpenFinance);
  };

  const handleClickUnitConversion = () => {
    //console.log("click!");
    setNestOpenUnitConversion(!nestOpenUnitConversion);
  };

  const handleClickItTool = () => {
    //console.log("click!");
    setNestOpenItTool(!nestOpenItTool);
  };

  const handleClickMath = () => {
    //console.log("click!");
    setNestOpenMath(!nestOpenMath);
  };

  const handleClickGame = () => {
    //console.log("click!");
    setNestOpenGame(!nestOpenGame);
  };
  //Add for parent menu ends

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="h1" id="docTitle" noWrap>
            {barTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />

        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.rootNested}
        >

            <ListItem button key="ホーム" component={Link} to="/" onClick={() => handleDrawerCloseWithChangingTitle("Life-Util")}>
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText>ホーム</ListItemText>
            </ListItem>
            
            <Divider />
        
            <ListItem button onClick={handleClickHealthcare}>
                <ListItemIcon>
                  <LocalHospitalIcon />
                </ListItemIcon>
                <ListItemText primary="ヘルスケア" />
                {nestOpenHealthcare ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={nestOpenHealthcare} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested}　component={Link} to="/calculator/healthcare/bmi-calculator" onClick={() => handleDrawerCloseWithChangingTitle("BMI計算")}>
                      <ListItemText primary="BMI計算" />
                  </ListItem>
                  <ListItem button className={classes.nested}　component={Link} to="/calculator/healthcare/basal-metabolic-rate-calculator" onClick={() => handleDrawerCloseWithChangingTitle("基礎代謝量計算(BMR計算)")}>
                      <ListItemText primary="BMR計算" />
                  </ListItem>
                  <ListItem button className={classes.nested}　component={Link} to="/calculator/healthcare/expected-life-at-age-calculator" onClick={() => handleDrawerCloseWithChangingTitle("平均余命計算")}>
                      <ListItemText primary="平均余命計算" />
                  </ListItem>
                </List>
            </Collapse>

            <ListItem button onClick={handleClickCalendar}>
                <ListItemIcon>
                  <CalendarTodayIcon />
                </ListItemIcon>
                <ListItemText primary="カレンダー" />
                {nestOpenCalendar ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={nestOpenCalendar} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested}　component={Link} to="/calculator/calendar/date-calculator" onClick={() => handleDrawerCloseWithChangingTitle("日数計算")}>
                      <ListItemText primary="日数計算" />
                  </ListItem>
                  <ListItem button className={classes.nested}　component={Link} to="/calculator/calendar/time-calculator" onClick={() => handleDrawerCloseWithChangingTitle("時間計算 (終了時間 - 開始時間)")}>
                      <ListItemText primary="時間計算 (終了時間 - 開始時間)" />
                  </ListItem>
                  <ListItem button className={classes.nested}　component={Link} to="/calculator/calendar/time-difference-calculator" onClick={() => handleDrawerCloseWithChangingTitle("時差計算 (タイムゾーン)")}>
                      <ListItemText primary="時差計算 (タイムゾーン)" />
                  </ListItem>
                </List>
            </Collapse>

            <ListItem button onClick={handleClickFinance}>
                <ListItemIcon>
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary="ファイナンス" />
                {nestOpenFinance ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={nestOpenFinance} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/finance/leave-allowance-monthly-salary-calculator" onClick={() => handleDrawerCloseWithChangingTitle("休業手当計算(月給の場合)")}>
                        <ListItemText primary="休業手当計算 (月給の場合)" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/finance/consumption-tax-calculator" onClick={() => handleDrawerCloseWithChangingTitle("消費税計算")}>
                        <ListItemText primary="消費税計算" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/finance/compound-interest-calculator" onClick={() => handleDrawerCloseWithChangingTitle("複利計算")}>
                        <ListItemText primary="複利計算" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/finance/fire-simulator" onClick={() => handleDrawerCloseWithChangingTitle("FIREシミュレーター (FIRE計算)")}>
                        <ListItemText primary="FIREシミュレーター" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/finance/barista-fire-calculator" onClick={() => handleDrawerCloseWithChangingTitle("サイドFIREシミュレーター (サイドFIRE計算)")}>
                        <ListItemText primary="サイドFIREシミュレーター" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/finance/savings-rate-calculator" onClick={() => handleDrawerCloseWithChangingTitle("貯蓄率計算")}>
                        <ListItemText primary="貯蓄率計算" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/finance/expected-asset-amount-calculator" onClick={() => handleDrawerCloseWithChangingTitle("期待資産額計算")}>
                        <ListItemText primary="期待資産額計算" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/finance/human-life-value-calculator" onClick={() => handleDrawerCloseWithChangingTitle("生命価値計算")}>
                        <ListItemText primary="生命価値計算" />
                    </ListItem>
                </List>
            </Collapse>

            <ListItem button onClick={handleClickUnitConversion}>
                <ListItemIcon>
                  <DialpadIcon />
                </ListItemIcon>
                <ListItemText primary="単位換算" />
                {nestOpenUnitConversion ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={nestOpenUnitConversion} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/converter/gram-to-ounce-converter" onClick={() => handleDrawerCloseWithChangingTitle("グラム⇔オンス換算")}>
                        <ListItemText primary="グラム⇔オンス換算" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/converter/inch-to-centimeter-converter" onClick={() => handleDrawerCloseWithChangingTitle("インチ⇔センチ換算")}>
                        <ListItemText primary="インチ⇔センチ換算" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/converter/mile-to-kilometer-converter" onClick={() => handleDrawerCloseWithChangingTitle("マイル⇔キロメートル換算")}>
                        <ListItemText primary="マイル⇔キロメートル換算" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/converter/meter-to-yard-converter" onClick={() => handleDrawerCloseWithChangingTitle("メートル⇔ヤード換算")}>
                        <ListItemText primary="メートル⇔ヤード換算" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/converter/byte-converter" onClick={() => handleDrawerCloseWithChangingTitle("バイト換算")}>
                        <ListItemText primary="バイト換算" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/converter/square-metre-tsubo-tatami-converter" onClick={() => handleDrawerCloseWithChangingTitle("平米計算 (坪/平米/畳)")}>
                        <ListItemText primary="平米計算 (坪/平米/畳)" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/converter/torque-converter" onClick={() => handleDrawerCloseWithChangingTitle("トルク換算")}>
                        <ListItemText primary="トルク換算" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/converter/pressure-converter" onClick={() => handleDrawerCloseWithChangingTitle("圧力換算")}>
                        <ListItemText primary="圧力換算" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/converter/celsius-to-fahrenheit-converter" onClick={() => handleDrawerCloseWithChangingTitle("摂氏⇔華氏換算")}>
                        <ListItemText primary="摂氏⇔華氏換算" />
                    </ListItem>
                    <ListItem button className={classes.nested}　component={Link} to="/calculator/converter/quart-to-litre-converter" onClick={() => handleDrawerCloseWithChangingTitle("クォート⇔リットル換算")}>
                        <ListItemText primary="クォート⇔リットル換算" />
                    </ListItem>
                </List>
            </Collapse>

            <ListItem button onClick={handleClickItTool}>
                <ListItemIcon>
                  <ComputerIcon />
                </ListItemIcon>
                <ListItemText primary="ITツール" />
                {nestOpenItTool ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={nestOpenItTool} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested}　component={Link} to="/tools/password-generator" onClick={() => handleDrawerCloseWithChangingTitle("パスワード生成")}>
                      <ListItemText primary="パスワード生成" />
                  </ListItem>
                  <ListItem button className={classes.nested}　component={Link} to="/tools/qr-code-generator" onClick={() => handleDrawerCloseWithChangingTitle("QRコード作成")}>
                      <ListItemText primary="QRコード作成" />
                  </ListItem>
                  <ListItem button className={classes.nested}　component={Link} to="/tools/barcode-generator" onClick={() => handleDrawerCloseWithChangingTitle("バーコード作成")}>
                      <ListItemText primary="バーコード作成" />
                  </ListItem>
                  <ListItem button className={classes.nested}　component={Link} to="/tools/color-picker" onClick={() => handleDrawerCloseWithChangingTitle("カラーピッカー")}>
                      <ListItemText primary="カラーピッカー" />
                  </ListItem>
                  <ListItem button className={classes.nested}　component={Link} to="/tools/sketch-canvas" onClick={() => handleDrawerCloseWithChangingTitle("お絵描きサイト")}>
                      <ListItemText primary="お絵描きサイト" />
                  </ListItem>
                  <ListItem button className={classes.nested}　component={Link} to="/tools/character-counter" onClick={() => handleDrawerCloseWithChangingTitle("文字数カウント")}>
                      <ListItemText primary="文字数カウント" />
                  </ListItem>
                  <ListItem button className={classes.nested}　component={Link} to="/tools/xml-formatter" onClick={() => handleDrawerCloseWithChangingTitle("XMLフォーマッター")}>
                      <ListItemText primary="XMLフォーマッター" />
                  </ListItem>
                  <ListItem button className={classes.nested}　component={Link} to="/tools/json-formatter" onClick={() => handleDrawerCloseWithChangingTitle("JSONフォーマッター")}>
                      <ListItemText primary="JSONフォーマッター" />
                  </ListItem>
                  <ListItem button className={classes.nested}　component={Link} to="/tools/aspect-ratio-calculator" onClick={() => handleDrawerCloseWithChangingTitle("アスペクト比計算ツール")}>
                      <ListItemText primary="アスペクト比計算ツール" />
                  </ListItem>
                </List>
            </Collapse>

            <ListItem button onClick={handleClickMath}>
                <ListItemIcon>
                  <ComputerIcon />
                </ListItemIcon>
                <ListItemText primary="数学" />
                {nestOpenMath ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={nestOpenMath} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <ListItem button className={classes.nested}　component={Link} to="/calculator/math/percentage-calculator" onClick={() => handleDrawerCloseWithChangingTitle("パーセント計算")}>
                    <ListItemText primary="パーセント計算" />
                </ListItem>
                </List>
            </Collapse>

            <ListItem button onClick={handleClickGame}>
                <ListItemIcon>
                  <SportsEsportsIcon />
                </ListItemIcon>
                <ListItemText primary="ゲーム" />
                {nestOpenGame ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={nestOpenGame} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <ListItem button className={classes.nested}　component={Link} to="/game/chessboard" onClick={() => handleDrawerCloseWithChangingTitle("チェスボード")}>
                    <ListItemText primary="チェスボード" />
                </ListItem>
                </List>
            </Collapse>

        </List>
      </Drawer>

      <div>
      <br />
      <br />

    </div>
    
    </div>
  );
}
