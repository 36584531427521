import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AdSense from 'react-adsense';
import ReactGA from "react-ga4";


const locationPath = "/calculator/calendar/time-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "時間計算 (終了時間 - 開始時間)";

let resultValue = "";
let resultValueHours = "";
let resultValueSec = "";
let resultElement;
var date1 = new Date('2020/01/01 09:00 AM');
var date2 = new Date();

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
  },
}));

function setFormData(formValues) {

    date1 = new Date('2020/01/01 ' + formValues.startDateValue);
    date2 = new Date('2020/01/01 ' + formValues.endDateValue);
    var timeDiff = date2.getTime() - date1.getTime();
    var diffMin = Math.floor(timeDiff / (1000 * 60));

    resultValue = Number(diffMin).toLocaleString();

    var hour = Math.floor(diffMin / 60);
    var min = diffMin % 60;
    if (min < 0) {
      min = -min;
    }
    resultValueHours = hour + "時間" + min + "分";

    resultValueSec = Number(diffMin * 60).toLocaleString();
}

function TimeCalculator() {
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);

  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setFormData(data)

  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(date1);
  const [selectedDate2, setSelectedDate2] = React.useState(date2);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChange2 = (date2) => {  
    setSelectedDate2(date2);
  };
  
  if(resultValue === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElement = 
    <div>
      <TextField
        id="result"
        label="時間"
        value={resultValueHours}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
      <br /><br />

      <TextField
        id="result"
        label="分"
        value={resultValue}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
      <br /><br />
      
      <TextField
        id="result"
        label="秒"
        value={resultValueSec}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
      <br /><br />
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="時間(経過時間)の計算をすることができます。2つの時刻、開始時間と終了時間を入力して「計算する」ボタンを押します。経過時間を算出することができます。経過時間は、時、分、秒の単位で表示することができます" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="時間(経過時間)の計算をすることができます。2つの時刻、開始時間と終了時間を入力して「計算する」ボタンを押します。経過時間を算出することができます。経過時間は、時、分、秒の単位で表示することができます" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

    <div>

          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  name="startDateValue"
                  label="開始時間"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  inputRef={register({ required: true })}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  name="endDateValue"
                  label="終了時間"
                  value={selectedDate2}
                  onChange={handleDateChange2}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  inputRef={register({ required: true })}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            
            <div>
            <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
                計算する
              </Button>
            </div>

            <br />
          
          </form>

          <div id="chart">
            {resultElement}
          </div>

      </div>

      <AccordionInstruction title="使い方">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            時間(経過時間)の計算をすることができます。<br />
            2つの時刻、開始時間と終了時間を入力して「計算する」ボタンを押します。経過時間を算出することができます。<br />
            経過時間は、時、分、秒の単位で表示することができます。<br />
            <br />
            関連リンク<br />
            ⇒<a href="/calculator/calendar/date-calculator">日数計算</a><br />
            ⇒<a href="/calculator/calendar/time-difference-calculator">時差計算 (タイムゾーン)</a>
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default TimeCalculator;
