import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  BarChart, Bar, Legend, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine,
} from 'recharts';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import AdSense from 'react-adsense';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SnsShareButtons from '../../../components/sns/SnsShareButtons';
import ReactGA from "react-ga4";


const locationPath = "/en/calculator/finance/simple-fire-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "Simple FIRE Calculator";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    backgroundColor: '#ffffff',
    width: '100%',
  },
  table: {
  },
  margin: {
    marginTop: '0px',
    marginBottom: '10px',
  },
}));

let chartElement, principal, monthly_deposit;
let finalAmount;
var chartData = [];
let rate, currentAge, retirementAge, yearlyExpenseGlobal, pensionStartAge, pensionStartAgeControled;

function setChartData(formValues) {

  var newChartData = [];
  let yearly_deposit, interest, total;
  principal = Number(formValues.principal);
  monthly_deposit = Number(formValues.monthly_deposit);
  yearly_deposit = 0;
  rate = Number(formValues.rate);
  interest = 0;
  pensionStartAgeControled = Number(pensionStartAge);
  currentAge = Number(formValues.currentAge);
  retirementAge = Number(retirementAge);
  if (retirementAge < currentAge) {
    retirementAge = currentAge;
  }
  let yearlyExpense, yearlyExpenseAfterPensionStart;
  yearlyExpense = Number(formValues.yearlyExpense);
  yearlyExpenseGlobal = formValues.yearlyExpense;
  yearlyExpenseAfterPensionStart = Number(formValues.yearlyExpenseAfterPensionStart);
  //yearlyExpenseAfterPensionStartGlobal = formValues.yearlyExpenseAfterPensionStart;

  let principalAfterRetirement, yearlyDepositeAfterRetirement;
  let interestAfterRetirement = 0;

  let considerPension = formValues.considerPension;

  if (! considerPension) {
    pensionStartAgeControled = "";
  }

  for (let i = 0; i + currentAge <= 110; i++) {

    //Consider pension age
    if (considerPension && i + currentAge >= pensionStartAgeControled) {
      //Replace yearly expense amount
      yearlyExpense = yearlyExpenseAfterPensionStart;
    }
    
    if (i + currentAge <= retirementAge) {
      yearly_deposit = yearly_deposit + (Number(formValues.monthly_deposit) * 12);
      interest = ((principal + yearly_deposit + interest) * (rate / 100)) + interest; 
      total = (principal) + yearly_deposit + interest;
      
      principalAfterRetirement = principal;
      yearlyDepositeAfterRetirement = yearly_deposit;
      interestAfterRetirement = Math.round(interest);
      
      newChartData[i] = {name: currentAge + i, "Principal": principal, "AdditionalInvestment": yearly_deposit, "Return": Math.round(interest), "Total": Math.round(total)};
      
      finalAmount = principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement;

    } else {
      
      let totalAmount = principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement;

      if ((yearlyDepositeAfterRetirement + interestAfterRetirement) < yearlyExpense) {
        principalAfterRetirement = totalAmount - yearlyExpense;
        yearlyDepositeAfterRetirement = 0;

        if (principalAfterRetirement < 0) {
          interestAfterRetirement = 0;
        } else {
          interestAfterRetirement = (Math.round((principalAfterRetirement) * (rate / 100))); 
        }
      } else if (interestAfterRetirement < yearlyExpense) {
        yearlyDepositeAfterRetirement = yearlyDepositeAfterRetirement + interestAfterRetirement - yearlyExpense;
        interestAfterRetirement = 0;
        interestAfterRetirement = interestAfterRetirement + (Math.round((principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement) * (rate / 100)));
      } else {
        interestAfterRetirement = interestAfterRetirement - yearlyExpense;
        interestAfterRetirement = interestAfterRetirement + (Math.round((principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement) * (rate / 100)));
      }
      
      newChartData[i] = {name: currentAge + i, "Principal": principalAfterRetirement, "AdditionalInvestment": yearlyDepositeAfterRetirement, "Return": interestAfterRetirement, "Total": principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement};
    }
  }

  //Important!
  chartData = newChartData;

}

function valuetext(value) {
  retirementAge = value;
  return `${value}`;
}

/**
function valuetextPensionStartAge(value) {
  pensionStartAge = value;
  return `${value}`;
}
*/

function FireCalculatorEn() {

  // For the English pages
  var newLang = 'en';
  document.documentElement.lang = newLang;
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);

  /**
  const [state, setState] = React.useState({
    considerPension: false,
  });
 */

  /**
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
 */

  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setChartData(data)
  
  if(chartData.length === 0) {

    rate = 4;
    yearlyExpenseGlobal = 30000;
    retirementAge = 35;
    //yearlyExpenseAfterPensionStartGlobal = 150;
    pensionStartAge = 65;

  } else {

    chartElement = 
    <div>
      <br />
      <div style={{ width: '100%'}}>
        <TextField
          id="outlined-read-only-input"
          label="Investment Value At The Retirement"
          defaultValue={finalAmount.toLocaleString()}
          value={finalAmount.toLocaleString()}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      </div>

      <div style={{ width: '800px', height: '450px', backgroundColor: '#FFFFFF', paddingLeft: '220px' }}>
        <ResponsiveContainer>
          <BarChart
            width="100%"
            height={300}
            data={chartData}
            margin={{
              top: 40, right: 30, left: 30, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={tick => {
                return (tick / 1000).toLocaleString() + " k";
            }}/>
            <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)}/>
            <Legend />
            <Bar dataKey="Principal" stackId="a" fill="#8884d8" />
            <Bar dataKey="AdditionalInvestment" stackId="a" fill="#82ca9d" />
            <Bar dataKey="Return" stackId="a" fill="#ff7f50" />
            <ReferenceLine x={retirementAge} stroke="red" label={{value:"Retirement Age", position:"top", fill:"red"}} />
            <ReferenceLine y={0} label="" stroke="red" />
            <ReferenceLine x={pensionStartAgeControled} stroke="blue" label={{value:"年金受給開始年齢", position:"top", fill:"blue"}} />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div style={{ maxWidth:'720px', paddingLeft: '220px' }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Age</TableCell>
                <TableCell align="right">Principal</TableCell>
                <TableCell align="right">Additional Investment</TableCell>
                <TableCell align="right">Return</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chartData.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row" align="right">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{Number(row.Principal).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.AdditionalInvestment).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.Return).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.Total).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  }

  return (
    <div className="App-header">
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Simple FIRE Calculator allows you to find out when you will be able to retire early.The results will be displayed in chart and tables.This tool gives you to get a basic idea of FIRE by visualizing it in a chart.Calculating the future is not simple, but I have prioritized the simplicity of the tool to make it as simple to use as possible.Enter your return, retirement age, and Annual expenses while considering your FIRE strategy." />
        <link rel="alternate" href="https://www.life-util.com/calculator/finance/fire-simulator" hreflang="ja"/>
        <link rel="alternate" href="https://www.life-util.com/en/calculator/finance/simple-fire-calculator" hreflang="en" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="Simple FIRE Calculator allows you to find out when you will be able to retire early.The results will be displayed in chart and tables.This tool gives you to get a basic idea of FIRE by visualizing it in a chart.Calculating the future is not simple, but I have prioritized the simplicity of the tool to make it as simple to use as possible.Enter your return, retirement age, and Annual expenses while considering your FIRE strategy." />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div>
        <SnsShareButtons fullPath={fullPath} title={title} hashtag={["FIRE_Calculator", "EarlyRetire"]} />
      </div>

      <form name="form1" className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
       
        <div>
          <TextField
            id="years"
            label="Current Age"
            type="number"
            name="currentAge"
            defaultValue={currentAge}
            inputRef={register({ required: true, maxLength: 3 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>
        <div>
          <TextField
            id="Current Investment Value"
            label="Current Investment Value"
            type="number"
            name="principal"
            defaultValue={principal}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            inputRef={register({ required: true, maxLength: 20 })}
          />
        </div>
        <div>
          <TextField
            id="monthly-deposit"
            label="Additional Investment (Monthly)"
            type="number"
            name="monthly_deposit"
            defaultValue={monthly_deposit}
            inputRef={register({ required: true, maxLength: 20 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>
        <div>
          <TextField
            id="rate"
            label="Interest Rate(%)"
            type="number"
            name="rate"
            defaultValue={rate}
            inputRef={register({ required: true, maxLength: 20 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>

        <div style={{width: '200px', marginLeft: 'auto', marginRight: 'auto'}}>
          <Typography id="discrete-slider-small-steps" gutterBottom className="MuiFormLabel-root MuiInputLabel-shrink ">
            Retirement Age
          </Typography>
          <Slider
            name="retirementAge"
            defaultValue={retirementAge}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider-small-steps"
            step={1}
            marks
            min={30}
            max={80}
            valueLabelDisplay="on"
          />
        </div>

        <div>
          <TextField
            id="yearlyExpense"
            label="Annual Expenses After Retirement"
            type="number"
            name="yearlyExpense"
            defaultValue={yearlyExpenseGlobal}
            inputRef={register({ required: false, maxLength: 20 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>

        <div>
          <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
            Calculate
          </Button>
          {/** 
          <AdSense.Google
            client='ca-pub-6460385026549088'
            slot='4235679126'
          />
          */}
        </div>
      </form>

      <div id="chart">
        {chartElement}
      </div>

      <div>
        <AccordionInstruction title="Information and instructions">
          <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
            <Typography variant="body2" gutterBottom>
              Simple FIRE Calculator allows you to find out when you will be able to retire early.<br />
              The results will be displayed in chart and tables.<br />
              <br />
              This tool gives you to get a basic idea of FIRE by visualizing it in a chart.<br />
              Calculating the future is not simple, but I have prioritized the simplicity of the tool to make it as simple to use as possible.<br />
              Enter your return, retirement age, and Annual expenses while considering your FIRE strategy.<br />
              <br />
            </Typography>
              
          </Box>
        </ AccordionInstruction>

        <AdSense.Google
          client='ca-pub-6460385026549088'
          slot='4219694846'
          style={{ display: 'block' }}
          layout='in-article'
          format='fluid'
        />
        
      </div>

    </div>
  );
}

export default FireCalculatorEn;
