import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import QRCode from "qrcode.react"
import AdSense from 'react-adsense';
import ReactGA from "react-ga4";


const locationPath = "/tools/qr-code-generator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "QRコード(キューアールコード)作成";

let urlValue = "";
let resultElement;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    marginTop: '50px',
  },
  resultElementStyle: {
      marginBottom: '15px',
  },
}));

function setFormData(formValues) {
    urlValue = formValues.url;
}

function downloadQr() {
  const canvas = document.getElementById("qr_code");
  const pngUrl = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
  let downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  downloadLink.download = "qr_code.png";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

function QrCodeGenerator() {
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setFormData(data)
  
  if(urlValue === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElement = 
    <div className={classes.resultElementStyle}>
      <QRCode value={urlValue}
        id='qr_code'
        renderAs='canvas'
      />
      <br />
      <a onClick={downloadQr} href=" ">QRコードをダウンロード</a>
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="このページではQRコード(キューアールコード)を作成することができます。作り方を説明します。URLや文字列を入力して「QRコードを生成する」ボタンを押すとQRコードが表示されます。生成されたQRコードは画像として保存できます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="このページではQRコード(キューアールコード)を作成することができます。作り方を説明します。URLや文字列を入力して「QRコードを生成する」ボタンを押すとQRコードが表示されます。生成されたQRコードは画像として保存できます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div>

        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TextField
              id="url"
              label="データを入力"
              name="url"
              defaultValue={urlValue}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              inputRef={register({ required: true, maxLength: 4000 })}
              variant="outlined"
            />
          </div>
        
          <br /> 

          <div>
          <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
              QRコードを作成する
            </Button>
          </div>

          <br />
        
        </form>

        <div id="chart">
          {resultElement}
        </div>
      </div>

      <AccordionInstruction title="説明">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            このページではQRコード(キューアールコード)を作成することができます。<br />
            URLや文字列を入力して「QRコードを作成する」ボタンを押すとQRコードが表示されます。<br />
            「QRコードをダウンロード」から画像としてダウンロードできます。<br />
            <br />
            無料でご自由にお使い頂けます。<br />
            ※作成後は期待通り読み取れることを自分で確認してから使いましょう。<br />
            <br />
            関連リンク<br />
            ⇒<a href="/tools/password-generator">パスワード生成</a><br />
            ⇒<a href="/tools/barcode-generator">バーコード作成</a>
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default QrCodeGenerator;
