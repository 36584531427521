import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../../components/AccordionInstruction';
import AdSense from 'react-adsense';
import { BigNumber } from 'bignumber.js';
import ReactGA from "react-ga4";


const locationPath = "/calculator/converter/mile-to-kilometer-converter";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "マイル⇔キロメートル換算ツール";

let resultValueKilometer = "";
let resultValueMile = "";
let inputValueKilometer = "";
let inputValueMile = "";
let resultElementKilometer = "";
let resultElementMile = "";
const kilometerToMile = 0.6214;
const mileToKilometer = 1.609;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function setFormDataCentimeter(formValues) {
  inputValueKilometer = BigNumber(formValues.centimeterValue);
  resultValueKilometer = inputValueKilometer.multipliedBy(kilometerToMile);
}

function setFormDataInch(formValues) {
  inputValueMile = BigNumber(formValues.inchValue);
  resultValueMile = inputValueMile.multipliedBy(mileToKilometer);
}

function MileToKilometerConverter() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { register, handleSubmit } = useForm();
  const onSubmitCentimeter = (data) => setFormDataCentimeter(data)
  const onSubmitInch = (data) => setFormDataInch(data)
  
  if(resultValueKilometer === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElementKilometer = 
    <div>
      <TextField
        id="result"
        label="マイル(mi)"
        defaultValue={resultValueKilometer}
        value={Number(resultValueKilometer).toLocaleString()}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
    </div>;
  }

  if(resultValueMile === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElementMile = 
    <div>
      <TextField
        id="result"
        label="キロメートル(km)"
        defaultValue={resultValueMile}
        value={Number(resultValueMile).toLocaleString()}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
    </div>;
  }

  return (
    <div>
            
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="マイルとキロメートルの換算ツールです。キロメートルからマイルへ換算、マイルからキロメートルへ換算することができます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="マイルとキロメートルの換算ツールです。キロメートルからマイルへ換算、マイルからキロメートルへ換算することができます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="キロメートルからマイル" {...a11yProps(0)} />
          <Tab label="マイルからキロメートル" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>

          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitCentimeter)}>
            <div>
              <TextField
                id="gramValue"
                label="キロメートル(km)を入力"
                type="number"
                name="centimeterValue"
                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                defaultValue={inputValueKilometer}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                inputRef={register({ required: true, maxLength: 20 })}
                variant="outlined"
              />
            </div>
          
            <br /> 

            <div>
            <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
                換算する
              </Button>
            </div>

            <br />
          
          </form>

          <div id="chart">
            {resultElementKilometer}
          </div>



      </TabPanel>
      <TabPanel value={value} index={1}>
        
        
            <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitInch)}>
              <div>
                <TextField
                  id="gramValue"
                  label="マイル(mi)を入力"
                  type="number"
                  name="inchValue"
                  endAdornment={<InputAdornment position="end">oz</InputAdornment>}
                  defaultValue={inputValueMile}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  inputRef={register({ required: true, maxLength: 20 })}
                  variant="outlined"
                />
              </div>
            
              <br /> 

              <div>
              <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
                  換算する
                </Button>
              </div>

              <br />
            
            </form>

            <div id="chart">
              {resultElementMile}
            </div>
      </TabPanel>
      </div>

      <AccordionInstruction title="説明">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            キロメートルとマイルの換算ツールです。<br />
            キロメートルからマイルへ換算、マイルからキロメートルへ換算することができます。<br />
            <br />
            マイルは「ヤード・ポンド法」で長さの単位を表しており記号は「mi」です。<br />
            「国際インチ」のことを指しており、1マイルは{mileToKilometer}キロメートルで計算しています。<br />
            <br />
            キロメートルは「メートル法」長さの単位を表しており1,000メートルに相当します。<br />
            日本では単に「キロ」と言いますが「キロメートル」を表しており、記号は「km」です。<br />
            1キロメートルは{kilometerToMile}マイルで計算しています。<br />
            <br />
            関連リンク<br />
            ⇒<a href="/calculator/converter/inch-to-centimeter-converter">インチ⇔センチ換算</a><br />
            ⇒<a href="/calculator/converter/meter-to-yard-converter">メートル⇔ヤード換算</a>
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default MileToKilometerConverter;
