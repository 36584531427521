import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import jaLocale from "date-fns/locale/ja";
import DatePickerFormatter from '../../../../view/components/formatter/DatePickerFormatter';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import ReactGA from "react-ga4";


const locationPath = "/calculator/calendar/date-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "日数計算";

let resultValue = "";
let resultElement;
var date1 = new Date();
var date2 = new Date();
var days = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
  },
}));

function setFormData(formValues) {
    //console.log(formValues.startDateValue);
    //console.log(formValues.endDateValue);

    date1 = new Date(formValues.startDateValue);
    date2 = new Date(formValues.endDateValue);
    days = Number((date2 - date1) / 86400000).toLocaleString();

    resultValue = days;
}

function DateCalculator() {
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setFormData(data)

  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(date1);
  const [selectedDate2, setSelectedDate2] = React.useState(date2);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChange2 = (date2) => {  
    setSelectedDate2(date2);
  };
  
  if(resultValue === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElement = 
    <div>
      <TextField
        id="result"
        label="日数"
        defaultValue={resultValue}
        value={resultValue}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
      <br /><br />
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="2つの日付から日数を計算することができます。日付1と日付2を入力して「計算する」ボタンを押すと日数が表示されます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="2つの日付から日数を計算することができます。日付1と日付2を入力して「計算する」ボタンを押すと日数が表示されます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
        
      </Helmet>

    <div>

          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

            <MuiPickersUtilsProvider utils={DatePickerFormatter} locale={jaLocale}>
              <Grid container justify="space-around">
              
                <KeyboardDatePicker
                  margin="normal"
                  id="startDate"
                  name="startDateValue"
                  label="日付1"
                  format="yyyy/MM/dd"
                  defaultValue={date1}
                  value={selectedDate}   
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  okLabel="決定"
                  cancelLabel="キャンセル"
                  inputRef={register({ required: true, maxLength: 10 })}
                />
                
                <KeyboardDatePicker
                  margin="normal"
                  id="endDate"
                  name="endDateValue"
                  label="日付2"
                  format="yyyy/MM/dd"
                  defaultValue={date2}
                  value={selectedDate2}                  
                  onChange={handleDateChange2}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  okLabel="決定"
                  cancelLabel="キャンセル"
                  inputRef={register({ required: true, maxLength: 10 })}
                />
                
              </Grid>
            </MuiPickersUtilsProvider>

            <div>
            <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
                計算する
              </Button>
            </div>

            <br />
          
          </form>

          <div id="chart">
            {resultElement}
          </div>

      </div>

      <AccordionInstruction title="使い方">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            2つの日付から日数を計算することができます。<br />
            日付1と日付2を入力して「計算する」ボタンを押すと日数が表示されます。<br />
            <br />
            関連リンク<br />
            ⇒<a href="/calculator/calendar/time-calculator">時間計算 (終了時間 - 開始時間)</a><br />
            ⇒<a href="/calculator/calendar/time-difference-calculator">時差計算 (タイムゾーン)</a>
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default DateCalculator;
