import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import AdSense from 'react-adsense';
import ReactGA from "react-ga4";


const locationPath = "/calculator/converter/square-metre-tsubo-tatami-converter";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "平米計算ツール";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    backgroundColor: '#ffffff',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    display: 'inline-block',
    marginTop: '10px',
    marginBottom: '10px',
  },
  adsMargin: {
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

const squareMetre = "㎡";
const tsubo = "坪";
const tatami = "畳";

const edoma = "江戸間";
const kyouma = "京間";
const chukyoma = "中京間";
const danchima = "団地間";

const tsuboInSquareMetre = 3.30578;
const squareMetreInTsubo = 0.3025;

const edomaInsquareMetre = 1.548;
const kyoumaInSquareMetre = 1.824;
const chukyomaInSquareMetre = 1.656;
const danchimaInSquareMetre = 1.445;

let resultElement, scaleValue;
var resultData = [];
let tatamiType;
let scaleType = squareMetre;

function createData(squareMetreValue, tsuboValue, tatamiValue) {
  return { squareMetreValue, tsuboValue, tatamiValue };
}

function getTatamiValueFromSquareMetre(squareMetreValue, tatamiType) {
  
  let ret;

  switch (tatamiType) {
    case edoma:
      ret = squareMetreValue / edomaInsquareMetre;
      break;
    case kyouma:
      ret = squareMetreValue / kyoumaInSquareMetre;
      break;
    case chukyoma:
      ret = squareMetreValue / chukyomaInSquareMetre;
      break;
    case danchima:
      ret = squareMetreValue / danchimaInSquareMetre;
      break;
    default:
  }

  return ret;

}

function getSquareMetreValueFromTatami(tatamiValue, tatamiType) {
  
  let ret;

  switch (tatamiType) {
    case edoma:
      ret = tatamiValue * edomaInsquareMetre;
      break;
    case kyouma:
      ret = tatamiValue * kyoumaInSquareMetre;
      break;
    case chukyoma:
      ret = tatamiValue * chukyomaInSquareMetre;
      break;
    case danchima:
      ret = tatamiValue * danchimaInSquareMetre;
      break;
    default:
  }

  return ret;

}

function setResultData(formValues) {

  //console.log("setCharData!");
  
  var newResultData = [];
  scaleType = formValues.scaleType;
  scaleValue = Number(formValues.scaleValue);
  tatamiType = formValues.tatamiType;

  /*
  console.log("priceType:" + priceType);
  console.log("price:" + price);
  console.log("roundingType:" + roundingType);
  console.log("rate:" + rate);
  */

  let squareMetreValue, tsuboValue, tatamiValue;

  if (scaleType === squareMetre) {
    squareMetreValue = scaleValue;
    tsuboValue = squareMetreValue * squareMetreInTsubo;
    tatamiValue = getTatamiValueFromSquareMetre(scaleValue, tatamiType);
  } else if (scaleType === tsubo) {
    tsuboValue = scaleValue;
    squareMetreValue = tsuboValue * tsuboInSquareMetre;
    tatamiValue = getTatamiValueFromSquareMetre(squareMetreValue, tatamiType);
  } else {
    tatamiValue = scaleValue;
    squareMetreValue = getSquareMetreValueFromTatami(tatamiValue, tatamiType);
    tsuboValue = squareMetreValue * squareMetreInTsubo;
  }

  newResultData = [
    createData(squareMetreValue, tsuboValue, tatamiValue),
  ];

  //Important!
  resultData = newResultData;
}

function SquareMetreTsuboTatami() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setResultData(data)
  const [value, setValue] = React.useState(scaleType);
  const [state, setState] = React.useState({
    scaleType: '',
    name: '',
  });

  const handleChangeForScaleType = (event) => {
    //console.log(event.target.value);
    scaleType = event.target.value;
    setValue(event.target.value);
  };

  const handleChangeForTatamiType = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  
  if(resultData.length === 0) {


  } else {

    resultElement = 
    <div className={classes.table}>
      <TableContainer component={Paper} elevation={3}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">㎡</TableCell>
              <TableCell align="right">坪</TableCell>
              <TableCell align="right">畳({tatamiType})</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resultData.map((row) => (
              <TableRow>
                <TableCell component="th" scope="row" align="right">
                  
                </TableCell>
                <TableCell align="right">{Number(row.squareMetreValue).toLocaleString()}</TableCell>
                <TableCell align="right">{Number(row.tsuboValue).toLocaleString()}</TableCell>
                <TableCell align="right">{Number(row.tatamiValue).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="平米計算ツールです。坪/平米/畳のいずれかを入力して換算することができます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="平米計算ツールです。坪/平米/畳のいずれかを入力して換算することができます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

        <div>
          <FormControl component="fieldset">
            <RadioGroup aria-label="scaleType" name="scaleType" value={value} onChange={handleChangeForScaleType}>
              <FormControlLabel value={squareMetre} control={<Radio />} label="㎡を入力する" inputRef={register({ required: true })}/>
              <FormControlLabel value={tsubo} control={<Radio />} label="坪数を入力する" inputRef={register({ required: true })}/>
              <FormControlLabel value={tatami} control={<Radio />} label="畳数を入力する" inputRef={register({ required: true })}/>
            </RadioGroup>
         </FormControl>

        </div>
       <div>
        <TextField
          id="scaleValue"
          label={scaleType}
          type="number"
          name="scaleValue"
          defaultValue={scaleValue}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 20 })}
        />
      </div>

      <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple" >畳サイズ</InputLabel>
          <Select
            native
            defaultValue={tatamiType}
            onChange={handleChangeForTatamiType}
            inputProps={{
              name: 'tatamiType',
              id: 'age-native-simple',
            }}
            inputRef={register({ required: true })}
          >
            <option value={edoma}>{edoma}</option>
            <option value={kyouma}>{kyouma}</option>
            <option value={chukyoma}>{chukyoma}</option>
            <option value={danchima}>{danchima}</option>
          </Select>
        </FormControl>
      </div>

      <div>
       <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
          換算する
        </Button>
        <AdSense.Google
          client='ca-pub-6460385026549088'
          slot='3710473082'
          style={{ display: 'block' }}
          layout='in-article'
          format='fluid'
        />
      </div>
      
    </form>

    <div id="result">
      {resultElement}
    </div>

    <AccordionInstruction title="使い方">
      <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
        <Typography variant="body2" gutterBottom>
        平米計算ツールです。坪/平米/畳のいずれかを入力して計算します。<br />
        <br />
        1畳(帖)のサイズについて<br />
        <br />
        江戸間(1.548㎡) 880×1760mm<br />
        東京を中心とした関東地方をはじめ、静岡以北のエリアで使用されています。<br />
        <br />
        京間(1.824㎡) 955×1910mm<br />
        西日本で多く使われ、関西・中国・四国・九州で使用されています。<br />
        <br />
        中京間(1.656㎡) 910×1820mm<br />
        愛知・岐阜・三重で多く見られますが、近畿や四国、東北でも使用されています。<br />
        <br />
        団地間(1.445㎡) 850×1700mm<br />
        地域に関係なく、公団住宅・アパート・マンションなどの共同住宅で使用されています。
        <br />
        <br />
        換算値は、1坪={tsuboInSquareMetre}㎡、1㎡={squareMetreInTsubo}坪、1畳(帖)={edomaInsquareMetre}㎡です。<br />
        </Typography>
      </Box>
    </ AccordionInstruction>

    <AdSense.Google
      client='ca-pub-6460385026549088'
      slot='3710473082'
      style={{ display: 'block' }}
      layout='in-article'
      format='fluid'
    />
    </div>
  );
}

export default SquareMetreTsuboTatami;
