import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import ReactGA from "react-ga4";


const locationPath = "/privacy-policy";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "プライバシーポリシー・免責事項";

function DisclaimerPage() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);

  return (

    <div>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content="プライバシーポリシー・免責事項" />
      <meta property="og:url" content={fullPath} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content="プライバシーポリシー・免責事項" />
      <meta property="og:site_name" content="Life-Util" />
    </Helmet>

    <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">

      <Typography variant="h6" component="h2" gutterBottom={true}>基本方針</Typography>
      <Typography variant="body1" component="body2">
        当サイトは、個人情報の重要性を認識し、個人情報を保護することが社会的責務であると考え、個人情報に関する法令を遵守し、当サイトで取扱う個人情報の取得、利用、管理を適正に行います。
      </Typography>
      <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>適用範囲</Typography>
      <Typography variant="body1" component="body2">
        本プライバシーポリシーは、お客様の個人情報もしくはそれに準ずる情報を取り扱う際に、当サイトが遵守する方針を示したものです。
      </Typography>
      <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>個人情報の利用目的</Typography>
      <Typography variant="body1" component="body2">
        当サイトでは、メールでのお問い合わせ、メールマガジンへの登録などの際に、に、名前（ハンドルネーム）、メールアドレス等の個人情報をご登録いただく場合がございます。<br />
        これらの個人情報は質問に対する回答や必要な情報を電子メールなどをでご連絡する場合に利用させていただくものであり、個人情報をご提供いただく際の目的以外では利用いたしません。
      </Typography>
      <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>個人情報の管理</Typography>
      <Typography variant="body1" component="body2">
        当サイトは、個人情報の正確性及び安全確保のために、セキュリティ対策を徹底し、個人情報の漏洩、改ざん、不正アクセスなどの危険については、必要かつ適切なレベルの安全対策を実施します。<br />
        当サイトは、第三者に重要な情報を読み取られたり、改ざんされたりすることを防ぐために、SSLによる暗号化を使用しております。
        </Typography>
        <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>個人情報の第三者提供</Typography>
      <Typography variant="body1" component="body2">
        当サイトは、以下を含む正当な理由がある場合を除き、個人情報を第三者に提供することはありません。<br />
        <p />
        ・ご本人の同意がある場合<br />
        ・法令に基づく場合<br />
        ・人の生命・身体・財産の保護に必要な場合<br />
        ・公衆衛生・児童の健全育成に必要な場合<br />
        ・国の機関等の法令の定める事務への協力の場合（税務調査、統計調査等）<br />
        ・当サイトでは、利用目的の達成に必要な範囲内において、他の事業者へ個人情報を委託することがあります。
      </Typography>
      <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>個人情報に関するお問い合わせ</Typography>
      <Typography variant="body1" component="body2">
        開示、訂正、利用停止等のお申し出があった場合には、所定の方法に基づき対応致します。具体的な方法については、個別にご案内しますので、お問い合わせください。
      </Typography>
      <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>Cookie（クッキー）</Typography>
      <Typography variant="body1" component="body2">
        Cookie（クッキー）は、利用者のサイト閲覧履歴を、利用者のコンピュータに保存しておく仕組みです。<br />
        利用者はCookie（クッキー）を無効にすることで収集を拒否することができますので、お使いのブラウザの設定をご確認ください。ただし、Cookie（クッキー）を拒否した場合、当サイトのいくつかのサービス・機能が正しく動作しない場合があります。
      </Typography>
      <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>アクセス解析</Typography>
      <Typography variant="body1" component="body2">
        当サイトでは、サイトの分析と改善のためにGoogleが提供している「Google アナリティクス」を利用しています。<br />
        このサービスは、トラフィックデータの収集のためにCookie（クッキー）を使用しています。トラフィックデータは匿名で収集されており、個人を特定するものではありません。
      </Typography>
      <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>広告配信</Typography>
      <Typography variant="body1" component="body2">
        当サイトは、第三者配信の広告サービス「Google アドセンス」「楽天アフィリエイト」「Amazonアソシエイト」「A8.net」「バリューコマース」を利用しています。<br />
        広告配信事業者は、利用者の興味に応じた広告を表示するためにCookie（クッキー）を使用することがあります。これによって利用者のブラウザを識別できるようになりますが、個人を特定するものではありません。<br />
        Cookie（クッキー）を無効にする方法や「Google アドセンス」に関する詳細は<Link target="_blank" href="https://policies.google.com/technologies/ads?gl=jp" rel="noopener noreferrer">こちら</Link>をご覧ください。
      </Typography>
      <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>コメント・お問い合わせフォーム</Typography>
      <Typography variant="body1" component="body2">
        当サイトでは、コメント・お問い合わせフォームに表示されているデータ、そしてスパム検出に役立てるための IP アドレスやブラウザのユーザーエージェント文字列等を収集します。<br />
        メールアドレスから作成される匿名化されたハッシュ文字列は、あなたが「Gravatar」サービスを使用中かどうか確認するため同サービスに提供されることがあります。<br />
        同サービスのプライバシーポリシーは、<Link target="_blank" href="https://automattic.com/privacy/" rel="noopener noreferrer">こちら</Link>をご覧ください。
        </Typography>
      <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>他サイトからの埋め込みコンテンツ</Typography>
      <Typography variant="body1" component="body2">  
        当サイトには、埋め込みコンテンツ （動画、画像、投稿など）が含まれます。他サイトからの埋め込みコンテンツは、訪問者がそのサイトを訪れた場合とまったく同じように振る舞います。<br />
        これらのサイトは、あなたのデータの収集、Cookie（クッキー）の使用、サードパーティによる追加トラッキングの埋め込み、埋め込みコンテンツとのやりとりの監視を行うことがあります。<br />
        アカウントを使ってそのサイトにログイン中の場合、埋め込みコンテンツとのやりとりのトラッキングも含まれます。
      </Typography>
      <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>免責事項</Typography>
      <Typography variant="body1" component="body2">
        当サイトのコンテ当サイトで掲載している画像の著作権・肖像権等は各権利所有者に帰属致します。権利を侵害する目的ではございません。記事の内容や掲載画像等に問題がございましたら、各権利所有者様本人が直接メールでご連絡下さい。確認後、対応させて頂きます。<br />
        当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。<br />
        当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、正確性を保証するものではございません。誤情報が入り込んだり、情報が古くなっていることもございます。<br />
        当サイトの利用によって生じた一切の損害、損失、不利益等に対し、一切の責任を負いませんのでご了承下さい。<br />
      </Typography>
      <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>著作権・肖像権</Typography>
      <Typography variant="body1" component="body2">
        当サイトで掲載しているすべてのコンテンツ（文章、画像、動画、音声、ファイル等）の著作権・肖像権等は当サイト所有者または各権利所有者が保有し、許可なく無断利用（転載、複製、譲渡、二次利用等）することを禁止します。また、コンテンツの内容を変形・変更・加筆修正することも一切認めておりません。<br />
        各権利所有者におかれましては、万一掲載内容に問題がございましたら、ご本人様よりお問い合わせください。迅速に対応いたします。
      </Typography>
      <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>リンク</Typography>
      <Typography variant="body1" component="body2">
        当サイトは原則リンクフリーです。リンクを行う場合の許可や連絡は不要です。引用する際は、引用元の明記と該当ページへのリンクをお願いします。<br />
        ただし、画像ファイルへの直リンク、インラインフレームを使用したHTMLページ内で表示する形でのリンクはご遠慮ください。
      </Typography>
      <p />
      <Typography variant="h6" component="h2" gutterBottom={true}>本プライバシーポリシーの変更</Typography>
      <Typography variant="body1" component="body2">
        当サイトは、本プライバシーポリシーの内容を適宜見直し、その改善に努めます。<br />
        本プライバシーポリシーは、事前の予告なく変更することがあります。<br />
        本プライバシーポリシーの変更は、当サイトに掲載された時点で有効になるものとします。<br />     
      </Typography>
    </ Box>
  </div>

  );

}

export default DisclaimerPage;
