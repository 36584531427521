import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
//import PieChartIcon from '@material-ui/icons/PieChart';
import SimplePieChart from '../../../components/chart/SimplePieChart';
import SnsShareButtons from '../../../components/sns/SnsShareButtons';
import ReactGA from "react-ga4";


const locationPath = "/calculator/finance/savings-rate-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "貯蓄率計算ツール";

let resultValue = "";
let incomeValue = "";
let savingValue = "";
let resultElement;
let chartData1;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '25px',
    marginBottom: '10px',
  },
  margin: {
    marginBottom: '0px',
  },
  paper: {
  },
  margin_ads: {
    marginTop: '5px',
    marginBottom: '10px',
  },
}));

function setFormData(formValues) {
    incomeValue = formValues.income;
    savingValue = formValues.saving;

    resultValue = Math.round(savingValue / incomeValue * 10000) / 100;

    // Create pie chart
    if (resultValue >= 0 && resultValue <= 100) {
      chartData1 = <SimplePieChart totalNumber={incomeValue} percentageMain={resultValue} valueMain={savingValue} percentageRemaining={100 - resultValue} label1={"貯蓄率" + resultValue + "%"} label2={100 - resultValue + "%"} />;
    } else {
      chartData1 = "円グラフを表示できません。";
    }
}

function SavingsRateCalculator() {
  
  useEffect(() => {
    
    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setFormData(data)
  //const labelPieChart = <div className={classes.chartLabel}><Typography variant="subtitle1" gutterBottom><PieChartIcon />円グラフ</Typography></div>;

  //document.title = '貯蓄率計算';
  
  if(resultValue === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElement = 
    <div className={classes.margin}>
      <Paper elevation={0} className={classes.paper}>
        <div className={classes.margin}>
          <TextField
            id="result"
            label="貯蓄率(%)"
            defaultValue={resultValue}
            value={resultValue}
            endAdornment={<InputAdornment position="end">円</InputAdornment>}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
        </div>
      </Paper>
      <Paper elevation={0} className={classes.paper}>
        {/** {labelPieChart} */}
        {chartData1}
      </Paper>
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="貯蓄率計算ツールです。貯蓄率を計算することができます。手取り収入と貯蓄額を入力して「計算する」ボタンを押すと貯蓄率(%)が表示されます。貯蓄率とは貯蓄額を可処分所得で割った比率です。ロングセラー書籍「バビロンの大富豪」の中では、金銭的成功を手に入れるために倹約を行い10%を貯蓄に回すことが教えとして書かれています。資産形成を行っていく上で大切な指標となります。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="貯蓄率計算ツールです。貯蓄率を計算することができます。手取り収入と貯蓄額を入力して「計算する」ボタンを押すと貯蓄率(%)が表示されます。貯蓄率とは貯蓄額を可処分所得で割った比率です。ロングセラー書籍「バビロンの大富豪」の中では、金銭的成功を手に入れるために倹約を行い10%を貯蓄に回すことが教えとして書かれています。資産形成を行っていく上で大切な指標となります。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>


      <div className={classes.snsButtonMargin}>
        <SnsShareButtons fullPath={fullPath} title={title} hashtag={["貯蓄率"]} />
      </div>
      
      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='2580419936'
        style={{ display: 'block' }}
        format='auto'
        responsive='true'
        layoutKey='-gw-1+2a-9x+5c'
      />

      <div className={classes.root}>

          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

            
            <div>
              <TextField
                id="income"
                label="手取り収入を入力"
                type="number"
                name="income"
                endAdornment={<InputAdornment position="end">円</InputAdornment>}
                defaultValue={incomeValue}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                inputRef={register({ required: true, maxLength: 20 })}
                variant="outlined"
              />
            </div>
            <br />
            <div>
              <TextField
                id="saving"
                label="貯蓄額を入力"
                type="number"
                name="saving"
                endAdornment={<InputAdornment position="end">円</InputAdornment>}
                defaultValue={savingValue}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                inputRef={register({ required: true, maxLength: 20 })}
                variant="outlined"
              />
            </div>
          
            <br /> 

            <div>
            <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
              計算する
            </Button>
            <AdSense.Google
              client='ca-pub-6460385026549088'
              slot='3444057112'
              style={{ display: 'block' }}
              layout='in-article'
              format='fluid'
            />
            </div>
          
          </form>

          <div id="chart">
            {resultElement}
          </div>

      </div>

      <AccordionInstruction title="説明と使い方">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            貯蓄率計算ツールです。<br />
            貯蓄率を計算することができます。<br />
            手取り収入と貯蓄額を入力して「計算する」ボタンを押すと貯蓄率(%)が表示されます。<br />
            <br />
            貯蓄率とは貯蓄額を可処分所得で割った比率です。<br />
            ロングセラー書籍「<Link target="_blank" href="https://www.amazon.co.jp/gp/product/B07P45X1WR/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B07P45X1WR&linkCode=as2&tag=tomniamazon-22&linkId=6bb70c7eb29d3f5b54fc7b0aeac7cd8e" rel="noopener noreferrer">バビロンの大富豪 「繁栄と富と幸福」はいかにして築かれるのか</Link>」の中では、金銭的成功を手に入れるために倹約を行い10%を貯蓄に回すことが教えとして書かれています。<br />
            <Link target="_blank" href="https://amzn.to/3j3Nlei" rel="noopener noreferrer">漫画 バビロン大富豪の教え 「お金」と「幸せ」を生み出す五つの黄金法則</Link>も読みやすくてお勧めです。<br />
            貯蓄率は資産形成、早期リタイア、FIREを考えていく上でも重要な指標となります。<br />
            <br />
            関連リンク<br />
            ⇒<Link href="/calculator/finance/compound-interest-calculator">複利計算</Link><br />
            ⇒<Link href="/calculator/finance/fire-simulator">FIREシミュレーター</Link><br />
            ⇒<Link href="/calculator/finance/barista-fire-calculator">サイドFIREシミュレーター</Link><br />
            ⇒<Link href="/calculator/finance/expected-asset-amount-calculator">期待資産額計算</Link><br />
            ⇒<Link href="/calculator/healthcare/expected-life-at-age-calculator">平均余命計算</Link><br />
            ⇒<Link href="/calculator/finance/human-life-value-calculator">生命価値計算</Link>
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
      
    </div>
  );

}

export default SavingsRateCalculator;
