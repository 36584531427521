import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import "react-color-palette/lib/css/styles.css";
import TextField from '@material-ui/core/TextField';
import SnsShareButtons from '../../components/sns/SnsShareButtons';
import xmlFormat from 'xml-formatter';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReactGA from "react-ga4";


const locationPath = "/tools/xml-formatter";
const title = "XMLフォーマッター";
const fullPath = "https://www.life-util.com" + locationPath;
const description = {title} + "です。XMLデータを人が読みやすい形に整形(pretty-print)します。使い方は簡単です。XMLテキスト入力欄にXMLテキストをコピー&ペーストすると、XML出力欄に整形済みXMLが出力されます。";

let strToCount;
let formattedXmlStr;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    marginTop: '50px',
  },
  textareainput: {
    minWidth: '80%',
    marginBottom: '10px'
  },
  textareaformatted: {
    minWidth: '80%',
    marginBottom: '10px'
  },
  paperstyle: {
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(4),
    },    
  },
  paperchildstyle: {
    background: '#0c8bed',
    color: '#FFFFFF',
  },
  snsButtonMargin: {
    marginBottom: '15px',
  },
}));

function XmlFormatter() {
  
  const [value, setValue] = React.useState(0);
  let dummy = value;
  if (dummy == null);

  const calculate = (str) => {

    //console.log("countCRLF:" + document.getElementById("countCRLF").checked);

    let inputStr = document.getElementById("textareainput").value;
    
    try {
      formattedXmlStr = 
      xmlFormat(inputStr, {
        throwOnFailure: true
      });
    } catch(err) {
      formattedXmlStr = err.name + ' - ' + err.message;
    }
  }

  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(formattedXmlStr);
  };

  const handleChange = (event) => {
    //console.log("handleChange");
    strToCount = event.target.value;
    setValue(event.target.value);
    calculate(strToCount);
  };
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div className={classes.snsButtonMargin}>
        <SnsShareButtons fullPath={fullPath} title={title} hashtag={["XMLフォーマッター", "XMLフォーマット"]} />
      </div>

      <form noValidate autoComplete="off">

        <div>
          <TextField 
           className={classes.textareainput}
            id="textareainput"
            label="XMLテキスト入力欄"
            placeholder="ここに入力してください。"
            multiline
            variant="outlined"
            onChange={handleChange}
            helperText="XMLデータに問題がある場合は、XML出力欄にエラー概要が表示されます。整形したいXMLをご確認ください。"
            rows={10}
          />
          <AdSense.Google
            client='ca-pub-6460385026549088'
            slot='3195698532'
            style={{ display: 'block' }}
            layout='in-article'
            format='fluid'
          />
        </div>

        <div>
          <Typography variant="h6" component="h4">
            XML出力欄
          </Typography>
          
          <div>
          <FileCopyIcon color="primary" onClick={() => copyToClipboard()} /> (左のアイコンをクリックでクリップボードにコピーできます)
          </div>

          <TextField 
            className={classes.textareaformatted}
            disabled
            id="textareaformatted"
            defaultValue={formattedXmlStr}
            multiline
            variant="outlined"
            rows={10}
          />
          <AdSense.Google
            client='ca-pub-6460385026549088'
            slot='9569535194'
            style={{ display: 'block' }}
            layout='in-article'
            format='fluid'
          />
        </div>
        
      </form>

      <AccordionInstruction title="使い方">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            {title}です。XMLデータを人が読みやすい形に整形(pretty-print)します。<br />
            使い方は簡単です。XMLテキスト入力欄にXMLテキストをコピー&ペーストすると、XML出力欄に整形済みXMLが出力されます。
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default XmlFormatter;
