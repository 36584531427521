import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import InfoIcon from '@material-ui/icons/Info';
import DescriptionIcon from '@material-ui/icons/Description';
import { Link } from "react-router-dom";
import EmailIcon from '@material-ui/icons/Email';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState();

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction label="About" icon={<InfoIcon />} component={Link} to="/about" />
      <BottomNavigationAction label="Privacy Policy & Disclaimer" icon={<DescriptionIcon />}  component={Link} to="/privacy-policy" />
      <BottomNavigationAction label="Contact" icon={<EmailIcon />}  component={Link} to="/contact" />
    </BottomNavigation>
  );
}
