import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
//import jaLocale from "date-fns/locale/ja";
import DatePickerFormatter from '../../../../view/components/formatter/DatePickerFormatter';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';    
import TimezoneSelect from 'react-timezone-select'
import { DateTime } from 'luxon';
import jaLocale from "date-fns/locale/ja";
import SnsShareButtons from '../../../components/sns/SnsShareButtons';
import ReactGA from "react-ga4";


const locationPath = "/calculator/calendar/time-difference-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "時差計算 (タイムゾーン)";

let resultValue = "";
let resultValue2 = "";
let timezoneName1 = "";
let timezoneName2 = "";
let resultElement;
var timezone1 = JSON.parse('{"value":"Asia/Tokyo"}');
var timezone2 = JSON.parse('{"value":"Asia/Kolkata"}');
var date1 = new Date();

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
  },
  result: {
    marginBottom: '20px',
  },
}));

function TimeDifferenceCalculator() {
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setFormData(data)
  const [selectedTime] = React.useState(date1);
  const [selectedTimezone1, setSelectedTimezone1] = React.useState(timezone1)
  const [selectedTimezone2, setSelectedTimezone2] = React.useState(timezone2)

  // The first commit of Material-UI
  const [selectedDate] = React.useState(date1);

  const setFormData = (formValues) => {
    
    var tmpSelectedDate = formValues.startDateValue;
    var tmpSelectedTime = formValues.startTimeValue;
    var dt = DateTime.fromFormat(tmpSelectedDate + " " + tmpSelectedTime, 'yyyy/MM/dd hh:mm a' , { zone:selectedTimezone1.value});
    var dt2 = dt.setZone(selectedTimezone2.value);

    resultValue = dt.toFormat('yyyy/MM/dd hh:mm a');
    resultValue2 = dt2.toFormat('yyyy/MM/dd hh:mm a');
    timezoneName1 = selectedTimezone1.value;
    timezoneName2 = selectedTimezone2.value
}

  const handleDateChange = (date) => {
    date1.setFullYear(date.getFullYear());
    date1.setMonth(date.getMonth());
    date1.setDate(date.getDate());
  };

  const handleTimeChange = (date) => {
    date1.setHours(date.getHours());
    date1.setMinutes(date.getMinutes());
  };
  
  if(resultValue === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElement = 
    <div className={classes.result}>
      <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="center" textAlign="left">
      <Typography variant="body1" component="body2" gutterBottom>
        {timezoneName1}時間 {resultValue}の
      </Typography>
      <br />
      <Typography variant="body1" component="body2" gutterBottom>
        {timezoneName2}時間は {resultValue2}です
      </Typography>
      </Box>
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="世界の時差を計算することができます。「東京/日本時間」を「ニューヨーク時間」や「インド時間」へ変換する等、世界の都市の時差を計算することができます。時差変換したい基準となる年月日、時間、タイムゾーンと変換後のタイムゾーンを入力して「変換する」ボタンを押すと時差計算を行い変換後の日時が表示されます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="世界の時差を計算することができます。「東京/日本時間」を「ニューヨーク時間」や「インド時間」へ変換する等、世界の都市の時差を計算することができます。時差変換したい基準となる年月日、時間、タイムゾーンと変換後のタイムゾーンを入力して「変換する」ボタンを押すと時差計算を行い変換後の日時が表示されます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
        
      </Helmet>

    <div>

          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

              <Grid container justify="space-around">
                <MuiPickersUtilsProvider utils={DatePickerFormatter} locale={jaLocale}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="startDate"
                    name="startDateValue"
                    label="年/月/日"
                    format="yyyy/MM/dd"
                    defaultValue={date1}
                    value={selectedDate}   
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    okLabel="決定"
                    cancelLabel="キャンセル"
                    inputRef={register({ required: true, maxLength: 10 })}
                  />
                </MuiPickersUtilsProvider>
                
               <MuiPickersUtilsProvider utils={DatePickerFormatter}>
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    name="startTimeValue"
                    label="時間"
                    value={selectedTime}
                    onChange={handleTimeChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                    inputRef={register({ required: true })}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

            <div className='select-wrapper'>
                <TimezoneSelect
                name="timezone1"
                value={selectedTimezone1}
                onChange={setSelectedTimezone1}
                labelSTyle="altName"
                inputRef={register({ required: true })}
                />を
                <TimezoneSelect
                name="timezone2"
                value={selectedTimezone2}
                onChange={setSelectedTimezone2}
                inputRef={register({ required: true })}
                />に
            </div>

            <div>
            <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
                変換する
              </Button>
            </div>

            <br />
          
          </form>

          <div id="chart">
            {resultElement}
          </div>

          <div className={classes.snsButtonMargin}>
            <SnsShareButtons fullPath={fullPath} title="時差計算 (タイムゾーン)" hashtag={["時差計算", "タイムゾーン計算"]} />
          </div>
      </div>

      <AccordionInstruction title="説明">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
          世界の時差を計算することができます。「東京/日本時間」を「ニューヨーク時間」や「インド時間」へ変換する等、世界の都市の時差を計算することができます。<br />
            時差変換したい基準となる年月日、時間、タイムゾーンと変換後のタイムゾーンを入力して「変換する」ボタンを押すと時差計算を行い変換後の日時が表示されます。<br />
            <br />
            関連リンク<br />
            ⇒<a href="/calculator/calendar/date-calculator">日数計算</a><br />
            ⇒<a href="/calculator/calendar/time-calculator">時間計算 (終了時間 - 開始時間)</a>
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default TimeDifferenceCalculator;
