import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import ReactGA from "react-ga4";


const locationPath = "/calculator/finance/consumption-tax-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "消費税計算 税抜/税込対応";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    backgroundColor: '#ffffff',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
  },
}));

const beforeTaxStr = "beforeTax";
const afterTaxStr = "afterTax";
const roundUpStr = "roundUp";
const roundingStr = "rounding";
const roundingDownStr = "roundDown";

let resultElement, price;
var resultData = [];
let rate;
let roundingType;
let priceType = beforeTaxStr;

function createData(rate, priceBeforeTax, taxPrice, priceAfterTax) {
  return { rate, priceBeforeTax, taxPrice, priceAfterTax };
}

function getRoundedValue(roundType, value) {
  
  value = Number(value);

  if (roundType === roundUpStr) {
    //console.log("切り上げ！");
    return Math.ceil(value);
  } else if (roundType === roundingStr) {
    //console.log("四捨五入！");
    return Math.round(value);
  } else {
    //console.log("切り捨て！");
    return Math.floor(value);
  }

}

function getOppositeRoundedValue(roundType, value) {
  
  value = Number(value);

  if (roundType === roundUpStr) {
    //console.log("切り上げ！");
    return Math.floor(value);
  } else if (roundType === roundingStr) {
    //console.log("四捨五入！");
    return Math.round(value);
  } else {
    //console.log("切り捨て！");
    return Math.ceil(value);
  }

}

function setResultData(formValues) {

  //console.log("setCharData!");
  
  var newResultData = [];
  priceType = formValues.priceType;
  price = Number(formValues.price);
  roundingType = formValues.roundingType;
  rate = Number(formValues.rate);
  let priceBeforeTax, priceAfterTax;
  let taxPrice;

  /*
  console.log("priceType:" + priceType);
  console.log("price:" + price);
  console.log("roundingType:" + roundingType);
  console.log("rate:" + rate);
  */

  if (priceType === beforeTaxStr) {
    //console.log("税抜金額！");
    priceBeforeTax = price;
    taxPrice = getRoundedValue(roundingType, priceBeforeTax * (rate / 100));
  } else {
    //console.log("税込金額！");
    taxPrice = getRoundedValue(roundingType, price * (rate / 100) / (1 + rate / 100));
    priceBeforeTax = price / (1 + rate / 100);
  }
  
  priceAfterTax = priceBeforeTax + taxPrice;
  //rounding in opposite way
  priceAfterTax = getOppositeRoundedValue(roundingType, priceAfterTax);
  priceBeforeTax = getOppositeRoundedValue(roundingType, priceBeforeTax);

  newResultData = [
    createData(rate + "%", priceBeforeTax, taxPrice, priceAfterTax),
  ];

  //Important!
  resultData = newResultData;
}

function ConsumptionTaxCalculator() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setResultData(data)
  const [value, setValue] = React.useState(priceType);
  const [state, setState] = React.useState({
    aroundingType: '',
    name: '',
  });

  const handleChangeForPriceType = (event) => {
    //console.log(event.target.value);
    setValue(event.target.value);
  };

  const handleChangeForRoundingType = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  
  if(resultData.length === 0) {

    rate = 10;

  } else {

    resultElement = 
    <div style={{ width: '100%' }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>消費税率</TableCell>
              <TableCell align="right">税抜金額</TableCell>
              <TableCell align="right">消費税</TableCell>
              <TableCell align="right">税込金額</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resultData.map((row) => (
              <TableRow key={row.rate}>
                <TableCell component="th" scope="row" align="right">
                  {row.rate}
                </TableCell>
                <TableCell align="right">{Number(row.priceBeforeTax).toLocaleString()}</TableCell>
                <TableCell align="right">{Number(row.taxPrice).toLocaleString()}</TableCell>
                <TableCell align="right">{Number(row.priceAfterTax).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br /><br />
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="消費税計算ツールです。税抜金額、税込金額の両方に対応しています。税抜または税込金額と消費税率を入力して金額と消費税を求めます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="消費税計算ツールです。税抜金額、税込金額の両方に対応しています。税抜または税込金額と消費税率を入力して金額と消費税を求めます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

        <div>
          <FormControl component="fieldset">
            <RadioGroup aria-label="priceType" name="priceType" value={value} onChange={handleChangeForPriceType}>
              <FormControlLabel value={beforeTaxStr} control={<Radio />} label="税抜金額を入力する" inputRef={register({ required: true })}/>
              <FormControlLabel value={afterTaxStr} control={<Radio />} label="税込金額を入力する" inputRef={register({ required: true })}/>
            </RadioGroup>
         </FormControl>

        </div>
       <div>
        <TextField
          id="price"
          label="金額"
          type="number"
          name="price"
          defaultValue={price}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 20 })}
        />
      </div>

      <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple" >小数点以下処理</InputLabel>
          <Select
            native
            defaultValue={roundingType}
            onChange={handleChangeForRoundingType}
            inputProps={{
              name: 'roundingType',
              id: 'age-native-simple',
            }}
            inputRef={register({ required: true })}
          >
            <option value={roundingDownStr}>切り捨て</option>
            <option value={roundingStr}>四捨五入</option>
            <option value={roundUpStr}>切り上げ</option>
          </Select>
        </FormControl>
      </div>
      
      <div>
        <TextField
          id="rate"
          label="消費税率(%)"
          type="number"
          name="rate"
          defaultValue={rate}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 10 })}
        />
      </div>

      <div>
       <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
          計算する
        </Button>
      </div>
      <br />
      
    </form>

      <div id="result">
        {resultElement}
      </div>

      <AccordionInstruction title="使い方">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
        <Typography variant="body2" gutterBottom>
          消費税計算ツールです。<br />
          税抜金額、税込金額の両方に対応しています。<br />
          税抜または税込金額と消費税率を入力して金額と消費税を求めます。<br />
          小数点以下は以下から選択することができます。<br />
          ・切り捨て<br />
          ・四捨五入<br />
          ・切り上げ<br />
        </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />

    </div>
  );
}

export default ConsumptionTaxCalculator;
