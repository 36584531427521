import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
//import PieChartIcon from '@material-ui/icons/PieChart';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CustomizedAxisTick from '../../../components/chart/CustomizedAxisTick';
import CustomizedLabel from '../../../components/chart/CustomizedLabel';
//import SnsShareButtons from '../../../components/sns/SnsShareButtons';
import { BigNumber } from 'bignumber.js';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import SnsShareButtons from '../../../components/sns/SnsShareButtons';
import AdSense from 'react-adsense';
import ReactGA from "react-ga4";


const locationPath = "/calculator/healthcare/basal-metabolic-rate-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "BMR 基礎代謝量計算ツール(グラフ表示)";

const female = "女性";
const male = "男性";

const female_param1 = BigNumber(665.1);
const female_param2 = BigNumber(9.6);
const female_param3 = BigNumber(1.7);
const female_param4 = BigNumber(7.0);
const male_param1 = BigNumber(66);
const male_param2 = BigNumber(13.7);
const male_param3 = BigNumber(5.0);
const male_param4 = BigNumber(6.8);

const female_stroke = "#e761fa";
const male_stroke = "#1880db";

let gender = female;

let resultValue = "";
let ageValue = "";
let heightValue = "";
let weightValue = "";
let resultElement;
let chartData1;
let strokeColor;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '10px',
    marginBottom: '10px',
  },
  margin: {
    marginBottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    
  },
  paper: {
  },
  margin_ads: {
    marginTop: '5px',
    marginBottom: '10px',
  },
  indent: {
    textIndent: '1em',
  },
  adMargin: {
    marginTop: '5px',
    marginBottom: '10px',
  },
}));

var data1 = [];

const ageRangeArray = ["1-2歳", "3-5歳", "6-7歳", "8-9歳", "10-11歳", "12-14歳", "15-17歳", "18-29歳", "30-49歳", "50-69歳", "70歳以上"];
const averageBmrFemale = [660, 840, 920, 1050, 1260, 1410, 1310, 1110, 1150, 1100, 1020];
const averageBmrMale = [700, 900, 980, 1140, 1330, 1520, 1610, 1520, 1530, 1400, 1290];

function setFormData(formValues) {

  gender = formValues.gender;
  //console.log(gender);
  ageValue = BigNumber(formValues.age);
  let ageIndex = 0;

  if (ageValue <= 2) {
    ageIndex = 0;
  } else if (ageValue <= 5) {
    ageIndex = 1;
  } else if (ageValue <= 7) {
    ageIndex = 2;
  } else if (ageValue <= 9) {
    ageIndex = 3;
  } else if (ageValue <= 11) {
    ageIndex = 4;
  } else if (ageValue <= 14) {
    ageIndex = 5;
  } else if (ageValue <= 17) {
    ageIndex = 6;
  } else if (ageValue <= 29) {
    ageIndex = 7;
  } else if (ageValue <= 49) {
    ageIndex = 8;
  } else if (ageValue <= 69) {
    ageIndex = 9;
  } else {
    ageIndex = 10;
  }

  heightValue = BigNumber(formValues.height);
  weightValue = BigNumber(formValues.weight);

  // Set female by default
  let param1 = female_param1;
  let param2 = female_param2;
  let param3 = female_param3;
  let param4 = female_param4;

  if (gender === male) {
    param1 = male_param1;
    param2 = male_param2;
    param3 = male_param3;
    param4 = male_param4;
  }

  let resultValue1, resultValue2, resultValue3;

  resultValue1 = param2.multipliedBy(weightValue).plus(param1);
  resultValue2 = param3.multipliedBy(heightValue); 
  resultValue3 = param4.multipliedBy(ageValue);
  resultValue = resultValue1.plus(resultValue2).minus(resultValue3);

  let averageBmr = [];
  if (gender === female) {
    averageBmr = averageBmrFemale;
    strokeColor = female_stroke;
  } else {
    averageBmr = averageBmrMale;
    strokeColor = male_stroke;
  }
  
  // For chart
  for (let i = 0; i < ageRangeArray.length; i++) {
    if (ageIndex === i) {
      data1[i] = {name:ageRangeArray[i], 参照体重における日本人の基礎代謝量:averageBmr[i], あなたの基礎代謝量:Math.round(resultValue)}
    } else {
      data1[i] = {name:ageRangeArray[i], 参照体重における日本人の基礎代謝量:averageBmr[i]}
    }
  }

  chartData1 = <LineChart
    width={330}
    height={300}
    data={data1}
    margin={{
      top: 20,
      right: 30,
      left: 20,
      bottom: 10,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
    <YAxis />
    <Tooltip />
    <Legend />
    <Line type="monotone" dataKey="あなたの基礎代謝量" stroke="red" dot={{ strokeWidth: 4 }} />
    <Line type="monotone" dataKey="参照体重における日本人の基礎代謝量" stroke={strokeColor} label={<CustomizedLabel/>} />
  </LineChart>;
}

function BasalMetabolicRateCalculator() {
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setFormData(data)
   
  const [value, setValue] = React.useState(gender);

  const handleChangeForGender = (event) => {
    //console.log(event.target.value);
    gender = event.target.value;
    setValue(event.target.value);
  };

  //const labelPieChart = <div className={classes.chartLabel}><Typography variant="subtitle1" gutterBottom><PieChartIcon />円グラフ</Typography></div>;

  //document.title = '貯蓄率計算';
  
  if(resultValue === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElement = 
    <div className={classes.margin}>
        <div>
          <TextField
            id="result"
            label="あなたの基礎代謝量(kcal/日)"
            defaultValue={resultValue}
            value={resultValue.toFormat()}
            endAdornment={<InputAdornment position="end">kcal/日</InputAdornment>}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
        {chartData1}
        </div>
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="基礎代謝量計算ツール(グラフ表示)です。一日の消費エネルギー量は性別や体重、年齢などによって人それぞれ異なります。このページではハリス・ベネディクトの式を日本人向けに改良したものを使用しています。算出したBMRを厚生労働省公表の日本人の基礎代謝基準値グラフに表示します。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="基礎代謝量計算ツール(グラフ表示)です。一日の消費エネルギー量は性別や体重、年齢などによって人それぞれ異なります。このページではハリス・ベネディクトの式を日本人向けに改良したものを使用しています。算出したBMRを厚生労働省公表の日本人の基礎代謝基準値グラフに表示します。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>


      <div className={classes.snsButtonMargin}>
        <SnsShareButtons fullPath={fullPath} title="BMR 基礎代謝量計算ツール" hashtag={["BMR"]} />
      </div>
      
    <div className={classes.root}>

          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextField
                id="age"
                label="年齢を入力"
                type="number"
                name="age"
                endAdornment={<InputAdornment position="end">歳</InputAdornment>}
                defaultValue={ageValue}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                inputRef={register({ required: true, maxLength: 20 })}
                variant="outlined"
              />
            </div>
            <br />
            <div>
              <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender" value={value} onChange={handleChangeForGender} row>
                  <FormControlLabel value={female} control={<Radio />} label={female} inputRef={register({ required: true })}/>
                  <FormControlLabel value={male} control={<Radio />} label={male} inputRef={register({ required: true })}/>
                </RadioGroup>
              </FormControl>
            </div>
            <br />
            <div>
              <TextField
                id="height"
                label="身長を入力"
                type="number"
                name="height"
                endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                defaultValue={heightValue}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                inputRef={register({ required: true, maxLength: 20 })}
                variant="outlined"
              />
            </div>
            <br />
            <div>
              <TextField
                id="weight"
                label="体重を入力"
                type="number"
                name="weight"
                endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                defaultValue={weightValue}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                inputRef={register({ required: true, maxLength: 20 })}
                variant="outlined"
              />
            </div>
          
            <br /> 

            <div>
            <Button type="submit" label="submit" variant="contained" size="small" color="primary">
              計算する
            </Button>
            
            <AdSense.Google
              client='ca-pub-6460385026549088'
              slot='3444057112'
              style={{ display: 'block' }}
              layout='in-article'
              format='fluid'
            />
            </div>
          
          </form>

          <div>
            {resultElement}
          </div>

      </div>

      <AccordionInstruction title="説明">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            基礎代謝量計算ツール(BMR計算ツール)です。<br />
            年齢、性別、身長、体重を入力して「計算する」ボタンを押すと、参照体重における日本人の基礎代謝量のグラフ上にあなたの基礎代謝量(kcal/日)がプロットされます。<br />
            一日の消費エネルギー量は性別や体重、年齢などによって人それぞれ異なり、一般的には加齢に伴い基礎代謝量は低下します。<br />
            主な理由は筋肉などの除脂肪量の低下があります。活動時のエネルギー代謝量も同様に低くなることにもつながっています。<br />
            このページでは日本人向けに改良したハリス・ベネディクトの式を使って基礎代謝量を算出します。<br />
            算出した基礎代謝量を、厚生労働省公表の参照体重における年代別基礎代謝量(*)のグラフ上に表示します。<br />
            <br />
            ハリス・ベネディクト式(日本版)の計算式<br />
            <p className={classes.indent}>女性：{female_param1.toFormat()} + {female_param2.toFormat()} × 体重(kg) + {female_param3.toFormat()} × 身長(cm) － {female_param4.toFormat()} × 年齢</p>
            <p className={classes.indent}>男性：{male_param1.toFormat()} + {male_param2.toFormat()} × 体重(kg) + {male_param3.toFormat()} × 身長(cm) － {male_param4.toFormat()} × 年齢</p>
            <br />
            <Link target="_blank" href="https://www.e-healthnet.mhlw.go.jp/information/exercise/s-02-004.html" rel="noopener noreferrer">加齢とエネルギー代謝. e-ヘルスネット.</Link> 厚生労働省. (2022)<br />
            <br />
            関連リンク<br />
            ⇒<Link href="/calculator/healthcare/bmi-calculator">BMI計算</Link><br />
            ⇒<Link href="/calculator/healthcare/expected-life-at-age-calculator">平均余命計算</Link>
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />

    </div>
  );

}

export default BasalMetabolicRateCalculator;
