import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import AdSense from 'react-adsense';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ComputerIcon from '@material-ui/icons/Computer';
import ReactGA from "react-ga4";


const locationPath = "/en/";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "Useful Calculator Site";

const useStyles = makeStyles((theme) => ({
  releaseNotes: {
    position: 'relative',
    height: '400px',
    overflowY: 'scroll',
  },
  paper: {
    padding: theme.spacing(2),
    margin: '5px',
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '240px',
    height: 'auto',
  },
}));

function HomePage() {

  // For the English pages
  var newLang = 'en';
  document.documentElement.lang = newLang;

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);

  const classes = useStyles();

  return (

      <div>
          <Helmet>
              <title>{title}</title>
              <meta name="description" content="This site provides useful calculator and tools such as Fire calculator, Percentage calculator, etc." />
              <link rel="alternate" href="https://www.life-util.com" hreflang="ja"/>
              <link rel="alternate" href="https://www.life-util.com/en/" hreflang="en" />
              <meta property="og:url" content={fullPath} />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={title} />
              <meta property="og:description" content="This site provides useful calculator and tools such as Fire calculator, Percentage calculator, etc." />
              <meta property="og:site_name" content="Life-Util" />
          </Helmet>

          <Paper elevation={3} className={classes.paper}>
            <Typography variant="body1" component="h2"><AttachMoneyIcon />Finance</Typography>
            <Link href="/en/calculator/finance/simple-fire-calculator">Simple FIRE Calculator</Link>
          </Paper>

          <Paper elevation={3} className={classes.paper}>
            <Typography variant="body1" component="h2"><ComputerIcon />Math</Typography>
            <Link href="/en/calculator/math/percentage-calculator">Percentage Calculator</Link>
          </Paper>
          
          
          <AdSense.Google
            client='ca-pub-6460385026549088'
            slot='3710473082'
            style={{ display: 'block' }}
            layout='in-article'
            format='fluid'
          />

      </div>

  );

}

export default HomePage;
