import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ReactGA from "react-ga4";


const locationPath = "/tools/barcode-generator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "バーコード作成";

var Barcode = require('react-barcode');

let urlValue = "";
let resultElement;
let barcodeTypeValue;
const CODE128 = "code128";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    marginTop: '50px',
  },
  resultElementStyle: {
      marginBottom: '15px',
  },
}));

function setFormData(formValues) {
    urlValue = formValues.url;
    barcodeTypeValue = formValues.barcodeType;
}

function BarcodeGenerator() {
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setFormData(data)
  const [state, setState] = React.useState({
    scaleType: '',
    name: '',
  });
  
  const handleChangeForBarcodeType = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  if(urlValue === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElement = 
    <div className={classes.resultElementStyle}>
      <Barcode value={urlValue} 
        width='2'
      />
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="このページではバーコードを作成することができます。URLや文字列を入力して「バーコードを生成する」ボタンを押すとバーコードが表示されます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="このページではバーコードを作成することができます。URLや文字列を入力して「バーコードを生成する」ボタンを押すとバーコードが表示されます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div>

        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TextField
              id="url"
              label="データを入力"
              name="url"
              defaultValue={urlValue}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              inputRef={register({ required: true, maxLength: 4000 })}
              variant="outlined"
            />
          </div>

          <div>
            <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple" >フォーマット</InputLabel>
            <Select
                native
                defaultValue={barcodeTypeValue}
                onChange={handleChangeForBarcodeType}
                inputProps={{
                name: 'barcodeType',
                id: 'age-native-simple',
                }}
                inputRef={register({ required: true })}
            >
                <option value={CODE128}>{CODE128}</option>
            </Select>
            </FormControl>
          </div>
        
          <br /> 

          <div>
          <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
              バーコードを作成する
            </Button>
          </div>

          <br />
        
        </form>

        <div id="chart">
          {resultElement}
        </div>
      </div>

      <AccordionInstruction title="説明">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            このページではバーコードを作成することができます。<br />
            URLや文字列を入力して「バーコードを作成する」ボタンを押すとバーコードが表示されます。<br />
            <br />
            無料でご自由にお使い頂けます。<br />
            ※作成後は期待通り読み取れることを自分で確認してから使いましょう。<br />
            <br />
            関連リンク<br />
            ⇒<a href="/tools/password-generator">パスワード生成</a><br />
            ⇒<a href="/tools/qr-code-generator">QRコード作成</a>
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default BarcodeGenerator;
