import './App.css';
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import LeaveAllowanceMonthlySalaryCalulator from './view/pages/calculator/finance/LeaveAllowanceMonthlySalaryCalulator';
import CompoundInterestCalculator from './view/pages/calculator/finance/CompoundInterestCalculator';
import FireCalculator from './view/pages/calculator/finance/FireCalculator';
import BaristaFireCalculator from './view/pages/calculator/finance/BaristaFireCalculator';
import ExpectedAssetAmountCalculator from './view/pages/calculator/finance/ExpectedAssetAmountCalculator';
import HumanLifeValueCalculator from './view/pages/calculator/finance/HumanLifeValueCalculator';
import SavingsRateCalculator from './view/pages/calculator/finance/SavingsRateCalulator';
import ConsumptionTaxCalculator from './view/pages/calculator/finance/ConsumptionTaxCalculator';
import PersistentDrawerLeftNested from './view/components/PersistentDrawerLeftNested';
import BottomNavigation from './view/components/BottomNavigation';
import GramToOunceConverter from './view/pages/calculator/converter/GramToOunceConverter';
import InchToCentimeterConverter from './view/pages/calculator/converter/InchToCentimeterConverter';
import MileToKilometerConverter from './view/pages/calculator/converter/MileToKilometerConverter';
import ByteConverter from './view/pages/calculator/converter/ByteConverter';
import TorqueConverter from './view/pages/calculator/converter/TorqueConverter';
import PressureConverter from './view/pages/calculator/converter/PressureConverter';
import MeterToYardConverter from './view/pages/calculator/converter/MeterToYardConverter';
import SquareMetreTsuboTatami from './view/pages/calculator/converter/SquareMetreTsuboTatami';
import CelsiusToFahrenheitConverter from './view/pages/calculator/converter/CelsiusToFahrenheitConverter';
import QuartToLitreConverter from './view/pages/calculator/converter/QuartToLitreConverter';
import HomePage from './view/pages/HomePage';
import HomePageEn from './view/pages/HomePageEn';
import BmiCalculator from './view/pages/calculator/healthcare/BmiCalculator';
import BasalMetabolicRateCalculator from './view/pages/calculator/healthcare/BasalMetabolicRateCalculator';
import ExpectedLifeAtAgeCalculator from './view/pages/calculator/healthcare/ExpectedLifeAtAgeCalculator';
import ContactPage from './view/pages/ContactPage';
import DateCalculator from './view/pages/calculator/calendar/DateCalulator';
import TimeCalculator from './view/pages/calculator/calendar/TimeCalulator';
import TimeDifferenceCalculator from './view/pages/calculator/calendar/TimeDifferenceCalculator';
import PasswordGenerator from './view/pages/tool/PasswordGenerator';
import QrCodeGenerator from './view/pages/tool/QrCodeGenerator';
import BarcodeGenerator from './view/pages/tool/BarcodeGenerator';
import AboutPage from './view/pages/AboutPage';
import PrivacyPolicyPage from './view/pages/PrivacyPolicyPage';
import PercentageCalculator from './view/pages/calculator/math/PercentageCalculator';
import PercentageCalculatorEn from './view/pages/calculator/math/PercentageCalculatorEn';
import FireCalculatorEn from './view/pages/calculator/finance/FireCalculatorEn';
import ColorPickerTool from './view/pages/tool/ColorPickerTool';
import SketchCanvas from './view/pages/tool/SketchCanvas';
import CharacterCounter from './view/pages/tool/CharacterCounter';
import XmlFormatter from './view/pages/tool/XmlFormatter';
import JsonFormatter from './view/pages/tool/JsonFormatter';
import AspectRatioCalculator from './view/pages/tool/AspectRatioCalculator';
import Chessboard from './view/pages/game/SimpleChessboard';

function App() {

  return (
    <div className="App">
      <header>
        <div style={{backgroundColor: '#ffffff'}}>
          <BrowserRouter>
            <div>
              <PersistentDrawerLeftNested />
              <div style={{marginBottom: 30}}  />
              <Route path="/" exact component={HomePage} />
              <Route path="/en/" exact component={HomePageEn} />
              <Route path="/about" exact component={AboutPage} />
              <Route path="/privacy-policy" exact component={PrivacyPolicyPage} />
              <Route path="/contact" exact component={ContactPage} />
              <Route path="/calculator/finance/leave-allowance-monthly-salary-calculator" component={LeaveAllowanceMonthlySalaryCalulator} />
              <Route path="/calculator/finance/compound-interest-calculator" component={CompoundInterestCalculator} />
              <Route path="/calculator/finance/fire-calculator" component={FireCalculator} />
              <Route path="/calculator/finance/fire-simulator" component={FireCalculator} />
              <Route path="/calculator/finance/barista-fire-calculator" component={BaristaFireCalculator} />
              <Route path="/calculator/finance/savings-rate-calculator" component={SavingsRateCalculator} />
              <Route path="/calculator/finance/consumption-tax-calculator" component={ConsumptionTaxCalculator} />
              <Route path="/calculator/finance/expected-asset-amount-calculator" component={ExpectedAssetAmountCalculator} />
              <Route path="/calculator/finance/human-life-value-calculator" component={HumanLifeValueCalculator} />
              <Route path="/calculator/converter/gram-to-ounce-converter" component={GramToOunceConverter} />
              <Route path="/calculator/converter/inch-to-centimeter-converter" component={InchToCentimeterConverter} />
              <Route path="/calculator/converter/mile-to-kilometer-converter" component={MileToKilometerConverter} />
              <Route path="/calculator/converter/byte-converter" component={ByteConverter} />
              <Route path="/calculator/converter/square-metre-tsubo-tatami-converter" component={SquareMetreTsuboTatami} />
              <Route path="/calculator/converter/torque-converter" component={TorqueConverter} />
              <Route path="/calculator/converter/pressure-converter" component={PressureConverter} />
              <Route path="/calculator/converter/celsius-to-fahrenheit-converter" component={CelsiusToFahrenheitConverter} />
              <Route path="/calculator/converter/quart-to-litre-converter" component={QuartToLitreConverter} />
              <Route path="/calculator/converter/meter-to-yard-converter" component={MeterToYardConverter} />
              <Route path="/calculator/healthcare/bmi-calculator" component={BmiCalculator} />
              <Route path="/calculator/healthcare/basal-metabolic-rate-calculator" component={BasalMetabolicRateCalculator} />
              <Route path="/calculator/healthcare/expected-life-at-age-calculator" component={ExpectedLifeAtAgeCalculator} />
              <Route path="/calculator/calendar/date-calculator" component={DateCalculator} />
              <Route path="/calculator/calendar/time-calculator" component={TimeCalculator} />
              <Route path="/calculator/calendar/time-difference-calculator" component={TimeDifferenceCalculator} />
              <Route path="/tools/password-generator" component={PasswordGenerator} />
              <Route path="/tools/qr-code-generator" component={QrCodeGenerator} />
              <Route path="/tools/barcode-generator" component={BarcodeGenerator} />
              <Route path="/tools/color-picker" component={ColorPickerTool} />
              <Route path="/tools/sketch-canvas" component={SketchCanvas} />
              <Route path="/calculator/math/percentage-calculator" component={PercentageCalculator} />
              <Route path="/tools/character-counter" component={CharacterCounter} />
              <Route path="/tools/xml-formatter" component={XmlFormatter} />
              <Route path="/tools/json-formatter" component={JsonFormatter} />
              <Route path="/tools/aspect-ratio-calculator" component={AspectRatioCalculator} />
              <Route path="/game/chessboard" component={Chessboard} />

              <Route path="/en/calculator/finance/simple-fire-calculator" component={FireCalculatorEn} />
              <Route path="/en/calculator/math/percentage-calculator" component={PercentageCalculatorEn} />

              <BottomNavigation />
            </div>
          </BrowserRouter>
       </div>
      </header>
    </div>
  );
}

export default App;
