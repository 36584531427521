import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import "react-color-palette/lib/css/styles.css";
import TextField from '@material-ui/core/TextField';
import SnsShareButtons from '../../components/sns/SnsShareButtons';
import ReactJsonFormatter from 'react-json-formatter';
//import FileCopyIcon from '@material-ui/icons/FileCopy';
import Paper from '@material-ui/core/Paper';
import ReactGA from "react-ga4";


const locationPath = "/tools/json-formatter";
const title = "JSONフォーマッター";
const fullPath = "https://www.life-util.com" + locationPath;
const description = {title} + "です。JSONデータを人が読みやすい形に整形(pretty-print)します。使い方は簡単です。JSON入力欄に整形したいJSONテキストデータをコピー&ペーストすると、JSON出力欄に整形済みJSONが出力されます。";

let strToCount;
let inputStr;
let formattedElement;

const jsonStyle = {
  propertyStyle: { color: 'red' },
  stringStyle: { color: 'green' },
  numberStyle: { color: 'darkorange' }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    marginTop: '50px',
  },
  textareainput: {
    minWidth: '80%',
    marginBottom: '10px'
  },
  textareaformatted: {
    minWidth: '80%',
    marginBottom: '10px'
  },
  paperchildstyle: {
    background: '#0c8bed',
    color: '#FFFFFF',
  },
  snsButtonMargin: {
    marginBottom: '15px',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '20px',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paperstyle: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
    padding: '20px',
    
    '& > *': {
      margin: theme.spacing(1),
    },  
  },
}));

function JsonFormatter() {
  
  const [value, setValue] = React.useState(0);
  let dummy = value;
  if (dummy == null);

  const calculate = (str) => {
    inputStr = document.getElementById("textareainput").value;
    if (inputStr == null) {
      formattedElement = "";
    } else {
      formattedElement = 
        <Paper variant="outlined" elevation={3} className={classes.paperstyle}>
          <ReactJsonFormatter json={inputStr} tabWith={4} jsonStyle={jsonStyle} />
        </Paper>;
    }
  }

  /**
  const copyToClipboard = async () => {
    // nbsp;(\u00A0)を通常のスペース(\u0020)に置き換える必要がある。
    let copyStr = global.navigator.clipboard.writeText(document.getElementById("formattedStr").innerText);
    //console.log("copyStr:" + copyStr);
    await copyStr.replace( String.fromCharCode(160) , ' ');
  }; */

  const handleChange = (event) => {
    //console.log("handleChange");
    strToCount = event.target.value;
    setValue(event.target.value);
    calculate(strToCount);
  };
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div className={classes.snsButtonMargin}>
        <SnsShareButtons fullPath={fullPath} title={title} hashtag={["JSONフォーマッター", "JSONフォーマット"]} />
      </div>

      <form noValidate autoComplete="off">

        <div>
          <TextField 
           className={classes.textareainput}
            id="textareainput"
            label="JSON入力欄"
            placeholder="ここに入力してください。"
            multiline
            variant="outlined"
            onChange={handleChange}
            rows={10}
          />
          <AdSense.Google
            client='ca-pub-6460385026549088'
            slot='5384847791'
            style={{ display: 'block' }}
            layout='in-article'
            format='fluid'
          />
        </div>

        <div>
          <Typography variant="h6" component="h4">
            JSON出力欄
          </Typography>
          
          {/** 
          <div>
            <FileCopyIcon color="primary" onClick={() => copyToClipboard()} /> (左のアイコンをクリックでクリップボードにコピーできます)
          </div>
           */}
         
          <div className={classes.list} id="formattedStr">
            {formattedElement}
          </div>
          
          <AdSense.Google
            client='ca-pub-6460385026549088'
            slot='2758684457'
            style={{ display: 'block' }}
            layout='in-article'
            format='fluid'
          />
        </div>
        
      </form>

      <AccordionInstruction title="使い方">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            {title}です。JSONデータを人が読みやすい形に整形(pretty-print)します。<br />
            使い方は簡単です。JSON入力欄に整形したいJSONテキストデータをコピー&ペーストすると、JSON出力欄に整形済みJSONが出力されます。<br />
            <br />
            プロパティは赤、数値はオレンジ、文字列は緑で表示されます。
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default JsonFormatter;
