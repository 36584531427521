import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AdSense from 'react-adsense';
import Link from '@material-ui/core/Link';
import ReactGA from "react-ga4";


const locationPath = "/calculator/finance/leave-allowance-monthly-salary-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "休業手当の計算方法と計算ツール";

let paidLastMonthValue, daysLastMonthValue, paidSecondMonthValue, daysSecondMonthValue, paidThirdMonthValue, daysThirdMonthValue, leaveDaysValue, averagePaidAmount;
let totalAmount = "";
let resultElement = "";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    maxWidth: '500px',
  },
  margin_result: {
    marginTop: '20px',
  },
  margin: {
    marginTop: '5px',
  },
}));

function setFormData(formValues) {

  paidLastMonthValue = Number(formValues.paidLastMonth);
  daysLastMonthValue = Number(formValues.daysLastMonth);
  paidSecondMonthValue = Number(formValues.paidSecondMonth);
  daysSecondMonthValue = Number(formValues.daysSecondMonth);
  paidThirdMonthValue = Number(formValues.paidThirdMonth);
  daysThirdMonthValue = Number(formValues.daysThirdMonth);
  leaveDaysValue = Number(formValues.leaveDays);

  averagePaidAmount = (paidLastMonthValue + paidSecondMonthValue + paidThirdMonthValue) / (daysLastMonthValue + daysSecondMonthValue + daysThirdMonthValue);
  averagePaidAmount = Math.floor(averagePaidAmount * 100) / 100;

  totalAmount = Number(averagePaidAmount * 0.6 * leaveDaysValue);
  totalAmount = Math.round(totalAmount);

}

function LeaveAllowanceMonthlySalaryCalulator() {
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setFormData(data)

  //document.title = '貯蓄率計算';
  
  if(totalAmount === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElement = 
    <div>
      <TextField
        id="result"
        label="平均賃金(1銭未満は切り捨て)"
        defaultValue={averagePaidAmount}
        value={Number(averagePaidAmount).toLocaleString()}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
      <br /><br />
      <TextField
        id="result2"
        label="休業手当支給額(1円未満は四捨五入)"
        defaultValue={totalAmount}
        value={Number(totalAmount).toLocaleString()}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
      <br /><br />
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="このページでは休業手当の計算方法の簡単な説明をします。また、計算ツールを使って休業手当支給額を試算することが出来ます。計算ツールを使うことができますので試算してみましょう。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="このページでは休業手当の計算方法の簡単な説明をします。また、計算ツールを使って休業手当支給額を試算することが出来ます。計算ツールを使うことができますので試算してみましょう。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div>

        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
            <Typography variant="body2" component="body1" gutterBottom>
              休業手当は平均賃金を元に計算します。<br />
              最初に平均賃金を算出するため、過去3か月分(*1)の賃金と総日数(*2)を入力します。
            </Typography>
        </Box>

        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          
         <div >
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={3}>
              <React.Fragment>
                  <Grid item xs={3}>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="caption" component="body2" gutterBottom>
                      支払われた賃金(*3)
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption" component="body2" gutterBottom>
                      総日数(*2)
                    </Typography>
                  </Grid>
                </React.Fragment>
            </Grid>

            <Grid container item xs={12} spacing={3}>
              <React.Fragment>
                <Grid item xs={3}>
                 <Typography variant="caption" component="body2" gutterBottom>
                    3ヶ月前(*1)
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="paidThirdMonth"
                    label="賃金"
                    type="number"
                    name="paidThirdMonth"
                    defaultValue={paidThirdMonthValue}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    inputRef={register({ required: true, maxLength: 10 })}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="daysThirdMonth"
                    label="日数"
                    type="number"
                    name="daysThirdMonth"
                    defaultValue={daysThirdMonthValue}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    inputRef={register({ required: true, maxLength: 4 })}
                    variant="outlined"
                  />
                </Grid>
              </React.Fragment>
            </Grid>

            <Grid container item xs={12} spacing={3}>
              <React.Fragment>
                <Grid item xs={3}>
                 <Typography variant="caption" component="body2" gutterBottom>
                    前々月
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="paidSecondMonth"
                    label="賃金"
                    type="number"
                    name="paidSecondMonth"
                    defaultValue={paidSecondMonthValue}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    inputRef={register({ required: true, maxLength: 10 })}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="daysSecondMonth"
                    label="日数"
                    type="number"
                    name="daysSecondMonth"
                    defaultValue={daysSecondMonthValue}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    inputRef={register({ required: true, maxLength: 4 })}
                    variant="outlined"
                  />
                </Grid>
              </React.Fragment>
            </Grid>

            <Grid container item xs={12} spacing={3}>
              <React.Fragment>
                <Grid item xs={3}>
                 <Typography variant="caption" component="body2" gutterBottom>
                    前月
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="paidLastMonth"
                    label="賃金"
                    type="number"
                    name="paidLastMonth"
                    defaultValue={paidLastMonthValue}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    inputRef={register({ required: true, maxLength: 10 })}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="daysLastMonth"
                    label="日数"
                    type="number"
                    name="daysLastMonth"
                    defaultValue={daysLastMonthValue}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    inputRef={register({ required: true, maxLength: 4 })}
                    variant="outlined"
                  />
                </Grid>
              </React.Fragment>
            </Grid>

          </Grid>

          <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
            <Typography variant="body2" component="h2" gutterBottom>
              上記で求めた平均賃金を元に休業手当を支給する日数を以下に入力して「計算する」ボタンを押すと、平均賃金と休業手当支給額が表示されます。
            </Typography>
          </Box>

          <Grid container spacing={1}>
          <Grid container item xs={12} spacing={3}>
              <React.Fragment>
                <Grid item xs={7}>
                 <Typography variant="caption" component="body2" gutterBottom>
                  休業手当を支給する日数
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="leaveDays"
                    label="日数"
                    type="number"
                    name="leaveDays"
                    defaultValue={leaveDaysValue}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    inputRef={register({ required: true, maxLength: 4 })}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={0}>
                </Grid>
              </React.Fragment>
            </Grid>
          </Grid>

        </div>

        <div>
          <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
              計算する
          </Button>
        </div>
        
        <div id="chart" className={classes.margin_result}>
          {resultElement}
        </div>
        
        <div className={classes.margin}>
          <script language="javascript" src="//ad.jp.ap.valuecommerce.com/servlet/smartphonebanner?sid=3643627&pid=887791374&position=inline"></script>
        </div>

      </form>
        
      

      </div>

      <AccordionInstruction title="説明">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2">
            休業手当計算ツールです。<br />
            コロナ禍の緊急事態宣言によりやむを得ず従業員を休業させる事が増えています。<br />
            休業手当の計算方法は、月給の場合と日給・時給の場合で異なります。<br />
            このページでは月給の場合の休業手当の計算方法の説明をします。<br />
            <br />
            平均賃金を求める計算式は以下のようになります。<br />
            <br />
            　平均賃金 = 過去3ヶ月の支払賃金の合計 ÷ 過去3ヶ月の総日数<br />
            <br />
            平均賃金に休業手当を支給する休業日数を掛けて支給額の合計を以下の計算式で求めます。<br />
            <br />
            　休業手当支給額 = 平均賃金 × 60％ × 休業日数<br />
            <br />
            (*1) 休業初日の前日から遡って３か月です。休業の初日("事由の発生した日")は含みません。<br />
            　　賃金締切日がある場合は、直近の賃金締切日から遡って３か月です。<br />
            　　賃金締切日に休業が始まった場合には、その前の締切日から遡って計算します。<br />
            <br />
            (*2) 暦日数です。労働した日数と異なり、土日等（平日休みの場合はその休日）を含めての日数です。<br />
            <br />
            (*3) 支払われた賃金の総額は、残業手当、精皆勤手当、年次有給休暇の賃金等、支払われなかった未払い賃金も含みます。<br />
            <br />
            詳細は<Link target="_blank" href="https://www.mhlw.go.jp/index.html" rel="noopener noreferrer">厚生労働省のサイト</Link>をご確認ください。<br />
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default LeaveAllowanceMonthlySalaryCalulator;
