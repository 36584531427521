import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import Link from '@material-ui/core/Link';
import ReactGA from "react-ga4";


const locationPath = "/calculator/healthcare/bmi-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "BMI計算ツールと計算式の説明";

let resultValue = "";
let incomeValue = "";
let savingValue = "";
let resultElement;
let resultValue2 = "";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    marginTop: '50px',
  },
}));

function setFormData(formValues) {
    incomeValue = formValues.income / 100;
    savingValue = formValues.saving;

    //console.log("incomeValue:" + incomeValue + " / savingValue:" + savingValue);

    resultValue = Math.round(savingValue / incomeValue / incomeValue * 100) / 100;

    resultValue2 = Math.round(incomeValue * incomeValue * 22 * 100) / 100;
}

function BmiCalculator() {
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setFormData(data)

  //document.title = '貯蓄率計算';
  
  if(resultValue === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElement = 
    <div>
      <TextField
        id="result"
        label="BMI(kg/㎡)"
        defaultValue={resultValue}
        value={resultValue}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
      <br /><br />
      <TextField
        id="result2"
        label="適正体重(kg)"
        defaultValue={resultValue2}
        value={resultValue2}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
      <br /><br />
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="BMI計算ツールと計算式の説明です。 身長と体重からBMIを計算することができます。BMI(Body Mass Index)は、体重と身長から算出する肥満度を表す体格指数(栄養指数)です。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="BMI計算ツールと計算式の説明です。 身長と体重からBMIを計算することができます。BMI(Body Mass Index)は、体重と身長から算出する肥満度を表す体格指数(栄養指数)です。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div>

        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TextField
              id="income"
              label="身長(cm)を入力"
              type="number"
              name="income"
              defaultValue={incomeValue}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              inputRef={register({ required: true, maxLength: 20 })}
              variant="outlined"
            />
          </div>
          <br />
          <div>
            <TextField
              id="saving"
              label="体重(kg)を入力"
              type="number"
              name="saving"
              defaultValue={savingValue}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              inputRef={register({ required: true, maxLength: 20 })}
              variant="outlined"
            />
          </div>
        
          <br /> 

          <div>
          <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
              計算する
            </Button>
          </div>

          <br />
        
        </form>

        <div id="chart">
          {resultElement}
        </div>
      </div>

      <AccordionInstruction title="使い方">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            BMI計算ツールです。<br />
            BMI(Body Mass Index)は、体重と身長から算出する肥満度を表す体格指数(栄養指数)です。<br />
            身長と体重を入力してBMI値を計算することができます。<br />
            <br />
            身長と体重を入力して「計算する」ボタンを押すと、BMIと適正体重が計算されます。<br />
            BMIは以下の計算式で求めることができます。<br />
            <br />
            　BMI ＝ 体重kg ÷ (身長m)<sup>2</sup><br />
            <br />
            ※以下は厚生労働省、e-ヘルスネットからの転載です。<br />
            計算方法は世界共通ですが、肥満の判定基準は国によって異なり、WHO（世界保健機構）の基準では30以上を”Obese”（肥満）としています。<br />
            日本肥満学会の定めた基準では18.5未満が「低体重（やせ）」、18.5以上25未満が「普通体重」、25以上が「肥満」で、肥満はその度合いによってさらに「肥満1」から「肥満4」に分類されます。<br />
            <br />
            関連リンク<br />
            ⇒<Link href="/calculator/healthcare/basal-metabolic-rate-calculator">BMR計算</Link><br />
            ⇒<Link href="/calculator/healthcare/expected-life-at-age-calculator">平均余命計算</Link><br />
            <br />
            <Link target="_blank" href="https://www.e-healthnet.mhlw.go.jp/information/dictionary/metabolic/ym-002.html" rel="noopener noreferrer">BMI（BMI）. e-ヘルスネット.</Link> 厚生労働省. (2021)<br />
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default BmiCalculator;
