import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../../components/AccordionInstruction';
import AdSense from 'react-adsense';
import { BigNumber } from 'bignumber.js';
import ReactGA from "react-ga4";


const locationPath = "/calculator/converter/celsius-to-fahrenheit-converter";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "摂氏⇔華氏換算";
const description = "摂氏⇔華氏の換算ツールです。温度表記には摂氏と華氏の2種類があります。摂氏とは摂氏温度のことで水の氷点（凝固点）が0度、沸点が100度となる温度の尺度です。日本では主にこの摂氏(℃)が使われています。華氏とは華氏温度のことで水の氷点（凝固点）を32度、沸点が212度となる温度の尺度です。";

let resultValueKilometer = "";
let resultValueMile = "";
let inputValueKilometer = "";
let inputValueMile = "";
let resultElementKilometer = "";
let resultElementMile = "";
const param1 = BigNumber(1.8);
const param2 = BigNumber(32);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  indent: {
    textIndent: '1em',
  },
}));

function setFormDataCentimeter(formValues) {
  inputValueKilometer = BigNumber(formValues.centimeterValue);
  resultValueKilometer = inputValueKilometer.multipliedBy(param1);
  resultValueKilometer = resultValueKilometer.plus(param2);
}

function setFormDataInch(formValues) {
  inputValueMile = BigNumber(formValues.inchValue);
  resultValueMile = inputValueMile.minus(param2);
  resultValueMile = resultValueMile.dividedBy(param1);
}

function CelsiusToFahrenheitConverter() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { register, handleSubmit } = useForm();
  const onSubmitCentimeter = (data) => setFormDataCentimeter(data)
  const onSubmitInch = (data) => setFormDataInch(data)
  
  if(resultValueKilometer === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElementKilometer = 
    <div>
      <TextField
        id="result"
        label="華氏(℉)"
        defaultValue={resultValueKilometer}
        value={Number(resultValueKilometer).toLocaleString()}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
    </div>;
  }

  if(resultValueMile === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElementMile = 
    <div>
      <TextField
        id="result"
        label="摂氏(℃)"
        defaultValue={resultValueMile}
        value={Number(resultValueMile).toLocaleString()}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
    </div>;
  }

  return (
    <div>
            
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="摂氏から華氏" {...a11yProps(0)} />
          <Tab label="華氏から摂氏" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>

          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitCentimeter)}>
            <div>
              <TextField
                id="gramValue"
                label="摂氏(℃)を入力"
                type="number"
                name="centimeterValue"
                endAdornment={<InputAdornment position="end">℃</InputAdornment>}
                defaultValue={inputValueKilometer}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                inputRef={register({ required: true, maxLength: 20 })}
                variant="outlined"
              />
            </div>
          
            <br /> 

            <div>
            <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
                換算する
              </Button>
            </div>

            <br />
          
          </form>

          <div id="chart">
            {resultElementKilometer}
          </div>



      </TabPanel>
      <TabPanel value={value} index={1}>
        
        
            <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitInch)}>
              <div>
                <TextField
                  id="gramValue"
                  label="華氏(℉)を入力"
                  type="number"
                  name="inchValue"
                  endAdornment={<InputAdornment position="end">℉</InputAdornment>}
                  defaultValue={inputValueMile}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  inputRef={register({ required: true, maxLength: 20 })}
                  variant="outlined"
                />
              </div>
            
              <br /> 

              <div>
              <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
                  換算する
                </Button>
              </div>

              <br />
            
            </form>

            <div id="chart">
              {resultElementMile}
            </div>
      </TabPanel>
      </div>

      <AccordionInstruction title="説明">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            摂氏⇔華氏の換算ツールです。温度表記には摂氏と華氏の2種類があります。<br />
            摂氏とは摂氏温度のことで水の氷点（凝固点）が0度、沸点が100度となる温度の尺度です。日本では主にこの摂氏(℃)が使われています。<br />
            華氏とは華氏温度のことで水の氷点（凝固点）を32度、沸点が212度となる温度の尺度です。<br />
            <br />
            摂氏から華氏、華氏から摂氏への換算の計算式は以下になります。<br />
            <p className={classes.indent}>摂氏⇒華氏: 〇℃ × {param1.toLocaleString()} + {param2.toLocaleString()} = △℉</p>
            <p className={classes.indent}>華氏⇒摂氏: (△℉ - {param2.toLocaleString()}) ÷ {param1.toLocaleString()} = 〇℃</p>
            <br />
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default CelsiusToFahrenheitConverter;
