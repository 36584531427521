import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    ReferenceLine,
    ResponsiveContainer,
  } from 'recharts';

export default function StackedBarChart(props) {

    let data = [
        {
          name: props.label1,
          A: props.totalNumber,
          B: props.valueMain,
        },
      ];

    return (
        <ResponsiveContainer width="100%" height={360}>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                  top: 25,
                  right: 30,
                  left: 20,
                  bottom: 5,
              }}
              >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <ReferenceLine y={0} stroke="#000" />
              <Bar dataKey="A" stackId="a" fill="#8884d8" label={{ fill: 'black', fontSize: 20 }} />
              <Bar dataKey="B" stackId="a" fill="#82ca9d" />
            </BarChart>
        </ResponsiveContainer>
    );
}
