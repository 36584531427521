import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import { Chessboard } from "react-chessboard";
import ReactGA from "react-ga4";


const locationPath = "/game/chessboard";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "チェスボード";


function SimpleChessboard() {
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="チェスボードです。自由に動かすことができるので、二人で対戦できます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="チェスボードです。自由に動かすことができるので、二人で対戦できます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div>
        <Chessboard id="BasicBoard"
          boardWidth={360}
        />
      </div>

       <br />

      <AccordionInstruction title="説明">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            チェスボードです。<br />
            自由に動かすことができるので、二人で対戦できます。<br />
            <br />
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default SimpleChessboard;
