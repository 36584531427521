import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  HatenaShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LineIcon,
  HatenaIcon,
  EmailIcon,
} from 'react-share';
//import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  sideMargin: {
    display: 'inline-block',
    marginRight: '10px',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

const iconSize = 32;

export default function SnsShareButtons(props) {

  const classes = useStyles();

  return (
    <div>
        {/**<Typography variant="body2" gutterBottom>＼このページのリンクをシェアする／</Typography>-->*/}
        <FacebookShareButton className={classes.sideMargin} url={props.fullPath} quote={props.title} hashtag={"#" + props.hashtag[0]}>
          <FacebookIcon size={iconSize} round={true} />
        </FacebookShareButton>
        <TwitterShareButton className={classes.sideMargin} url={props.fullPath} title={props.title} hashtags={props.hashtag}>
          <TwitterIcon size={iconSize} round={true} />
        </TwitterShareButton>
        <LineShareButton className={classes.sideMargin} url={props.fullPath} title={props.title}>
          <LineIcon size={iconSize} round={true} />
        </LineShareButton>
        <HatenaShareButton className={classes.sideMargin} url={props.fullPath} title={props.title}>
          <HatenaIcon size={iconSize} round={true} />
        </HatenaShareButton>
        <EmailShareButton className={classes.sideMargin} url={props.fullPath} subject={props.title}>
          <EmailIcon size={iconSize} round={true} />
        </EmailShareButton>
      </div>
  );
}
