import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import "react-color-palette/lib/css/styles.css";
import "react-color-palette/lib/css/styles.css";
import ReactGA from "react-ga4";
import Link from '@material-ui/core/Link';

const locationPath = "/tools/sketch-canvas";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "お絵描きサイト";

function SketchCanvas() {
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="お絵描きサイトです。ブラウザ上のキャンバスに自由に絵を描くことができます。書いた絵は画像として保存できます。キャンバスの幅、高さ、ペンサイズ、消しゴムサイズ、背景画像を設定できます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="お絵描きサイトです。ブラウザ上のキャンバスに自由に絵を描くことができます。書いた絵は画像として保存できます。キャンバスの幅、高さ、ペンサイズ、消しゴムサイズ、背景画像を設定できます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <Typography variant="body2" gutterBottom>
            このページは以下のサイトへ移動しました。新サイト<Link href="https://www.oekaki.site/sketch-canvas" target="_blank" rel="noreferrer"><b>おえかきサイト</b></Link>でお楽しみください。<p />
            おえかきサイト：<Link href="https://www.oekaki.site/sketch-canvas" target="_blank" rel="noreferrer">https://www.oekaki.site/sketch-canvas</Link><p /><br />
        </Typography>

    </div>
  );

}

export default SketchCanvas;
