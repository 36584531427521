import format from "date-fns/format";
import DateFnsUtils from '@date-io/date-fns';

export default class DatePickerFormatter extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "yyyy MMM", { locale: this.locale });
  }
  getDatePickerHeaderText(date) {
    return format(date, "MMMd日", { locale: this.locale });
  }
}