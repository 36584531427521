import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
//import AdSense from 'react-adsense';
import { BigNumber } from 'bignumber.js';
import Link from '@material-ui/core/Link';
import SnsShareButtons from '../../../components/sns/SnsShareButtons';
import AdSense from 'react-adsense';
import ReactGA from "react-ga4";


const locationPath = "/calculator/finance/expected-asset-amount-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "期待資産額計算ツールと蓄財優等生と蓄財劣等生の判定";

let resultValue = "";
let inputValue1 = "";
let inputValue2 = "";
let resultElement;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    marginTop: '20px',
  },
}));

function setFormData(formValues) {
    inputValue1 = BigNumber(formValues.input1);
    inputValue2 = BigNumber(formValues.input2);

    resultValue = inputValue1.multipliedBy(inputValue2).dividedBy(10).toFormat();
}

function ExpectedAssetAmountCalculator() {
  
  useEffect(() => {
    
    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setFormData(data)
  
  if(resultValue === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElement = 
    <div>
      <TextField
        id="result"
        label="期待資産額(万円)"
        defaultValue={resultValue}
        value={resultValue}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
      <br /><br />
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="期待資産額の計算ツールです。トマス・J・スタンリー「となりの億万長者」に蓄財優等生と蓄財劣等生という言葉が出てきます。年齢と年収から期待資産額を求めて、それ以上であれば蓄財優等生、それより少なければ蓄財劣等生であると定義しています。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="期待資産額の計算ツールです。トマス・J・スタンリー「となりの億万長者」に蓄財優等生と蓄財劣等生という言葉が出てきます。年齢と年収から期待資産額を求めて、それ以上であれば蓄財優等生、それより少なければ蓄財劣等生であると定義しています。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div className={classes.snsButtonMargin}>
        <SnsShareButtons fullPath={fullPath} title="期待資産額計算ツールと蓄財優等生と蓄財劣等生の判定" hashtag={["期待資産額"]} />
      </div>

      <div>

        <AdSense.Google
          client='ca-pub-6460385026549088'
          slot='9028809602'
          style={{ display: 'block' }}
          format='auto'
          responsive='true'
          layoutKey='-gw-1+2a-9x+5c'
        />

        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TextField
              id="input1"
              label="年齢を入力"
              type="number"
              name="input1"
              defaultValue={inputValue1}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              inputRef={register({ required: true, maxLength: 3 })}
              variant="outlined"
            />
          </div>
          <br />
          <div>
            <TextField
              id="input2"
              label="税引前年収(万円)を入力"
              type="number"
              name="input2"
              defaultValue={inputValue2}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              inputRef={register({ required: true, maxLength: 20 })}
              variant="outlined"
            />
          </div>
        
          <br /> 

          <div>
          <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
              計算する
            </Button>
          </div>

          <br />
        
        </form>

        <div id="chart">
          {resultElement}
        </div>
      </div>

      <AccordionInstruction title="説明">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            期待資産額の計算ツールです。<br />
            トマス・J・スタンリー「<Link target="_blank" href="https://www.amazon.co.jp/gp/product/4152093927/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=4152093927&linkCode=as2&tag=tomniamazon-22&linkId=b51314ecfa7bdea84cf2341dee872876" rel="noopener noreferrer">となりの億万長者 ― 成功を生む7つの法則</Link>」に蓄財優等生と蓄財劣等生という言葉が出てきます。<br />
            年齢と年収から期待資産額を求めて、それ以上であれば蓄財優等生、それより少なければ蓄財劣等生であると定義しています。<br />
            <br />
            期待資産額を求める式はシンプルです。<br />
            <br />
            期待資産額 = 年齢 × 税引前年収 ÷ 10<br />
            <br />
            関連リンク<br />
            ⇒<Link href="/calculator/finance/fire-simulator">FIREシミュレーター</Link><br />
            ⇒<Link href="/calculator/finance/barista-fire-calculator">サイドFIREシミュレーター</Link><br />
            ⇒<Link href="/calculator/finance/compound-interest-calculator">複利計算</Link><br />
            ⇒<Link href="/calculator/finance/savings-rate-calculator">貯蓄率計算</Link><br />
            ⇒<Link href="/calculator/healthcare/expected-life-at-age-calculator">平均余命計算</Link><br />
            ⇒<Link href="/calculator/finance/human-life-value-calculator">生命価値計算</Link>
          </Typography>
        </Box>
      </ AccordionInstruction>
      
      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />

    </div>
  );

}

export default ExpectedAssetAmountCalculator;
