import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../../components/AccordionInstruction';
import AdSense from 'react-adsense';
import SnsShareButtons from '../../../components/sns/SnsShareButtons';
import ReactGA from "react-ga4";


const locationPath = "/calculator/converter/gram-to-ounce-converter";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "オンス⇔グラム換算";

let resultValueGram = "";
let resultValueOunce = "";
let inputValueGram = "";
let inputValueOunce = "";
let resultElementGram;
let resultElementOunce;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function setFormDataGram(formValues) {
inputValueGram = formValues.gramValue;
  resultValueGram = inputValueGram * 0.035274;
}

function setFormDataOunce(formValues) {
  inputValueOunce = formValues.ounceValue;
  resultValueOunce = inputValueOunce * 28.349;
}

function GramToOunceConverter() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { register, handleSubmit } = useForm();
  const onSubmitGram = (data) => setFormDataGram(data)
  const onSubmitOunce = (data) => setFormDataOunce(data)
  
  if(resultValueGram === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElementGram = 
    <div>
      <TextField
        id="result"
        label="オンス"
        defaultValue={resultValueGram}
        value={resultValueGram}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
    </div>;
  }

  if(resultValueOunce === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElementOunce = 
    <div>
      <TextField
        id="result"
        label="グラム"
        defaultValue={resultValueOunce}
        value={resultValueOunce}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
    </div>;
  }

  return (
    <div>
            
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="オンスとグラムの換算ツールです。オンスからグラムへ換算、グラムからオンスへ換算することができます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="オンスとグラムの換算ツールです。オンスからグラムへ換算、グラムからオンスへ換算することができます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="グラムからオンス" {...a11yProps(0)} />
          <Tab label="オンスからグラム" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>

          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitGram)}>
            <div>
              <TextField
                id="gramValue"
                label="グラムを入力"
                type="number"
                name="gramValue"
                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                defaultValue={inputValueGram}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                inputRef={register({ required: true, maxLength: 20 })}
                variant="outlined"
              />
            </div>
          
            <br /> 

            <div>
            <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
                換算する
              </Button>
            </div>

            <br />
          
          </form>

          <div id="chart">
            {resultElementGram}
          </div>
      </TabPanel>

      <TabPanel value={value} index={1}>
        
        
            <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitOunce)}>
              <div>
                <TextField
                  id="ounceValue"
                  label="オンスを入力"
                  type="number"
                  name="ounceValue"
                  endAdornment={<InputAdornment position="end">oz</InputAdornment>}
                  defaultValue={inputValueOunce}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  inputRef={register({ required: true, maxLength: 20 })}
                  variant="outlined"
                />
              </div>
            
              <br /> 

              <div>
              <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
                  換算する
                </Button>
              </div>

              <br />
            
            </form>

            <div id="chart">
              {resultElementOunce}
            </div>
      </TabPanel>

      <div className={classes.snsButtonMargin}>
        <SnsShareButtons fullPath={fullPath} title="オンス⇔グラム換算" hashtag={["オンスグラム換算"]} />
      </div>

      </div>

      <AccordionInstruction title="説明">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            オンスとグラムの換算ツールです。<br />
            オンスからグラムへ換算、グラムからオンスへ換算することができます。<br />
            オンスは「ヤード・ポンド法」で質量の単位を表しており記号は「oz」です。<br />
            グラムは「メートル法」で質量の単位を表しており記号は「g」です。日本では日常ではグラムが使われています。<br />
            1オンスは28.349グラムに相当します。
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default GramToOunceConverter;
