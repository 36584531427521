import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import ReactGA from "react-ga4";


const locationPath = "/about";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "サイトについて";

function AboutPage() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);

  return (

      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content="サイトについて" />
          <meta property="og:url" content={fullPath} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content="サイトについて" />
          <meta property="og:site_name" content="Life-Util" />
        </Helmet>

        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" component="h2">
            学びや生活に役立つモノを作りたくてこのサイトを運営しています。<br />
            <br />
            ITの仕事やライフハックについてブログを書いています。<br />
            外部リンク：
            <Link href="https://www.tatsu-don.com" target="_blank" rel="noopener noreferrer">
              Tatsu-don's Blog
            </Link>
          </Typography>
        </Box>
      </div>

  );
}

export default AboutPage;
