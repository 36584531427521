import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import { BigNumber } from 'bignumber.js';
import AdSense from 'react-adsense';
import Paper from '@material-ui/core/Paper';
import SimplePieChart from '../../../components/chart/SimplePieChart';
import SimpleBarChart from '../../../components/chart/SimpleBarChart';
import StackedBarChart from '../../../components/chart/StackedBarChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import SnsShareButtons from '../../../components/sns/SnsShareButtons';
import ReactGA from "react-ga4";


const locationPath = "/calculator/math/percentage-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "パーセント計算";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    backgroundColor: '#ffffff',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
  },
  paper: {
  },
  chartLabel: {
    textAlign: 'left',
    margin: '20px',
    paddingTop: '15px',
  },
  chartStyle: {
    padding: 15,
    maxWidth: '800px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  margin: {
    marginTop: '0px',
    marginBottom: '0px',
  }
}));

const mode0 = "AのB%は？";
const mode1 = "AはBの何%？";
const mode2 = "AからBへの増減率は？";
const mode3 = "AがB%の場合、全体は？";
const mode4 = "AのB%引きは？";
const mode5 = "AのB%増しは？";

let resultElement, valueA, valueB, resultValue;
let resultData = "";
let modeType = mode0;
let chartData1, chartData2, chartData3;

function setResultData(formValues) {

  //console.log("setCharData!");
  
  modeType = formValues.modeType;
  valueA = BigNumber(formValues.valueA);
  valueB = BigNumber(formValues.valueB);
  resultValue = 0;
  let resultStr = "";
  let percentageRemaining;
  const messageForNoChart = <Typography variant="body2" gutterBottom>円グラフを表示できません。</Typography>;
  const messageForNoStackedBarChart = <Typography variant="body2" gutterBottom>帯グラフを表示できません。</Typography>;

  if (modeType === mode0) {
    resultValue = valueA.multipliedBy(valueB).dividedBy(100);
    resultStr = valueA + "の" + valueB + "%は、" + resultValue.toFormat() + "です。";

    percentageRemaining = BigNumber(100).minus(valueB).toNumber();
    if (valueB.isGreaterThanOrEqualTo(0) && valueB.isLessThanOrEqualTo(100)) {
      chartData1 = <SimplePieChart totalNumber={valueA.toNumber()} percentageMain={valueB.toNumber()} valueMain={resultValue.toNumber()} percentageRemaining={percentageRemaining} label1={resultStr} label2={percentageRemaining + "%"} />;
      chartData3 = <StackedBarChart totalNumber={resultValue.toNumber()} percentageMain={valueB.toNumber()} valueMain={valueA.toNumber() - resultValue.toNumber()} percentageRemaining={percentageRemaining} label1={resultStr} label2={percentageRemaining + "%"} />;
    } else {
      chartData1 = messageForNoChart;
      chartData3 = messageForNoStackedBarChart;
    }

    chartData2 = <SimpleBarChart totalNumber={valueA.toNumber()} percentageMain={valueB.toNumber() + "%"} valueMain={resultValue.toNumber()} nameValue="A" />;

  } else if (modeType === mode1) {
    resultValue = valueA.dividedBy(valueB).multipliedBy(100);
    resultStr = valueA + "は" + valueB + "の、" + resultValue.toFormat() + "%です。";

    percentageRemaining = BigNumber(100).minus(resultValue).toNumber();
    if (resultValue.isGreaterThanOrEqualTo(0) && resultValue.isLessThanOrEqualTo(100)) {
      chartData1 = <div>
                    <SimplePieChart totalNumber={valueB.toNumber()} percentageMain={resultValue.toNumber()} valueMain={valueA.toNumber()}  percentageRemaining={percentageRemaining} label1={valueA + "は" + resultValue.toFormat() + "%"} label2={percentageRemaining + "%"} />
                    <Typography variant="body2" gutterBottom>全体は{valueB.toNumber()}</Typography>
                  </div>;

      chartData3 = <div>
      <StackedBarChart totalNumber={valueA.toNumber()} percentageMain={resultValue.toNumber()} valueMain={valueB.toNumber() - valueA.toNumber()}  percentageRemaining={percentageRemaining} label1={valueA + "は" + resultValue.toFormat() + "%"} label2={percentageRemaining + "%"} />
      <Typography variant="body2" gutterBottom>全体は{valueB.toNumber()}</Typography>
      </div>;

    } else {
      chartData1 = messageForNoChart;
      chartData3 = messageForNoStackedBarChart;
    }

    chartData2 = <SimpleBarChart totalNumber={valueB.toNumber()} percentageMain={resultValue.toNumber() + "%"} valueMain={valueA.toNumber()} nameValue="B" />;

  } else if (modeType === mode2) {
    resultValue = valueB.dividedBy(valueA).minus(1).multipliedBy(100);
    resultStr = valueA + "から" + valueB + "の増減率は、" + resultValue.toFormat() + "%です。";

    chartData1 = messageForNoChart;
    chartData3 = messageForNoStackedBarChart;

    chartData2 = <SimpleBarChart totalNumber={valueA.toNumber()} percentageMain={resultValue.toNumber() + "%"} valueMain={valueB.toNumber()} nameValue="A" />;

  } else if (modeType === mode3) {
    resultValue = valueA.dividedBy(valueB).multipliedBy(100);
    resultStr = valueA + "が" + valueB + "%の場合、全体は" + resultValue.toFormat() + "です。";

    percentageRemaining = BigNumber(100).minus(valueB).toNumber();
    if (valueB.isGreaterThanOrEqualTo(0) && valueB.isLessThanOrEqualTo(100)) {
      chartData1 = <div>
                    <SimplePieChart totalNumber={resultValue.toNumber()} percentageMain={valueB.toNumber()} valueMain={valueA.toNumber()} percentageRemaining={percentageRemaining} label1={valueA + "は" + valueB + "%"} label2={percentageRemaining + "%"} />
                    <Typography variant="body2" gutterBottom>全体は{resultValue.toNumber()}</Typography>
                  </div>;

      chartData3 = <div>
      <StackedBarChart totalNumber={valueA.toNumber()} percentageMain={valueB.toNumber()} valueMain={resultValue.toNumber() - valueA.toNumber()} percentageRemaining={percentageRemaining} label1={valueA + "は" + valueB + "%"} label2={percentageRemaining + "%"} />
      <Typography variant="body2" gutterBottom>全体は{resultValue.toNumber()}</Typography>
      </div>;

    } else {
      chartData1 = messageForNoChart;
      chartData3 = messageForNoStackedBarChart;
    }

    chartData2 = <SimpleBarChart totalNumber={valueA.toNumber()} percentageMain="全体 (100%)" valueMain={resultValue.toNumber()} nameValue={"A (" + valueB.toNumber() + "%)"} />;

  } else if (modeType === mode4) {
    // modeType === mode4
    let paramA = BigNumber(100).minus(valueB);
    resultValue = valueA.multipliedBy(paramA).dividedBy(100);
    resultStr = valueA + "の" + valueB + "%引きは、" + resultValue.toFormat() + "です。";

    percentageRemaining = BigNumber(100).minus(valueB).toNumber();
    
    if (valueB.isGreaterThanOrEqualTo(0) && valueB.isLessThanOrEqualTo(100)) {
      chartData1 = <div>
                      <SimplePieChart totalNumber={valueB} percentageMain={percentageRemaining} valueMain={resultValue.toNumber()} percentageRemaining={valueB} label1={valueA + "の" + valueB + "%引きは" + resultValue} label2={valueA.minus(resultValue).toNumber()} />
                      <Typography variant="body2" gutterBottom>元の値は{valueA.toNumber()}</Typography>
                    </div>;

      chartData3 = <div>
      <StackedBarChart totalNumber={resultValue.toNumber()} percentageMain={percentageRemaining} valueMain={valueA - resultValue.toNumber()} percentageRemaining={valueB} label1={valueA + "の" + valueB + "%引きは" + resultValue} label2={valueA.minus(resultValue).toNumber()} />
      <Typography variant="body2" gutterBottom>元の値は{valueA.toNumber()}</Typography>
      </div>;

    } else {
      chartData1 = messageForNoChart;
      chartData3 = messageForNoStackedBarChart;
    }

    chartData2 = <SimpleBarChart totalNumber={valueA.toNumber()} percentageMain={valueB.toNumber() + "%引き"} valueMain={resultValue.toNumber()} nameValue="A" />;
  } else {
    // modeType === mode5
    let paramA = BigNumber(valueB);
    resultValue = valueA.multipliedBy(paramA).dividedBy(100).plus(valueA);
    resultStr = valueA + "の" + valueB + "%増しは、" + resultValue.toFormat() + "です。";

    percentageRemaining = BigNumber(valueB).toNumber();
    
    if (valueB.isGreaterThanOrEqualTo(0)) {
      chartData1 = <div>
                      <SimplePieChart totalNumber={resultValue} percentageMain={valueA.dividedBy(resultValue).multipliedBy(100).toNumber()} valueMain={valueA.toNumber()} percentageRemaining={resultValue.minus(valueA).dividedBy(resultValue).multipliedBy(100).toNumber()} label1={"元の値" + valueA.toNumber()} label2={valueB + "%増加分は" + resultValue.minus(valueA).toNumber()} />
                      <Typography variant="body2" gutterBottom>{valueA + "の" + valueB + "%増しは" + resultValue}</Typography>
                    </div>;

      chartData3 = <div>
      <StackedBarChart totalNumber={valueA} valueMain={resultValue - valueA} label1={"元の値" + valueA.toNumber()} />
      <Typography variant="body2" gutterBottom>{valueA + "の" + valueB + "%増しは" + resultValue}</Typography>
      </div>;

    } else {
      chartData1 = messageForNoChart;
      chartData3 = messageForNoStackedBarChart;
    }

    chartData2 = <SimpleBarChart totalNumber={valueA.toNumber()} percentageMain={valueB.toNumber() + "%増し"} valueMain={resultValue.toNumber()} nameValue="A" />;
  }

  //Important!
  resultData = resultStr;
}

function PercentageCalculator() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setResultData(data)
  const [value, setValue] = React.useState(modeType);

  const labelPieChart = <div className={classes.chartLabel}><Typography variant="subtitle1" gutterBottom><PieChartIcon />円グラフ</Typography></div>;
  const labelBarChart = <div className={classes.chartLabel}><Typography variant="subtitle1" gutterBottom><BarChartIcon />棒グラフ</Typography></div>;
  const labelStackedBarChart = <div className={classes.chartLabel}><Typography variant="subtitle1" gutterBottom><BarChartIcon />帯グラフ</Typography></div>;

  const handleChangeForModeType = (event) => {
    //console.log(event.target.value);
    modeType = event.target.value;
    setValue(event.target.value);
  };
  
  if(resultData === "") {


  } else {

    resultElement = 
    <div className={classes.chartStyle}>
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h6" component="body2" gutterBottom>{resultData}</Typography><br />
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        {labelPieChart}
        {chartData1}
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        {labelBarChart}
        {chartData2}
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        {labelStackedBarChart}
        {chartData3}
      </Paper>
      <br />
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={"パーセント計算です。" + mode0 + mode1 + mode2 + mode3 + mode4 + mode5 + "目的によって計算方法を選択して計算できます。計算結果をグラフで分かりやすく説明します。"} />
        <link rel="alternate" href="https://www.life-util.com/calculator/math/percentage-calculator" hreflang="ja"/>
        <link rel="alternate" href="https://www.life-util.com/en/calculator/math/percentage-calculator" hreflang="en" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={"パーセント計算です。" + mode0 + mode1 + mode2 + mode3 + mode4 + mode5 + "目的によって計算方法を選択して計算できます。計算結果をグラフで分かりやすく説明します。"} />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div className={classes.snsButtonMargin}>
        <SnsShareButtons fullPath={fullPath} title={title} hashtag={["パーセント計算", "割合計算"]} />
      </div>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='8215889996'
        style={{ display: 'block' }}
        format='auto'
        responsive='true'
        layoutKey='-gw-1+2a-9x+5c'
      />

      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

        <div>
          <FormControl component="fieldset">
            <RadioGroup aria-label="modeType" name="modeType" value={value} onChange={handleChangeForModeType}>
              <FormControlLabel value={mode0} control={<Radio />} label={mode0} inputRef={register({ required: true })}/>
              <FormControlLabel value={mode1} control={<Radio />} label={mode1} inputRef={register({ required: true })}/>
              <FormControlLabel value={mode2} control={<Radio />} label={mode2} inputRef={register({ required: true })}/>
              <FormControlLabel value={mode3} control={<Radio />} label={mode3} inputRef={register({ required: true })}/>
              <FormControlLabel value={mode4} control={<Radio />} label={mode4} inputRef={register({ required: true })}/>
              <FormControlLabel value={mode5} control={<Radio />} label={mode5} inputRef={register({ required: true })}/>
            </RadioGroup>
         </FormControl>

        </div>
       <div>
        <TextField
          id="valueA"
          label="Aを入力"
          type="number"
          name="valueA"
          defaultValue={valueA}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 20 })}
        />
      </div>

      <div>
        <TextField
          id="valueB"
          label="Bを入力"
          type="number"
          name="valueB"
          defaultValue={valueB}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 20 })}
        />
      </div>

      <div style={{ paddingTop: 15 }}>
       <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
          計算する
        </Button>

      </div>
      <AdSense.Google
          client='ca-pub-6460385026549088'
          slot='1389671621'
          style={{ display: 'block' }}
          layout='in-article'
          format='fluid'
        />
    </form>

    <div id="result">
      {resultElement}
    </div>

    <AccordionInstruction title="使い方">
      <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
        <Typography variant="body2" gutterBottom>
          パーセントの計算ができるツールです。{mode0}{mode1}{mode2}{mode3}{mode4}<br />
          目的によって計算方法を選んで簡単に計算できます。計算結果をグラフで表示して分かりやすく説明します。<br />
          <br />
          {mode0}<br />
          全体の〇〇パーセントはいくつかという計算は、 全体 × パーセント ÷ 100 で求めることができます。<br />
          <br />
          {mode1}<br />
          ある数は全体の何パーセントかという計算は ある数 ÷ 全体 × 100 で求めることができます。<br />
          <br />
          {mode2}<br />
          ある数がある数へ変わった場合の増減率は何パーセントかという計算は、変更後の数 ÷ 変更前の数 - 1 × 100 で求めることができます。<br />
          <br />
          {mode3}<br />
          全体はいくつかという計算は、 ある数 ÷ パーセント × 100 で求めることができます。<br />
          <br />
          {mode4}<br />
          ある数の〇〇%引きはいくつかという計算は、 ある数 × (100 - 割引率) ÷ 100 で求めることができます。<br />
          <br />
          {mode5}<br />
          ある数の〇〇%増しはいくつかという計算は、 ある数 × 100 ÷ 割増率 + ある数 で求めることができます。<br />
          <br />
          結果を円グラフ、棒グラフ、帯グラフで表示することができます。<br />
        </Typography>
      </Box>
    </ AccordionInstruction>

    <AdSense.Google
      client='ca-pub-6460385026549088'
      slot='3710473082'
      style={{ display: 'block' }}
      layout='in-article'
      format='fluid'
    />
    </div>
  );
}

export default PercentageCalculator;
