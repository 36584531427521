import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import { BigNumber } from 'bignumber.js';
import AdSense from 'react-adsense';
import Link from '@material-ui/core/Link';
import ReactGA from "react-ga4";


const locationPath = "/calculator/healthcare/expected-life-at-age-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "平均余命計算ツール";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    backgroundColor: '#ffffff',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
  },
}));

const mode0 = "男性";
const mode1 = "女性";

let resultElement, valueA;
let resultData = "";
let modeType = mode0;

function setResultData(formValues) {

  //console.log("setCharData!");
  
  modeType = formValues.modeType;
  valueA = BigNumber(formValues.valueA);
  let resultValue = 0;
  let ijou = ""
  const maleExpectedLifeAtAgeArry = ["81.41", "80.57", "79.59", "78.61", "77.62", "76.63", "75.63", "74.64", "73.65", "72.65", "71.66", "70.66", "69.67", "68.67", "67.68", "66.69", "65.70", "64.71", "63.73", "62.75", "61.77", "60.80", "59.83", "58.85", "57.88", "56.91", "55.93", "54.96", "53.98", "53.01", "52.03", "51.06", "50.09", "49.12", "48.15", "47.18", "46.21", "45.24", "44.28", "43.31", "42.35", "41.39", "40.43", "39.47", "38.52", "37.57", "36.62", "35.68", "34.74", "33.81", "32.89", "31.97", "31.05", "30.14", "29.24", "28.34", "27.45", "26.57", "25.70", "24.83", "23.97", "23.13", "22.29", "21.46", "20.64", "19.83", "19.03", "18.24", "17.47", "16.71", "15.96", "15.23", "14.51", "13.79", "13.09", "12.41", "11.73", "11.08", "10.43", "9.80", "9.18", "8.59", "8.02", "7.48", "6.95", "6.46", "5.99", "5.55", "5.14", "4.76", "4.41", "4.08", "3.76", "3.47", "3.19", "2.94", "2.70", "2.47", "2.26", "2.07", "1.89", "1.73", "1.58", "1.44", "1.31", "1.19"];
  const femaleExpectedLifeAtAgeArry = ["87.45", "86.60", "85.63", "84.64", "83.65", "82.66", "81.67", "80.68", "79.68", "78.69", "77.69", "76.69", "75.70", "74.70", "73.71", "72.72", "71.73", "70.73", "69.74", "68.76", "67.77", "66.78", "65.80", "64.81", "63.82", "62.84", "61.85", "60.86", "59.88", "58.89", "57.91", "56.92", "55.94", "54.96", "53.98", "53.00", "52.02", "51.04", "50.06", "49.08", "48.11", "47.14", "46.17", "45.20", "44.23", "43.26", "42.30", "41.34", "40.39", "39.44", "38.49", "37.54", "36.60", "35.66", "34.73", "33.79", "32.86", "31.93", "31.00", "30.08", "29.17", "28.25", "27.34", "26.43", "25.53", "24.63", "23.73", "22.84", "21.96", "21.08", "20.21", "19.35", "18.49", "17.64", "16.80", "15.97", "15.15", "14.34", "13.55", "12.77", "12.01", "11.26", "10.54", "9.84", "9.16", "8.51", "7.89", "7.30", "6.73", "6.21", "5.71", "5.24", "4.80", "4.39", "4.00", "3.64", "3.31", "3.02", "2.75", "2.51", "2.29", "2.09", "1.90", "1.74", "1.58", "1.45"];

  if (modeType === mode1) {
    if ( valueA > femaleExpectedLifeAtAgeArry.length - 1) {
      valueA = femaleExpectedLifeAtAgeArry.length - 1;
      ijou = "以上";
    }
    
    resultValue = femaleExpectedLifeAtAgeArry[valueA];

  } else {
    if ( valueA > maleExpectedLifeAtAgeArry.length - 1) {
      valueA = maleExpectedLifeAtAgeArry.length - 1;
      ijou = "以上";
    }
    resultValue = maleExpectedLifeAtAgeArry[valueA];
  }

  //Important!
  resultData = valueA + "歳" + ijou + modeType + "の平均余命は " + resultValue + " 年です。";
}

function ExpectedLifeAtAgeCalculator() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setResultData(data)
  const [value, setValue] = React.useState(modeType);

  const handleChangeForModeType = (event) => {
    //console.log(event.target.value);
    modeType = event.target.value;
    setValue(event.target.value);
  };
  
  if(resultData === "") {


  } else {

    resultElement = 
    <div style={{ width: '100%' }}>
      <Typography variant="h6" component="body2" gutterBottom>{resultData}</Typography>
      <br /><br />
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="性別と年齢を元に厚生労働省が公表する生命表データを参照して平均余命を表示します。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="性別と年齢を元に厚生労働省が公表する生命表データを参照して平均余命を表示します。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

        <div>
          <FormControl component="fieldset">
            <RadioGroup aria-label="modeType" name="modeType" value={value} onChange={handleChangeForModeType}>
              <FormControlLabel value={mode0} control={<Radio />} label={mode0} inputRef={register({ required: true })}/>
              <FormControlLabel value={mode1} control={<Radio />} label={mode1} inputRef={register({ required: true })}/>
            </RadioGroup>
         </FormControl>

        </div>
       <div>
        <TextField
          id="valueA"
          label="現在年齢を入力"
          type="number"
          name="valueA"
          defaultValue={valueA}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 3 })}
        />
      </div>

      <div style={{ padding: 15 }}>
       <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
          計算する
        </Button>
      </div>
      <br />
      
    </form>

    <div id="result">
      {resultElement}
    </div>

    <AccordionInstruction title="使い方">
      <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
        <Typography variant="body2" gutterBottom>
        平均余命を計算するツールです。<br />
        本ツールでは、性別と年齢を元に厚生労働省が公表する生命表データを参照して平均余命を表示します。<br />
        生命表は、各年齢毎に平均してあと何年生きられるかという期待値を平均余命などの指標によって表したものです。<br />
        <br />
        人生設計を考え始めると、自分があと何年生きられるのかが気になってきます。<br />
        寿命は人それぞれで正確に計算することはできません。<br />
        人口統計学により特定の年齢層・性別に対して、死亡率（誕生日から、次の誕生日までに死ぬ確率）や平均余命を導き出しています。<br />
        老後の資金計画やタイムバケットなどの人生設計に利用する目的で作成しました。<br />
        <br />
        ※令和元年簡易生命表を参照しています。<br />
        <br />
        関連リンク<br />
        ⇒<Link href="/calculator/healthcare/bmi-calculator">BMI計算</Link><br />
        ⇒<Link href="/calculator/healthcare/basal-metabolic-rate-calculator">BMR計算</Link><br />
        ⇒<Link href="/calculator/finance/fire-simulator">FIREシミュレーター</Link><br />
        ⇒<Link href="/calculator/finance/barista-fire-calculator">サイドFIREシミュレーター</Link><br />
        ⇒<Link href="/calculator/finance/compound-interest-calculator">複利計算</Link><br />
        ⇒<Link href="/calculator/finance/savings-rate-calculator">貯蓄率計算</Link><br />
        ⇒<Link href="/calculator/finance/expected-asset-amount-calculator">期待資産額計算</Link><br />
        <br />
        <Link target="_blank" href="https://www.mhlw.go.jp/toukei/list/list54-57.html" rel="noopener noreferrer">生命表（加工統計）</Link> 厚生労働省. (2021)
        </Typography>
      </Box>
    </ AccordionInstruction>

    <AdSense.Google
      client='ca-pub-6460385026549088'
      slot='3710473082'
      style={{ display: 'block' }}
      layout='in-article'
      format='fluid'
    />
    </div>
  );
}

export default ExpectedLifeAtAgeCalculator;
