import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  BarChart, Bar, Legend, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine,
} from 'recharts';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import AdSense from 'react-adsense';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SnsShareButtons from '../../../components/sns/SnsShareButtons';
import ReactGA from "react-ga4";

const locationPath = "/calculator/finance/fire-simulator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "FIREシミュレーター (FIRE計算)";
const description = "FIREとは経済的独立と早期リタイアのことで、Financial Independence, Retire Earlyの頭文字を取った言葉です。このページでは早期リタイアのためのFIRE計算のシミュレーションをすることができます。シミュレーション結果をグラフで表示します。";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    backgroundColor: '#ffffff',
    width: '100%',
  },
  table: {
  },
  margin: {
    marginTop: '0px',
    marginBottom: '0px',
  },
  margin_ads: {
    marginTop: '5px',
    marginBottom: '10px',
  },
}));

let chartElement, principal, monthly_deposit;
let finalAmount;
var chartData = [];
let rate, currentAge, retirementAge, yearlyExpenseGlobal, pensionStartAge, pensionStartAgeControled, yearlyExpenseAfterPensionStartGlobal;

function setChartData(formValues) {

  var newChartData = [];
  let yearly_deposit, interest, total;
  principal = Number(formValues.principal);
  monthly_deposit = Number(formValues.monthly_deposit);
  yearly_deposit = 0;
  rate = Number(formValues.rate);
  interest = 0;
  pensionStartAgeControled = Number(pensionStartAge);
  currentAge = Number(formValues.currentAge);
  retirementAge = Number(retirementAge);
  if (retirementAge < currentAge) {
    retirementAge = currentAge;
  }
  let yearlyExpense, yearlyExpenseAfterPensionStart;
  yearlyExpense = Number(formValues.yearlyExpense * 10000);
  yearlyExpenseGlobal = formValues.yearlyExpense;
  yearlyExpenseAfterPensionStart = Number(formValues.yearlyExpenseAfterPensionStart) * 10000;
  yearlyExpenseAfterPensionStartGlobal = formValues.yearlyExpenseAfterPensionStart;

  let principalAfterRetirement, yearlyDepositeAfterRetirement;
  let interestAfterRetirement = 0;

  let considerPension = formValues.considerPension;

  if (! considerPension) {
    pensionStartAgeControled = "";
  }

  for (let i = 0; i + currentAge <= 110; i++) {

    //Consider pension age
    if (considerPension && i + currentAge >= pensionStartAgeControled) {
      //Replace yearly expense amount
      yearlyExpense = yearlyExpenseAfterPensionStart;
    }
    
    if (i + currentAge <= retirementAge) {
      yearly_deposit = yearly_deposit + (Number(formValues.monthly_deposit * 10000) * 12);
      interest = ((principal * 10000 + yearly_deposit + interest) * (rate / 100)) + interest; 
      total = (principal * 10000) + yearly_deposit + interest;
      
      principalAfterRetirement = principal * 10000;
      yearlyDepositeAfterRetirement = yearly_deposit;
      interestAfterRetirement = Math.round(interest);
      
      newChartData[i] = {name: currentAge + i + "歳", 元金: principal * 10000, 毎月積立額: yearly_deposit, リターン: Math.round(interest), 合計: Math.round(total)};
      
      finalAmount = principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement;

    } else {
      
      let totalAmount = principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement;

      if ((yearlyDepositeAfterRetirement + interestAfterRetirement) < yearlyExpense) {
        principalAfterRetirement = totalAmount - yearlyExpense;
        yearlyDepositeAfterRetirement = 0;

        if (principalAfterRetirement < 0) {
          interestAfterRetirement = 0;
        } else {
          interestAfterRetirement = (Math.round((principalAfterRetirement) * (rate / 100))); 
        }
      } else if (interestAfterRetirement < yearlyExpense) {
        yearlyDepositeAfterRetirement = yearlyDepositeAfterRetirement + interestAfterRetirement - yearlyExpense;
        interestAfterRetirement = 0;
        interestAfterRetirement = interestAfterRetirement + (Math.round((principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement) * (rate / 100)));
      } else {
        interestAfterRetirement = interestAfterRetirement - yearlyExpense;
        interestAfterRetirement = interestAfterRetirement + (Math.round((principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement) * (rate / 100)));
      }
      
      newChartData[i] = {name: currentAge + i + "歳", 元金: principalAfterRetirement, 毎月積立額: yearlyDepositeAfterRetirement, リターン: interestAfterRetirement, 合計: principalAfterRetirement + yearlyDepositeAfterRetirement + interestAfterRetirement};
    }
  }

  //Important!
  chartData = newChartData;

}

function valuetext(value) {
  retirementAge = value;
  return `${value}`;
}

function valuetextPensionStartAge(value) {
  pensionStartAge = value;
  return `${value}`;
}

function FireCalculator() {

  useEffect(() => {
    
    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);

  const [state, setState] = React.useState({
    considerPension: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setChartData(data)
  
  if(chartData.length === 0) {

    rate = 4;
    yearlyExpenseGlobal = 300;
    retirementAge = 35;
    yearlyExpenseAfterPensionStartGlobal = 150;
    pensionStartAge = 65;

  } else {

    chartElement = 
    <div>
      <br />
      <div style={{ width: '100%'}}>
        <TextField
          id="outlined-read-only-input"
          label="リタイア時の積立金額"
          defaultValue={finalAmount.toLocaleString()}
          value={finalAmount.toLocaleString()}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      </div>

      <div style={{ width: '800px', height: '450px', backgroundColor: '#FFFFFF', paddingLeft: '220px' }}>
        <ResponsiveContainer>
          <BarChart
            width="100%"
            height={300}
            data={chartData}
            margin={{
              top: 40, right: 30, left: 30, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={tick => {
                return (tick / 10000).toLocaleString() + "万円";
            }}/>
            <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)}/>
            <Legend />
            <Bar dataKey="元金" stackId="a" fill="#8884d8" />
            <Bar dataKey="毎月積立額" stackId="a" fill="#82ca9d" />
            <Bar dataKey="リターン" stackId="a" fill="#ff7f50" />
            <ReferenceLine x={retirementAge + '歳'} stroke="red" label={{value:"リタイア年齢", fill:"red"}} />
            <ReferenceLine y={0} label="" stroke="red" />
            <ReferenceLine x={pensionStartAgeControled + '歳'} stroke="blue" label={{value:"年金受給開始年齢", position:"top", fill:"blue"}} />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div style={{ maxWidth:'720px', paddingLeft: '220px' }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>年齢</TableCell>
                <TableCell align="right">元金</TableCell>
                <TableCell align="right">積立金額</TableCell>
                <TableCell align="right">リターン</TableCell>
                <TableCell align="right">合計</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chartData.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row" align="right">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{Number(row.元金).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.毎月積立額).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.リターン).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.合計).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  }

  return (
    <div className="App-header">
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="alternate" href="https://www.life-util.com/calculator/finance/fire-simulator" hreflang="ja"/>
        <link rel="alternate" href="https://www.life-util.com/en/calculator/finance/simple-fire-calculator" hreflang="en" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="FIREシミュレーター (FIRE計算)" />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div>
        <SnsShareButtons fullPath={fullPath} title={title} hashtag={["FIREシミュレーター", "早期リタイヤ"]} />
      </div>

      <form name="form1" className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      
        <AdSense.Google
          client='ca-pub-6460385026549088'
          slot='4591505605'
          style={{ display: 'block' }}
          format='auto'
          responsive='true'
          layoutKey='-gw-1+2a-9x+5c'
        />

        <div>
          <TextField
            id="years"
            label="現在年齢(歳)"
            type="number"
            name="currentAge"
            defaultValue={currentAge}
            inputRef={register({ required: true, maxLength: 3 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>
        <div>
          <TextField
            id="principal"
            label="元金(万円)"
            type="number"
            name="principal"
            defaultValue={principal}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            inputRef={register({ required: true, maxLength: 20 })}
          />
        </div>
        <div>
          <TextField
            id="monthly-deposit"
            label="毎月積立額(万円) 例:3.33"
            type="number"
            name="monthly_deposit"
            defaultValue={monthly_deposit}
            inputRef={register({ required: true, maxLength: 20 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>
        <div>
          <TextField
            id="rate"
            label="実質リターン(%)"
            type="number"
            name="rate"
            defaultValue={rate}
            inputRef={register({ required: true, maxLength: 20 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>

        <div style={{width: '200px', marginLeft: 'auto', marginRight: 'auto'}}>
          <Typography id="discrete-slider-small-steps" gutterBottom className="MuiFormLabel-root MuiInputLabel-shrink ">
            リタイア年齢
          </Typography>
          <Slider
            name="retirementAge"
            defaultValue={retirementAge}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider-small-steps"
            step={1}
            marks
            min={30}
            max={100}
            valueLabelDisplay="on"
          />
        </div>

        <div>
          <TextField
            id="yearlyExpense"
            label="リタイア後の年間支出(万円)"
            type="number"
            name="yearlyExpense"
            defaultValue={yearlyExpenseGlobal}
            inputRef={register({ required: false, maxLength: 20 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>

        <div>
        <Accordion expanded={state.considerPension} style={{maxWidth:"300px", marginLeft: 'auto', marginRight: 'auto'}}>
          <AccordionSummary
            /*expandIcon={<ExpandMoreIcon />}*/
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >

            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={<Checkbox  checked={state.considerPension} onChange={handleChange} name="considerPension" color="primary" 
              inputRef={register({ required: false})}/>}
              label="年金情報を入力する"
            />

          </AccordionSummary>

          <AccordionDetails>
            
            <div style={{width: '200px', marginLeft: 'auto', marginRight: 'auto'}}>
              <Typography id="discrete-slider-small-steps" gutterBottom className="MuiFormLabel-root MuiInputLabel-shrink">
                年金受給開始年齢
              </Typography>
              <Slider
                name="pensionStartAge"
                defaultValue={pensionStartAge}
                getAriaValueText={valuetextPensionStartAge}
                aria-labelledby="discrete-slider-small-steps"
                step={1}
                marks
                min={60}
                max={75}
                valueLabelDisplay="on"
              />

              <TextField
                id="yearlyExpense"
                label="年金受給開始後の年間支出(万円)"
                type="number"
                name="yearlyExpenseAfterPensionStart"
                defaultValue={yearlyExpenseAfterPensionStartGlobal}
                inputRef={register({ required: false, maxLength: 20 })}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </div>

          </AccordionDetails>
        </Accordion>

        </div>

        <div>
          <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
            計算する
          </Button>
          <AdSense.Google
            client='ca-pub-6460385026549088'
            slot='4235679126'
            style={{ display: 'block' }}
            layout='in-article'
            format='fluid'
          />
        </div>
      </form>

      <div id="chart">
        {chartElement}
      </div>

      <div>
        <AccordionInstruction title="説明">
          <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
            <Typography variant="body2" gutterBottom>
              FIREとは経済的独立と早期リタイアのことでFinancial Independence, Retire Earlyの頭文字を取った言葉です。<br />
              このページでは早期リタイア(早期退職)のためのFIRE計算のシミュレーションをすることができます。<br />
              シミュレーションでの試算結果をグラフと表で表示します。<br />
              <br />
              米国では経済的独立と早期リタイアを目標とするライフスタイルが2010年代から大きな注目を集めて人気が高まり「FIREムーブメント」と呼ばれています。<br />
              <b>「4%ルール」</b>とは、年間支出の25倍の資産形成をして4%の運用益でリタイア後の支出をまかなうという考え方です。<br />
              <br />
              このツールではグラフで可視化することで、FIREの基本イメージを掴むことができます。<br />
              リタイヤ年齢までは複利の力で資産形成をします。リタイヤ年齢後は資産が生む利益と元金で逃げ切ります。<br />
              将来をシミュレーションをすることは単純ではありませんが、ツールの簡易性を優先して出来るだけシンプルに使えるようにしています。<br />
              FIRE戦略を考えながらリターンやリタイヤ年齢、支出を入力して計算してみましょう。<br />
              <br />

              参考になるFIRE関連の書籍も多く出版されています。一冊読んでみると更にイメージできるようになりますので是非読んでみてください。<br />
              <br />
              外部リンク：<Link target="_blank" href="https://amzn.to/3M1mf3w" rel="nofollow noopener noreferrer">Amazon.co.jp - FIRE関連書籍一覧</Link><br />
              <br />
              また、FIRE達成のためには貯蓄率が重要な指標だと言われます。これは支出と収入のバランスによって目標としたFIREが達成できるか判断できるためです。<br />
              貯蓄率を算出する<Link href="/calculator/finance/savings-rate-calculator">貯蓄率計算ツール</Link>も使ってみましょう。<br />
              <br />
              サイト内リンク<br />
              ⇒<Link href="/calculator/finance/barista-fire-calculator">サイドFIREシミュレーター</Link><br />
              ⇒<Link href="/calculator/finance/compound-interest-calculator">複利計算</Link><br />
              ⇒<Link href="/calculator/finance/savings-rate-calculator">貯蓄率計算</Link><br />
              ⇒<Link href="/calculator/finance/expected-asset-amount-calculator">期待資産額計算</Link><br />
              ⇒<Link href="/calculator/healthcare/expected-life-at-age-calculator">平均余命計算</Link><br />
              ⇒<Link href="/calculator/finance/human-life-value-calculator">生命価値計算</Link>
            </Typography>
          </Box>
        </ AccordionInstruction>
        
        <AdSense.Google
          client='ca-pub-6460385026549088'
          slot='3710473082'
          style={{ display: 'block' }}
          layout='in-article'
          format='fluid'
        />
          
      </div>

    </div>
  );
}

export default FireCalculator;
