import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

/*
let data01 = [
    {
      "name": "Group A",
      "value": 10
    },
    {
      "name": "Group B",
      "value": 20
    }
  ];
*/

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, label }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {label}
    </text>
  );
};

export default function SimplePieChart(props) {
  
  let data01 = [
    {
      "name": Number(props.totalNumber),
      "value": Number(props.percentageMain),
      "label": props.label1,
    },
    {
      "name": "",
      "value": Number(props.percentageRemaining),
      "label": props.label2,
    }
  ];

  return (
    <ResponsiveContainer height={150} width="100%">
      <PieChart width={730} height={250}>
        <Pie 
          data={data01} 
          dataKey="value" 
          nameKey="name" 
          cx="50%" 
          cy="50%" 
          outerRadius={50} 
          fill="#8884d8" 
          labelLine={false} 
          label={renderCustomizedLabel} 
          legendType="plainline"
          animationDuration={500}
        >
        {data01.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
