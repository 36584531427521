import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import "react-color-palette/lib/css/styles.css";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { BigNumber } from 'bignumber.js';
import TextField from '@material-ui/core/TextField';
import SnsShareButtons from '../../components/sns/SnsShareButtons';
import ReactGA from "react-ga4";


const locationPath = "/tools/character-counter";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "文字数カウント";
const description = "文字数カウントツールです。入力欄にキーボードで入力、または、コピー＆ペーストすることで文字数をカウントできます。本機能はJavascriptを利用していますが、絵文字なども書記素単位で正確に文字数をカウントできるよう対応しています。また、サーバーレスで実装しているため入力した情報が保存されることはありませんので安心してご利用いただけます。文字数カウントの条件を指定することができます。";

let strToCount;
let countResult = BigNumber(0);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    marginTop: '50px',
  },
  textareainput: {
    minWidth: '80%',
    marginBottom: '10px'
  },
  paperstyle: {
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(4),
    },    
  },
  paperchildstyle: {
    background: '#0c8bed',
    color: '#FFFFFF',
  },
  snsButtonMargin: {
    marginBottom: '15px',
  },
}));

function CharacterCounter() {
  
  const [value, setValue] = React.useState(0);
  const [state, setState] = React.useState({
    countCRLF: false,
  });
  let dummy = value;
  if(dummy === "");


  const countGrapheme = (string) => {
    const segmenter = new Intl.Segmenter("ja", { granularity: "grapheme" });
    return [...segmenter.segment(string)].length;
  }

  const calculate = (str) => {

    //console.log("countCRLF:" + document.getElementById("countCRLF").checked);

    if (!document.getElementById("countCRLF").checked) {
      //console.log("in if");
      strToCount = strToCount.replace(/\r?\n/g, '');
      setValue(strToCount);
    } else {
      //console.log("in else");
      strToCount = document.getElementById("textareainput").value;
      setValue(document.getElementById("textareainput").value);
    }
    //console.log(strToCount);
    
    //console.log([...strToCount].length);
    //countResult = BigNumber(strToCount.length);
    //countResult = BigNumber([...strToCount].length);
    countResult = BigNumber(countGrapheme(strToCount));
  }

  const handleChange = (event) => {
    //console.log("handleChange");
    strToCount = event.target.value;
    setValue(event.target.value);
    calculate(strToCount);
  };

  const handleConditionChange = (event) => {
    setState({ ...state, [event.target.name]: document.getElementById("countCRLF").checked });
    setValue(document.getElementById("textareainput").value);
    calculate(strToCount);
  };

  const handleCear = (event) => {
    //console.log("Clear!");
    setValue("");
    strToCount = "";
    countResult = BigNumber(0);
    document.getElementById("textareainput").value = "";
  }
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div className={classes.snsButtonMargin}>
        <SnsShareButtons fullPath={fullPath} title={title} hashtag={["文字数カウント"]} />
      </div>

      <div className={classes.paperstyle}>
        <Paper elevation={0}>
          <Typography variant="body1" component="h4" gutterBottom>文字数</Typography>
        </Paper>
        <Paper className={classes.paperchildstyle}>
          <Typography variant="h6" component="h4" gutterBottom>{countResult.toFormat()}</Typography>
        </Paper>
      </div>

      <form noValidate autoComplete="off">

        <div>
          <FormControlLabel
            control={
              <Switch
                id="countCRLF"
                checked={state.countCRLF}
                onChange={handleConditionChange}
                name="countCRLF"
                color="primary"
              />
            }
            label="改行をカウントする"
          />
  
          <IconButton aria-label="delete" className={classes.margin} onClick={handleCear}>
            <DeleteIcon fontSize="large" />
          </IconButton>
        </div>

        <div>
          <TextField 
           className={classes.textareainput}
            id="textareainput"
            label="入力欄"
            placeholder="ここに入力してください。"
            multiline
            variant="outlined"
            onChange={handleChange}
            rows={10}
          />
        </div>
        
      </form>

      <AccordionInstruction title="使い方">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            文字数カウントツールです。入力欄にキーボードで入力、または、コピー＆ペーストすることで文字数をカウントできます。ゴミ箱<DeleteIcon fontSize="small" />をクリックするとテキストフィールドをクリアされます。<p />
            本機能はJavascriptを利用していますが、🏴󠁧󠁢󠁥󠁮󠁧󠁿や🇯🇵などの絵文字も書記素単位で正確に文字数をカウントできるよう対応しています。<p />
            また、サーバーレスで実装しているため入力した情報が保存されることはありませんので安心してご利用いただけます。<p />
            文字数カウントの条件を指定することができます。<br />
            ・改行をカウントする<br />
            <p />
            
          </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );

}

export default CharacterCounter;
