import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import { BigNumber } from 'bignumber.js';
import ReactGA from "react-ga4";


const locationPath = "/calculator/converter/torque-converter";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "トルク換算";

const unit_n_m = "N・m";
const unit_n_cm = "N・cm";
const unit_kgf_m = "kgf・m";
const unit_kgf_cm = "kgf・cm";
const unit_lbf_ft = "lbf・ft";
const unit_lbf_in = "lbf・in";

const label_n_m = unit_n_m + " (ニュートンメートル)";
const label_n_cm = unit_n_cm + " (ニュートンセンチメートル)";
const label_kgf_m = unit_kgf_m + " (重量キログラムメートル)";
const label_kgf_cm = unit_kgf_cm + " (重量キログラムセンチメートル)";
const label_lbf_ft = unit_lbf_ft + " (重量ポンドフィート)";
const label_lbf_in = unit_lbf_in + " (重量ポンドインチ)";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    backgroundColor: '#ffffff',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
  },
  paper: {
    width: "fit-content",
    marginLeft: "auto",
    marginRight: "auto",
  },
  resultMargin: {
    marginTop: '5px',
    marginBottom: '10px',
  }
}));

let resultElement, inputValue;
let unit;

let n_m, n_cm, kgf_m, kgf_cm, lbf_in, lbf_ft;

function setResultData(formValues) {

  inputValue = BigNumber(formValues.inputValue);
  unit = formValues.unit;

  switch (unit) {
    case unit_n_m:
      n_m = inputValue.toFormat();
      n_cm = inputValue.multipliedBy(100).toFormat();
      kgf_m = inputValue.multipliedBy(0.102).toFormat();
      kgf_cm = inputValue.multipliedBy(10.197).toFormat();
      lbf_ft = inputValue.multipliedBy(0.738).toFormat();
      lbf_in = inputValue.multipliedBy(8.851).toFormat();
      break;
    case unit_n_cm:
      n_m = inputValue.dividedBy(100);
      n_cm = inputValue.toFormat();
      kgf_m = inputValue.multipliedBy(0.102).dividedBy(100).toFormat();
      kgf_cm = inputValue.multipliedBy(10.197).dividedBy(100).toFormat();
      lbf_ft = inputValue.multipliedBy(0.738).dividedBy(100).toFormat();
      lbf_in = inputValue.multipliedBy(8.851).dividedBy(100).toFormat();
      break;
    case unit_kgf_m:
      //n_m = inputValue.multipliedBy(9.807).toFormat();
      n_m = inputValue.multipliedBy(9.80665).toFormat();
      //n_cm = inputValue.multipliedBy(9.807).multipliedBy(100);
      n_cm = inputValue.multipliedBy(980.665).toFormat();
      kgf_m = inputValue.toFormat();
      kgf_cm = inputValue.multipliedBy(100).toFormat();
      lbf_ft = inputValue.multipliedBy(7.233).toFormat();
      lbf_in = inputValue.multipliedBy(86.796).toFormat();
      break;
    case unit_kgf_cm:
      n_m = inputValue.multipliedBy(0.0980665).toFormat();
      n_cm = inputValue.multipliedBy(9.80665).toFormat();
      kgf_m = inputValue.multipliedBy(0.01).toFormat();
      kgf_cm = inputValue.toFormat();
      lbf_ft = inputValue.multipliedBy(0.072).toFormat();
      lbf_in = inputValue.multipliedBy(0.868).toFormat();
      break;
    case unit_lbf_ft:
        n_m = inputValue.multipliedBy(1.356).toFormat();
        n_cm = inputValue.multipliedBy(1.356).multipliedBy(100).toFormat();
        kgf_m = inputValue.multipliedBy(0.138).toFormat();
        kgf_cm = inputValue.multipliedBy(13.826).toFormat();
        lbf_ft = inputValue.toFormat();
        lbf_in = inputValue.multipliedBy(12).toFormat();
        break;
    case unit_lbf_in:
      n_m = inputValue.multipliedBy(0.113).toFormat();
      n_cm = inputValue.multipliedBy(0.113).multipliedBy(100).toFormat();
      kgf_m = inputValue.multipliedBy(0.01152).toFormat();
      kgf_cm = inputValue.multipliedBy(1.152).toFormat();
      lbf_ft = inputValue.multipliedBy(0.083).toFormat();
      lbf_in = inputValue.toFormat();
      break;
    default:
  }

}

function TorqueConverter() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setResultData(data)
  const [state, setState] = React.useState({
    unit: '',
  });

  const handleChangeForUnit = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={"トルクの換算をします。トルクの単位 " + unit_n_m + ", " +  unit_n_cm + ", " + unit_kgf_m + ", " + unit_kgf_cm + ", " + unit_lbf_ft + ", " + unit_lbf_in + "を選択して換算することができます。"} />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={"トルクの換算をします。トルクの単位 " + unit_n_m + ", " +  unit_n_cm + ", " + unit_kgf_m + ", " + unit_kgf_cm + ", " + unit_lbf_ft + ", " + unit_lbf_in + "を選択して換算することができます。"} />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

      <div>
        <TextField
          label="入力"
          type="number"
          name="inputValue"
          defaultValue={inputValue}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 20 })}
        />
      </div>

        <div>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple" >単位</InputLabel>
            <Select
              native
              defaultValue={unit}
              onChange={handleChangeForUnit}
              inputProps={{
                name: 'unit',
                id: 'age-native-simple',
              }}
              inputRef={register({ required: true })}
            >
              <option value={unit_n_m}>{label_n_m}</option>
              <option value={unit_n_cm}>{label_n_cm}</option>
              <option value={unit_kgf_m}>{label_kgf_m}</option>
              <option value={unit_kgf_cm}>{label_kgf_cm}</option>
              <option value={unit_lbf_ft}>{label_lbf_ft}</option>
              <option value={unit_lbf_in}>{label_lbf_in}</option>
            </Select>
          </FormControl>
        </div>

        <div>
        <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
            換算する
          </Button>
        </div>
        <br />
        
      </form>

      <div id="result">

        <TextField className={classes.resultMargin}
          id={unit_n_m}
          label={label_n_m}
          defaultValue=" "
          value={n_m}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
        <br />

        <TextField className={classes.resultMargin}
          id={unit_n_cm}
          label={label_n_cm}
          defaultValue=" "
          value={n_cm}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
        <br />

        <TextField className={classes.resultMargin}
          id={unit_kgf_m}
          label={label_kgf_m}
          defaultValue=" "
          value={kgf_m}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
        <br />

        <TextField className={classes.resultMargin}
          id={unit_kgf_cm}
          label={label_kgf_cm}
          defaultValue=" "
          value={kgf_cm}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
        <br />

        <TextField  className={classes.resultMargin}
          id={unit_lbf_ft}
          label={label_lbf_ft}
          defaultValue=" "
          value={lbf_ft}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
        <br />

        <TextField className={classes.resultMargin}
          id={unit_lbf_in}
          label={label_lbf_in}
          defaultValue=" "
          value={lbf_in}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
        <br />

        {resultElement}

      </div>

      <AccordionInstruction title="使い方">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
        <Typography variant="body2" gutterBottom>
          トルクの換算をします。<br />
          トルクの単位 {unit_n_m}, {unit_n_cm}, {unit_kgf_m}, {unit_kgf_cm}, {unit_lbf_ft}, {unit_lbf_in}を選択して換算することができます。<br />
        </Typography>
        </Box>
      </ AccordionInstruction>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
    </div>
  );
}

export default TorqueConverter;
