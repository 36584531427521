import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AccordionInstruction from '../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import AdSense from 'react-adsense';
import { BigNumber } from 'bignumber.js';
import ReactGA from "react-ga4";


const locationPath = "/calculator/converter/square-metre-tsubo-tatami-converter";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "アスペクト比計算ツール";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    backgroundColor: '#ffffff',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    display: 'inline-block',
    marginTop: '10px',
    marginBottom: '10px',
  },
  adsMargin: {
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

const inputWidth = "横幅";
const inputHeight = "縦幅";

const aspectRatioCustom = "カスタム";
const aspectRatioHdVideo = "HD Video 16:9";
const aspectRatioStandardMonitor = "Standard Monitor 4:3";
const aspectRatioClassicFilm = "Classic Film 3:2";
const cinemascope = "Cinemascope 21:9";

const hd_video_w = BigNumber(16);
const hd_video_h = BigNumber(9);
const standard_monitor_w = BigNumber(4);
const standard_monitor_h = BigNumber(3);
const classic_film_w = BigNumber(3);
const classic_film_h = BigNumber(2);
const cinemascope_w = BigNumber(21);
const cinemascope_h = BigNumber(9);

let resultElement, scaleValue;
var resultData = [];
let aspectRatio = aspectRatioHdVideo;
let scaleType = inputWidth;

let widthValue = hd_video_w;
let heightValue = hd_video_h;

function createData(resultWidth, resultHeight) {
  return { resultWidth, resultHeight };
}

function setResultData(formValues) {

  //console.log("setCharData!");
  
  var newResultData = [];
  scaleType = formValues.scaleType;
  scaleValue = BigNumber(formValues.scaleValue);
  aspectRatio = formValues.aspectRatio;

  /*
  console.log("priceType:" + priceType);
  console.log("price:" + price);
  console.log("roundingType:" + roundingType);
  console.log("rate:" + rate);
  */

  let resultWidth, resultHeight;

  let ratioWidth = BigNumber(formValues.widthValue);
  let ratioHeight = BigNumber(formValues.heightValue);

  //console.log("width:" + ratioWidth);

  if (scaleType === inputWidth) {
    resultWidth = BigNumber(scaleValue);
    resultHeight = resultWidth.dividedBy(ratioWidth).multipliedBy(ratioHeight);
  } else if (scaleType === inputHeight) {
    resultHeight = BigNumber(scaleValue);
    resultWidth = resultHeight.dividedBy(ratioHeight).multipliedBy(ratioWidth);
  }

  newResultData = [
    createData(resultWidth, resultHeight),
  ];

  //Important!
  resultData = newResultData;
}

function AspectRatioCalculator() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setResultData(data)
  const [value, setValue] = React.useState(scaleType);
  const [state, setState] = React.useState({
    scaleType: '',
    name: '',
  });

  const handleChangeForScaleType = (event) => {
    //console.log(event.target.value);
    scaleType = event.target.value;
    setValue(event.target.value);
  };

  const handleChangeForAspectRatio = (event) => {

    //console.log("tataminame:" + event.target.value);

    switch (event.target.value) {
      case aspectRatioCustom:
        // keep values
        break;
      case aspectRatioHdVideo:
        widthValue = hd_video_w;
        heightValue = hd_video_h;
        document.getElementById("widthValue").value = hd_video_w;
        document.getElementById("heightValue").value = hd_video_h;
        break;
      case aspectRatioStandardMonitor:
        widthValue = standard_monitor_w;
        heightValue = standard_monitor_h;
        document.getElementById("widthValue").value = standard_monitor_w;
        document.getElementById("heightValue").value = standard_monitor_h;
        break;
      case aspectRatioClassicFilm:
        widthValue = classic_film_w;
        heightValue = classic_film_h;
        document.getElementById("widthValue").value = classic_film_w;
        document.getElementById("heightValue").value = classic_film_h;
        break;
      case cinemascope:
        widthValue = cinemascope_w;
        heightValue = cinemascope_h;
        document.getElementById("widthValue").value = cinemascope_w;
        document.getElementById("heightValue").value = cinemascope_h;
        break;
      default:
        break;
    }

    //console.log("width:" + widthValue + ", height:" + heightValue);

    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  
  if(resultData.length === 0) {


  } else {

    resultElement = 
    <div className={classes.table}>
      <TableContainer component={Paper} elevation={3}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">横幅</TableCell>
              <TableCell align="right">縦幅</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resultData.map((row) => (
              <TableRow>
                <TableCell component="th" scope="row" align="right">
                  
                </TableCell>
                <TableCell align="right">{BigNumber(row.resultWidth).toLocaleString()}</TableCell>
                <TableCell align="right">{BigNumber(row.resultHeight).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="横幅、または、縦幅のいずれかを入力し、選択したアスペクト比を元に横幅と縦幅を求めます。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="横幅、または、縦幅のいずれかを入力し、選択したアスペクト比を元に横幅と縦幅を求めます。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

        <div>
          <FormControl component="fieldset">
            <RadioGroup aria-label="scaleType" name="scaleType" value={value} onChange={handleChangeForScaleType}>
              <FormControlLabel value={inputWidth} control={<Radio />} label="横幅を入力する" inputRef={register({ required: true })}/>
              <FormControlLabel value={inputHeight} control={<Radio />} label="縦幅を入力する" inputRef={register({ required: true })}/>
            </RadioGroup>
         </FormControl>

        </div>
       <div>
        <TextField
          id="scaleValue"
          label={scaleType}
          type="number"
          name="scaleValue"
          defaultValue={scaleValue}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 20 })}
        />
      </div>

      <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple" >プリセット</InputLabel>
          <Select
            native
            defaultValue={aspectRatio}
            onChange={handleChangeForAspectRatio}
            inputProps={{
              name: 'aspectRatio',
              id: 'age-native-simple',
            }}
            inputRef={register({ required: true })}
          >
            <option value={aspectRatioCustom}>{aspectRatioCustom}</option>
            <option value={aspectRatioHdVideo}>{aspectRatioHdVideo}</option>
            <option value={aspectRatioStandardMonitor}>{aspectRatioStandardMonitor}</option>
            <option value={aspectRatioClassicFilm}>{aspectRatioClassicFilm}</option>
            <option value={cinemascope}>{cinemascope}</option>
          </Select>
        </FormControl>
      </div>

      <div>
        <TextField
          id="widthValue"
          label={"横幅比"}
          type="number"
          name="widthValue"
          defaultValue={widthValue}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 20 })}
        />
        
        <TextField
          id="heightValue"
          label={"縦幅比"}
          type="number"
          name="heightValue"
          defaultValue={heightValue}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 20 })}
        />
      </div>

      <div>
       <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
          計算する
        </Button>
      </div>
      
    </form>

    <div id="result">
      {resultElement}
    </div>

    <AccordionInstruction title="使い方">
      <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
        <Typography variant="body2" gutterBottom>
        横幅、または、縦幅のいずれかを入力し、選択したアスペクト比を元に横幅と縦幅を求めます。<br />
        <br />
        計算式は以下で求めることができます。<br /><br />
        長辺 ÷ アスペクト比(長辺) × アスペクト比(短辺) = 短辺<br />
        短辺 ÷ アスペクト比(短辺) × アスペクト比(長辺) = 長辺<br />
        <br />
        </Typography>
      </Box>
    </ AccordionInstruction>

    <AdSense.Google
      client='ca-pub-6460385026549088'
      slot='3710473082'
      style={{ display: 'block' }}
      layout='in-article'
      format='fluid'
    />
    </div>
  );
}

export default AspectRatioCalculator;
