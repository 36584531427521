import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import { BigNumber } from 'bignumber.js';
import AdSense from 'react-adsense';
import Paper from '@material-ui/core/Paper';
import SimplePieChart from '../../../components/chart/SimplePieChart';
import SimpleBarChart from '../../../components/chart/SimpleBarChartEn';
import PieChartIcon from '@material-ui/icons/PieChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import SnsShareButtons from '../../../components/sns/SnsShareButtons';
import ReactGA from "react-ga4";


const locationPath = "/en/calculator/math/percentage-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "Percentage Calculator";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    backgroundColor: '#ffffff',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
  },
  paper: {
  },
  chartLabel: {
    textAlign: 'left',
    margin: '20px',
    paddingTop: '15px',
  },
  chartStyle: {
    padding: 15,
    maxWidth: '800px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  margin: {
    marginTop: '0px',
    marginBottom: '10px',
  }
}));

const mode0 = "What is A% of B?"; //"AのB%は？";
const mode1 = "A is what percent of B?"; //"AはBの何%？";
const mode2 = "What is % increase/decrease from A to B?"; //"AからBへの増減率は？";
const mode3 = "A is B% of what?"; //"AがB%の場合、全体は？";
const mode4 = "A minus B% is what?"; //"AのB%引きは？";
const mode5 = "A plus B% is what?"; //"AのB%増しは？";

let resultElement, valueA, valueB, resultValue;
let resultData = "";
let modeType = mode0;
let chartData1, chartData2;

function setResultData(formValues) {

  //console.log("setCharData!");
  
  modeType = formValues.modeType;
  valueA = BigNumber(formValues.valueA);
  valueB = BigNumber(formValues.valueB);
  resultValue = 0;
  let resultStr = "";
  let percentageRemaining;
  const messageForNoChart = <Typography variant="body2" gutterBottom>Pie chart cannot be displayed.</Typography>;

  if (modeType === mode0) {
    resultValue = valueB.multipliedBy(valueA).dividedBy(100);
    resultStr = valueA + "% of " + valueB + " is " + resultValue.toFormat() + ".";

    percentageRemaining = BigNumber(100).minus(valueB).toNumber();
    if (valueB.isGreaterThanOrEqualTo(0) && valueB.isLessThanOrEqualTo(100)) {
      chartData1 = <SimplePieChart totalNumber={valueA.toNumber()} percentageMain={valueB.toNumber()} valueMain={resultValue.toNumber()} percentageRemaining={percentageRemaining} label1={resultStr} label2={percentageRemaining + "%"} />;
    } else {
      chartData1 = messageForNoChart;
    }

    chartData2 = <SimpleBarChart totalNumber={valueA.toNumber()} percentageMain={valueB.toNumber() + "%"} valueMain={resultValue.toNumber()} nameValue="A" />;

  } else if (modeType === mode1) {
    resultValue = valueA.dividedBy(valueB).multipliedBy(100);
    resultStr = valueA + " is " + resultValue.toFormat() + "% of " + valueB + ".";

    percentageRemaining = BigNumber(100).minus(resultValue).toNumber();
    if (resultValue.isGreaterThanOrEqualTo(0) && resultValue.isLessThanOrEqualTo(100)) {
      chartData1 = <div>
                    <SimplePieChart totalNumber={valueB.toNumber()} percentageMain={resultValue.toNumber()} valueMain={valueA.toNumber()}  percentageRemaining={percentageRemaining} label1={valueA + " is " + resultValue.toFormat() + "%"} label2={percentageRemaining + "%"} />
                    <Typography variant="body2" gutterBottom>Out of {valueB.toNumber()}</Typography>
                  </div>;
    } else {
      chartData1 = messageForNoChart;
    }

    chartData2 = <SimpleBarChart totalNumber={valueB.toNumber()} percentageMain={resultValue.toNumber() + "%"} valueMain={valueA.toNumber()} nameValue="B" />;

  } else if (modeType === mode2) {
    resultValue = valueB.dividedBy(valueA).minus(1).multipliedBy(100);
    let increaseDecrease = "increase/decrease";
    if (resultValue > 0) {
      increaseDecrease = "increase";
    } else if (resultValue < 0) {
      increaseDecrease = "decrease";
    }
    resultStr = "from " + valueA + " to " + valueB + " is " + resultValue.toFormat() + "% " + increaseDecrease + ".";

    chartData1 = messageForNoChart;

    chartData2 = <SimpleBarChart totalNumber={valueA.toNumber()} percentageMain={resultValue.toNumber() + "%"} valueMain={valueB.toNumber()} nameValue="A" />;

  } else if (modeType === mode3) {
    resultValue = valueA.dividedBy(valueB).multipliedBy(100);
    resultStr = valueA + " is " + valueB + "% of " + resultValue.toFormat() + ".";

    percentageRemaining = BigNumber(100).minus(valueB).toNumber();
    if (valueB.isGreaterThanOrEqualTo(0) && valueB.isLessThanOrEqualTo(100)) {
      chartData1 = <div>
                    <SimplePieChart totalNumber={resultValue.toNumber()} percentageMain={valueB.toNumber()} valueMain={valueA.toNumber()} percentageRemaining={percentageRemaining} label1={valueA + " is " + valueB + "%"} label2={percentageRemaining + "%"} />
                    <Typography variant="body2" gutterBottom>Out of {resultValue.toNumber()}</Typography>
                  </div>;
    } else {
      chartData1 = messageForNoChart;
    }

    chartData2 = <SimpleBarChart totalNumber={valueA.toNumber()} percentageMain="(100%)" valueMain={resultValue.toNumber()} nameValue={"A (" + valueB.toNumber() + "%)"} />;

  } else if (modeType === mode4) {
    // modeType === mode4
    let paramA = BigNumber(100).minus(valueB);
    resultValue = valueA.multipliedBy(paramA).dividedBy(100);
    resultStr = valueA + " minus " + valueB + "% is " + resultValue.toFormat() + ".";

    percentageRemaining = BigNumber(100).minus(valueB).toNumber();
    
    if (valueB.isGreaterThanOrEqualTo(0) && valueB.isLessThanOrEqualTo(100)) {
      chartData1 = <div>
                      <SimplePieChart totalNumber={valueB} percentageMain={percentageRemaining} valueMain={resultValue.toNumber()} percentageRemaining={valueB} label1={valueA + " minus " + valueB + "% is " + resultValue} label2={valueA.minus(resultValue).toNumber()} />
                      <Typography variant="body2" gutterBottom>{valueA.toNumber()}</Typography>
                    </div>;
    } else {
      chartData1 = messageForNoChart;
    }

    chartData2 = <SimpleBarChart totalNumber={valueA.toNumber()} percentageMain={"Minus " + valueB.toNumber() + "%"} valueMain={resultValue.toNumber()} nameValue="A" />;
  } else {
    // modeType === mode5
    let paramA = BigNumber(valueB);
    resultValue = valueA.multipliedBy(paramA).dividedBy(100).plus(valueA);
    resultStr = valueA + " plus " + valueB + "% is " + resultValue.toFormat() + ".";

    percentageRemaining = BigNumber(valueB).toNumber();
    
    if (valueB.isGreaterThanOrEqualTo(0)) {
      chartData1 = <div>
                      <SimplePieChart totalNumber={resultValue} percentageMain={valueA.dividedBy(resultValue).multipliedBy(100).toNumber()} valueMain={valueA.toNumber()} percentageRemaining={resultValue.minus(valueA).dividedBy(resultValue).multipliedBy(100).toNumber()} label1={valueA.toNumber()} label2={valueB + "% is " + resultValue.minus(valueA).toNumber()} />
                      <Typography variant="body2" gutterBottom>{valueA + " plus " + valueB + "% is " + resultValue}</Typography>
                    </div>;
    } else {
      chartData1 = messageForNoChart;
    }

    chartData2 = <SimpleBarChart totalNumber={valueA.toNumber()} percentageMain={"Plus " + valueB.toNumber() + "%"} valueMain={resultValue.toNumber()} nameValue="A" />;
  }

  //Important!
  resultData = resultStr;
}

function PercentageCalculator() {

  // For the English pages
  var newLang = 'en';
  document.documentElement.lang = newLang;

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setResultData(data)
  const [value, setValue] = React.useState(modeType);

  const labelPieChart = <div className={classes.chartLabel}><Typography variant="subtitle1" gutterBottom><PieChartIcon />Pie Chart</Typography></div>;
  const labelBarChart = <div className={classes.chartLabel}><Typography variant="subtitle1" gutterBottom><BarChartIcon />Bar Chart</Typography></div>;

  const handleChangeForModeType = (event) => {
    //console.log(event.target.value);
    modeType = event.target.value;
    setValue(event.target.value);
  };
  
  if(resultData === "") {


  } else {

    resultElement = 
    <div className={classes.chartStyle}>
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h6" component="body2" gutterBottom>{resultData}</Typography><br />
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        {labelPieChart}
        {chartData1}
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        {labelBarChart}
        {chartData2}
      </Paper>
      <br />
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={"This is Percentage Calculator. " + {mode0} + " " + {mode1} + " " + {mode2} + " " + {mode3} + " " + {mode4} +". You can select formula from options. This Percentage Calculator shows you result in visualized Pie chart and Bar chart."} />
        <link rel="alternate" href="https://www.life-util.com/calculator/math/percentage-calculator" hreflang="ja"/>
        <link rel="alternate" href="https://www.life-util.com/en/calculator/math/percentage-calculator" hreflang="en" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={"This is Percentage Calculator. " + {mode0} + " " + {mode1} + " " + {mode2} + " " + {mode3} + " " + {mode4} +". You can select formula from options. This Percentage Calculator shows you result in visualized Pie chart and Bar chart."} />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div className={classes.snsButtonMargin}>
        <SnsShareButtons fullPath={fullPath} title={title} hashtag={["PercentageCalculator"]} />
      </div>

      {/*
      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='8215889996'
        style={{ display: 'block' }}
        format='auto'
        responsive='true'
        layoutKey='-gw-1+2a-9x+5c'
      />**/}

      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

        <div>
          <FormControl component="fieldset">
            <RadioGroup aria-label="modeType" name="modeType" value={value} onChange={handleChangeForModeType}>
              <FormControlLabel value={mode0} control={<Radio />} label={mode0} inputRef={register({ required: true })}/>
              <FormControlLabel value={mode1} control={<Radio />} label={mode1} inputRef={register({ required: true })}/>
              <FormControlLabel value={mode2} control={<Radio />} label={mode2} inputRef={register({ required: true })}/>
              <FormControlLabel value={mode3} control={<Radio />} label={mode3} inputRef={register({ required: true })}/>
              <FormControlLabel value={mode4} control={<Radio />} label={mode4} inputRef={register({ required: true })}/>
              <FormControlLabel value={mode5} control={<Radio />} label={mode5} inputRef={register({ required: true })}/>
            </RadioGroup>
         </FormControl>

        </div>
       <div>
        <TextField
          id="valueA"
          label="Enter A"
          type="number"
          name="valueA"
          defaultValue={valueA}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 20 })}
        />
      </div>

      <div>
        <TextField
          id="valueB"
          label="Enter B"
          type="number"
          name="valueB"
          defaultValue={valueB}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 20 })}
        />
      </div>

      <div style={{ paddingTop: 15 }}>
       <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
          CALCULATE
        </Button>

      </div>

      {/*
      <AdSense.Google
          client='ca-pub-6460385026549088'
          slot='1389671621'
          style={{ display: 'block' }}
          layout='in-article'
          format='fluid'
        />**/}

    </form>

    <div id="result">
      {resultElement}
    </div>

    <AccordionInstruction title="Information and instructions">
      <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
        <Typography variant="body2" gutterBottom>
          Percentage Calculator. {mode0} {mode1} {mode2} {mode3} {mode4}<br />
          You can select formula from options. This Percentage Calculator shows you result in visualized Pie chart and Bar chart.<br />
        </Typography>
      </Box>
    </ AccordionInstruction>

    <AdSense.Google
      client='ca-pub-6460385026549088'
      slot='4219694846'
      style={{ display: 'block' }}
      layout='in-article'
      format='fluid'
    />
    </div>
  );
}

export default PercentageCalculator;
