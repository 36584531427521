import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
//import AdSense from 'react-adsense';
import { BigNumber } from 'bignumber.js';
import Link from '@material-ui/core/Link';
import SnsShareButtons from '../../../components/sns/SnsShareButtons';
import AdSense from 'react-adsense';
import ReactGA from "react-ga4";


const locationPath = "/calculator/finance/human-life-value-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "生命価値計算";

let resultValue = "";
let inputValue1 = "";
let inputValue2 = "";
let resultElement;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    marginTop: '20px',
  },
}));

function setFormData(formValues) {
    inputValue1 = BigNumber(formValues.input1);
    inputValue2 = BigNumber(formValues.input2);

    resultValue = inputValue1.multipliedBy(inputValue2).toFormat();
}

function HumanLifeValueCalculator() {
  
  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setFormData(data)
  
  if(resultValue === "") {
    //showChart = false;
  } else {
    //showChart = true;

    resultElement = 
    <div>
      <TextField
        id="result"
        label="生命価値(万円)"
        defaultValue={resultValue}
        value={resultValue}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
      <br /><br />
    </div>;
  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="生命の価値はその人の収入や所得だけで計ることはできませんが、未来のことを考えるときに数値化することでイメージしやすくなることがあります。将来得られる(た)であろう家族を養うための収入を見込みで算出することができます。また、この方法はそれを生命保険で代替しようと考えるときにも用いられることがあります。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="生命の価値はその人の収入や所得だけで計ることはできませんが、未来のことを考えるときに数値化することでイメージしやすくなることがあります。将来得られる(た)であろう家族を養うための収入を見込みで算出することができます。また、この方法はそれを生命保険で代替しようと考えるときにも用いられることがあります。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div className={classes.snsButtonMargin}>
        <SnsShareButtons fullPath={fullPath} title={title} hashtag={["生命価値計算"]} />
      </div>

      <div>

        <AdSense.Google
          client='ca-pub-6460385026549088'
          slot='3897779459'
          style={{ display: 'block' }}
          format='auto'
          responsive='true'
          layoutKey='-gw-1+2a-9x+5c'
        />

        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TextField
              id="input1"
              label="年収(万円)を入力"
              type="number"
              name="input1"
              defaultValue={inputValue1}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              inputRef={register({ required: true, maxLength: 20 })}
              variant="outlined"
            />
          </div>
          <br />
          <div>
            <TextField
              id="input2"
              label="リタイアまでの年数を入力"
              type="number"
              name="input2"
              defaultValue={inputValue2}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              inputRef={register({ required: true, maxLength: 3 })}
              variant="outlined"
            />
          </div>
        
          <br /> 

          <div>
          <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin} >
              計算する
            </Button>
          <AdSense.Google
            client='ca-pub-6460385026549088'
            slot='7676138718'
            style={{ display: 'block' }}
            layout='in-article'
            format='fluid'
          />
          </div>

          <br />
        
        </form>

        <div id="chart">
          {resultElement}
        </div>
      </div>

      <AccordionInstruction title="説明">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            生命の価値(Human Life Value / HLV)はその人の収入や所得だけで計ることはできませんが、未来のことを考えるときに数値化することでイメージしやすくなることがあります。<br />
            将来得られる(た)であろう家族を養うための収入の見込みを簡易的に算出することができます。<br />
            また、この方法はそれを生命保険で代替しようと考えるときにも用いられることがあります。<br />
            <br />
            将来の平均年収とリタイアするまでの年数を入力して計算ボタンを押下します。<br />
            <br />
            関連リンク<br />
            ⇒<Link href="/calculator/finance/fire-simulator">FIREシミュレーター</Link><br />
            ⇒<Link href="/calculator/finance/barista-fire-calculator">サイドFIREシミュレーター</Link><br />
            ⇒<Link href="/calculator/finance/compound-interest-calculator">複利計算</Link><br />
            ⇒<Link href="/calculator/finance/savings-rate-calculator">貯蓄率計算</Link><br />
            ⇒<Link href="/calculator/finance/expected-asset-amount-calculator">期待資産額計算</Link><br />
            ⇒<Link href="/calculator/healthcare/expected-life-at-age-calculator">平均余命計算</Link>
          </Typography>
        </Box>
      </ AccordionInstruction>
      
      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />

    </div>
  );

}

export default HumanLifeValueCalculator;
