import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  BarChart, Bar, Legend, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AccordionInstruction from '../../../components/AccordionInstruction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AdSense from 'react-adsense';
import Link from '@material-ui/core/Link';
import SnsShareButtons from '../../../components/sns/SnsShareButtons';
import ReactGA from "react-ga4";


const locationPath = "/calculator/finance/compound-interest-calculator";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "積立投資のための複利計算 (表とグラフ表示あり)";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    backgroundColor: '#ffffff',
  },
  chart: {
    display: 'block',
    justifyContent: 'start',
    width: '680px',
    height: '450px',
    backgroundColor: '#FFFFFF',
  },
  table: {
  },
  chartroot: {
    justifyContent: 'start',
    width: '360px',
    marginBottom: '5px',
    /*paddingLeft: '60px',*/
  },
  tableparent: {
    justifyContent: 'start',
    minWidth: '500px',
  },
  headersub: {
    display: 'flex',
  },
  margin: {
    marginTop: "5px",
    marginBottom: "0px",
  },
  margin_ads: {
    marginTop: '5px',
    marginBottom: '10px',
  },
}));

let chartElement, principal, monthly_deposit;
let finalAmount;
var chartData = [];
let rate, years;
var resultData = [];

//const jsfiddleUrl = 'https://jsfiddle.net/alidingling/c1rLyqj1/';

function setChartData(formValues) {

  var newChartData = [];
  let yearly_deposit, interest, total;
  principal = Number(formValues.principal);
  monthly_deposit = Number(formValues.monthly_deposit);
  yearly_deposit = 0;
  rate = Number(formValues.rate);
  years = Number(formValues.years);
  interest = 0;
  var newResultData = [];

  for (let i = 0; i < years; i++) {
    
    yearly_deposit = yearly_deposit + (Number(formValues.monthly_deposit * 10000) * 12);
    interest = Math.round(((principal * 10000 + yearly_deposit + interest) * (rate / 100)) + interest); 
    total = (principal * 10000) + yearly_deposit + interest;

    newChartData[i] = {name: i+1 + '年目', 元金: principal * 10000, 毎月積立額: yearly_deposit, リターン: interest,ttl: total};

    newResultData[i] = {year: i+1, principal: principal * 10000, yearlyDeposite: yearly_deposit, interest: interest, total: total};

  }

  //Important!
  chartData = newChartData;
  resultData = newResultData;
  finalAmount = total.toLocaleString();

}

function CompoundInterestCalculator() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);
  
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => setChartData(data)

  //console.log("■" + document.getElementById('app_bar_title'));
  //document.title = '積立シミュレーション | 複利計算';
  
  if(chartData.length === 0) {
    //showChart = false;

    rate = 3.5;
    years = 30;

  } else {
    //showChart = true;


    chartElement = 
    <div className={classes.chartroot}>
      <div style={{width: '100%'}}>
        <TextField
          id="outlined-read-only-input"
          label="最終積立金額"
          defaultValue={finalAmount}
          value={finalAmount}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      </div>
      <div className={classes.chart}>
        <ResponsiveContainer width="100%" height="80%">
          <BarChart
            width="100%"
            height={300}
            data={chartData}
            margin={{
              top: 40, right: 30, left: 50, bottom: 1,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={tick => {
                return (tick / 10000).toLocaleString() + "万円";
            }}/>
            <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)}/>
            <Legend />
            <Bar dataKey="元金" stackId="a" fill="#8884d8" />
            <Bar dataKey="毎月積立額" stackId="a" fill="#82ca9d" />
            <Bar dataKey="リターン" stackId="a" fill="#ff7f50" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className={classes.tableparent}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>年数</TableCell>
                <TableCell align="right">元金</TableCell>
                <TableCell align="right">積立金額</TableCell>
                <TableCell align="right">リターン</TableCell>
                <TableCell align="right">合計</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultData.map((row) => (
                <TableRow key={row.year}>
                  <TableCell component="th" scope="row" align="right">
                    {row.year}
                  </TableCell>
                  <TableCell align="right">{Number(row.principal).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.yearlyDeposite).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.interest).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.total).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>;

  }

  return (
    <div>
      
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="複利計算のシミュレーションをするツールです。シミュレーション結果をグラフと表で表示することができます。積立金額や積立期間などを入力して計算してみましょう。複利とは元金によって生じた利子を元金に組み入れる方式の計算です。元金だけでなく利子にも次期の利子がつくことにより、長期の複利の力を味方につけることができるようになります。" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="複利計算のシミュレーションをするツールです。シミュレーション結果をグラフと表で表示することができます。積立金額や積立期間などを入力して計算してみましょう。複利とは元金によって生じた利子を元金に組み入れる方式の計算です。元金だけでなく利子にも次期の利子がつくことにより、長期の複利の力を味方につけることができるようになります。" />
        <meta property="og:site_name" content="Life-Util" />
        <meta property="og:image" content="https://www.life-util.com/images/thumbnail/001.jpg" />
      </Helmet>

      <div className={classes.snsButtonMargin}>
        <SnsShareButtons fullPath={fullPath} title="積立投資のための複利計算" hashtag={["積立投資", "複利計算"]} />
      </div>

      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='2388848247'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />

      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
       <div>
        <TextField
          id="principal"
          label="元金(万円)"
          type="number"
          name="principal"
          defaultValue={principal}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          inputRef={register({ required: true, maxLength: 20 })}
          />
        </div>
        <div>
          <TextField
            id="monthly-deposit"
            label="毎月積立額(万円) 例:3.33"
            type="number"
            name="monthly_deposit"
            defaultValue={monthly_deposit}
            inputRef={register({ required: true, maxLength: 20 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>
        <div>
          <TextField
            id="rate"
            label="実質リターン(%)"
            type="number"
            name="rate"
            defaultValue={rate}
            inputRef={register({ required: true, maxLength: 20 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>
        <div>
          <TextField
            id="years"
            label="積立期間(年)"
            type="number"
            name="years"
            defaultValue={years}
            inputRef={register({ required: true, maxLength: 2 })}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </div>
        <div>
          <Button type="submit" label="submit" variant="contained" size="small" color="primary" className={classes.margin}>
            試算する
          </Button>
          <AdSense.Google
            client='ca-pub-6460385026549088'
            slot='5523425547'
            style={{ display: 'block' }}
            layout='in-article'
            format='fluid'
          />
        </div>

        <br />
        
      </form>

      <div>
        {chartElement}
      </div>

      <AccordionInstruction title="使い方">
        <Box display="block" p={1} m={1} bgcolor="background.paper" justifyContent="flex-start" textAlign="left">
          <Typography variant="body2" gutterBottom>
            複利計算のシミュレーションをするツールです。<br />
            シミュレーション結果をグラフと表で表示することができます。<br />
            積立金額や積立期間などを入力して計算してみましょう。<br />
            <br />
            複利とは元金によって生じた利子を元金に組み入れる方式の計算です。<br />
            元金だけでなく利子にも次期の利子がつくことにより、長期の複利の力を味方につけることができるようになります。<br />
            <br />
            グラフのをタップまたはクリックすると、内訳を表示します。<br />
            ・紫色・・・元金<br />
            ・緑色・・・積立金額<br />
            ・橙色・・・運用リターン<br />
            <br />
            ※シミュレーション結果は実際の結果を保障するものではありません。<br />
            <br />
            関連リンク<br />
            ⇒<Link href="/calculator/finance/fire-simulator">FIREシミュレーター</Link><br />
            ⇒<Link href="/calculator/finance/barista-fire-calculator">サイドFIREシミュレーター</Link><br />
            ⇒<Link href="/calculator/finance/savings-rate-calculator">貯蓄率計算</Link><br />
            ⇒<Link href="/calculator/finance/expected-asset-amount-calculator">期待資産額計算</Link><br />
            ⇒<Link href="/calculator/healthcare/expected-life-at-age-calculator">平均余命計算</Link><br />
            ⇒<Link href="/calculator/finance/human-life-value-calculator">生命価値計算</Link>
          </Typography>
        </Box>
      </ AccordionInstruction>
      
      <AdSense.Google
        client='ca-pub-6460385026549088'
        slot='3710473082'
        style={{ display: 'block' }}
        layout='in-article'
        format='fluid'
      />
      
    </div>
  );
}

export default CompoundInterestCalculator;
